/* ================================================== */

/* GIGALAND
/* created by designesia

@charset "utf-8";

/* ================================================== */

/* import custom fonts */

/* ================================================== */
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Mulish:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap");
@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../fonts/ProximaNova-Regular.woff2") format("woff2"),
    url("../fonts/ProximaNova-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"),
    url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* ================================================== */

/* import fonts icon */

/* ================================================== */

@import url("../fonts/font-awesome/css/font-awesome.css");
@import url("../fonts/elegant_font/HTML_CSS/style.css");
@import url("../fonts/et-line-font/style.css");
@import url("../fonts/icofont/icofont.min.css");

* {
  --body-font: "Proxima Nova Rg";
  --title-font: "Proxima Nova Rg";
}

/* ================================================== */

/* body */

/* ================================================== */

html {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  background: #fff;
}

body {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  color: #727272;
  line-height: 30px;
  padding: 0;
  line-height: 26px;
  word-spacing: 0px;
}

.dark-scheme {
  color: #9fa4dd;
}

body.dark-scheme {
  background: #ffffff;
}

body.boxed {
  margin: 30px;
  overflow: hidden;
  border: solid 1px #eee;
}

body .owl-stage .owl-item {
  margin-bottom: 15px;
}
#navbarSupportedContent::before {
  display: none;
}
#wrapper {
  overflow: hidden;
}

.de-navbar-left #wrapper {
  overflow: visible;
}

a,
a:hover {
  text-decoration: none;
}

a.a-underline {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

a.a-underline span {
  display: block;
  position: relative;
  left: 0;
  bottom: 0;
  border-bottom: solid 1px #333;
  width: 0;
}

a.a-underline:hover span {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.homeTextSide {
  position: relative;
  width: 1320px;
  max-width: 100%;
  margin: 0 auto;
}

/* ================================================== */

/*  header  */

/* ================================================== */
/* 
#logo img {
  max-width: 100%;
} */
#profile_banner\ subheader {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.d-create-file.coverPhotoButton {
  margin: 0;
  padding: 0;
  text-align: left;
  border: 0;
  position: absolute;
  right: 25px;
  bottom: 25px;
}
header {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1001;
  background: #2b313f;
  margin: 0;
  /*border-bottom:solid 1px rgba(255,255,255,0);*/
}

header.smaller.has-topbar {
  height: 105px;
}

header.header-s1 {
  /*border-bottom:solid 1px rgba(255,255,255,.2);*/
  top: 0;
}

header .info {
  display: none;
  color: #fff;
  padding: 10px 0 10px 0;
  width: 100%;
  z-index: 100;
  background: #242424;
}

header .info .col {
  display: inline-block;
  font-size: 12px;
}

header .info .col {
  padding-right: 30px;
}

header .info .col:last-child {
  padding-right: 0px;
}

header .info i {
  font-size: 14px;
  margin-right: 15px;
}

header .info .social.col {
  border: none;
}

header .info .social i {
  color: #fff;
  font-size: 14px;
  display: inline-block;
  float: none;
  padding: 0 10px 0 10px;
  margin: 0;
}

header .info strong {
  font-size: 12px;
}

header.header-bg {
  background: rgba(0, 0, 0, 0.5);
}

header.transparent {
  background: none;
}
header.transparent.up nav {
  background-color: transparent;
}
header.transparent.down nav {
  box-shadow: 0 4px 20px 0 rgba(20, 25, 30, 0.8);
  background: rgba(23, 13, 82, 0.9);
  backdrop-filter: blur(5px);
}
body:not(.side-layout)
  header:not(.smaller):not(.header-mobile).header-light.transparent {
  background: rgba(23, 13, 82, 0);
}

header.autoshow {
  top: -120px;
  height: 70px !important;
}

header.autoshow.scrollOn,
header.autoshow.scrollOff {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

header.autoshow.scrollOn {
  top: 0;
}

header.autoshow.scrollOff {
  top: -120px;
}

header div#logo {
  font-family: "Oswald", sans-serif;
  color: white;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

header .logo-2 {
  display: none;
}

header .logo-scroll {
  display: none;
}

header .logo,
header .logo-2 {
  font-family: var(--body-font);
  color: #fff;
  font-size: 40px;
  font-weight: 300;
}

header .logo-2 {
  color: #333;
}

header.header-full nav {
  float: left;
}

header nav a {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

header nav a:hover {
  color: #555;
}

header.smaller #mainmenu ul ul {
  top: 0px;
}

header.smaller {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0px;
  padding: 0;
  /*
    -webkit-box-shadow: 0 4px 6px 0 rgba(10,10,10, 0.05);
    -moz-box-shadow: 0 4px 6px 0 rgba(10,10,10, 0.05);
    box-shadow: 0 4px 6px 0 rgba(10,10,10, 0.05);
    */
  border: 0;
}

header.smaller.header-light,
header.header-mobile.header-light {
  border-bottom: solid 1px #dddddd;
}

header.smaller .btn-rsvp {
  height: 100px;
  padding-top: 55px;
}

.boxed header.smaller {
  margin-top: 0px;
}

header.smaller div#logo,
header.s2 div#logo {
}

header.smaller.scroll-light {
  background: #ffffff;
}

header.smaller.scroll-dark {
  background: #161d30;
}

header.header-light .h-phone,
header.smaller.scroll-light .h-phone {
  color: #202020;
}

header:not(.header-light).smaller.scroll-light div#logo .logo {
  display: none;
}

header.smaller.scroll-light div#logo .logo-2 {
  display: inline-block;
}

header.smaller.scroll-light div#logo .logo-scroll {
  display: inline-block;
}

header.smaller.header-light div#logo .logo {
  display: none;
}

header.smaller.scroll-light .social-icons i {
  color: #333;
}

header.smaller.scroll-light #mainmenu li li a:hover {
  color: #ffffff;
}

.logo-smaller div#logo {
  width: 150px;
  height: 30px;
  line-height: 65px;
  font-size: 30px;
}

.logo-smaller div#logo img {
  font-size: 30px;
}

.logo-smaller div#logo .logo-1 {
  display: none;
}

.logo-smaller div#logo .logo-2 {
  display: inline-block;
}

.logo-small {
  margin-bottom: 20px;
}

header.de_header_2 {
  height: 125px;
}

header.de_header_2.smaller {
  height: 70px;
}

header.de_header_2.smaller .info {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

header.header-full {
  padding-left: 40px;
  padding-right: 40px;
}

header .header-row {
  text-align: center;
}

header .header-col {
  position: relative;
  height: 100%;
}

/* left col header */
header .header-col.left {
  float: left;
  padding: 0 30px 0 0;
  /* border-right:solid 1px rgba(255,255,255,.2); */
}

/* middle col header */
header .header-col.mid {
  float: right;
}

header .header-col.mid .social-icons {
  float: right;
  /* border-left:solid 1px rgba(255,255,255,.2); */
  padding: 0 30px 0 30px;
}

.header-col-right {
  text-align: right;
}

.de-navbar-left {
  background: #151618;
}

.de-navbar-left #logo {
  padding: 40px;
  text-align: center;
  width: 100%;
}

.de-navbar-left header {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  background: none;
  padding: 30px;
  padding-right: 20px;
}

.de-navbar-left header #mainmenu {
  float: none;
  margin: 0;
  padding: 0;
}
.has-child i {
  display: none;
}
.de-navbar-left header #mainmenu > li {
  display: block;
  margin: 0;
  float: none;
  text-align: center;
  font-family: var(--body-font);
  letter-spacing: 5px;
  font-size: 14px;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.de_light.de-navbar-left header #mainmenu > li {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.de-navbar-left header #mainmenu > li > a {
  padding: 0;
  opacity: 0.5;
}

.de_light.de-navbar-left header #mainmenu > li > a {
  color: #222;
}

.de-navbar-left header #mainmenu > li a:hover {
  opacity: 1;
}

.de-navbar-left header #mainmenu > li > a:after {
  content: none;
}

.de-navbar-left header nav {
  float: none;
}

/* subheader */

#subheader {
  text-align: center;
  overflow: hidden;
  padding: 0px;
  margin-top: 70px;
}

#subheader h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 36px;
}

#subheader.dark {
  background-color: #1a1c26;
}

#subheader.dark h1 {
  color: #fff;
}

.de-navbar-left #subheader {
  padding-bottom: 120px;
}

#subheader .crumb {
  font-size: 14px;
  letter-spacing: 3px;
}

#subheader.text-light .crumb {
  color: #fff;
}

#subheader .small-border-deco {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}

#subheader .small-border-deco span {
  display: block;
  margin: 0 auto;
  height: 2px;
  width: 100px;
}

#subheader.dark h2,
#subheader.dark h4 {
  color: #fff;
}

#subheader.dark h2 {
  font-size: 48px;
}

#subheader.s2 {
  background: rgba(var(--secondary-color-rgb), 0.1);
  text-align: left;
  padding: 120px 0 60px;
  border: solid 1px #eee;
  border-left: none;
  border-right: none;
}

#subheader.s2 h1,
#subheader.s2 .crumb {
  margin: 0;
  padding: 0;
}

#subheader.s2 .crumb {
  float: right;
  margin-top: 15px;
}

.de-navbar-left.de_light #subheader h1 {
  color: #222;
}

#profile_banner {
  min-height: 360px;
  background-position: center center;
  background-size: cover;
}

/* ================================================== */

/* navigation */

/* ================================================== */
.ml-auto {
  margin-left: auto;
}
.ml-0 {
  margin-left: 0 !important;
}
#mainmenu {
  font-family: var(--title-font);
  font-size: 16px;
  float: none;
}
.dropdown.has-child > a::after {
  display: none !important;
}

#mainmenu ul {
  margin: 0px 0px;
  padding: 0px;
  height: 30px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  -moz-box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
}

.dark-scheme #mainmenu ul {
  background: #21273e;
}

#mainmenu li {
  margin: 0px 0px;
  padding: 0px 0px;
  float: left;
  display: inline;
  list-style: none;
  position: relative;
}

#mainmenu li.has-child:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #ffffff;
  padding-left: 5px;
  font-size: 8px;
  position: relative;
  top: -2px;
}

.header-light #mainmenu li.has-child:after {
  color: #ffffff;
}

#mainmenu li > a {
  padding-right: 6px;
  min-width: auto;
  border: 0;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: normal;
  margin-left: 12px;
}

#mainmenu a {
  position: relative;
  display: inline-block;
  padding: 30px 14px 30px 14px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  outline: none;
}

#mainmenu a span {
  position: relative;
  width: 0%;
  color: #ffffff;
  display: block;
  font-size: 10px;
  line-height: 14px;
  border-bottom: solid 0px #ffffff;
}

#mainmenu li a:hover span {
  color: #000000;
}

#mainmenu li li a span {
  float: right;
  margin-top: 5px;
}

#mainmenu a.active span {
  width: 100%;
  margin-left: 0%;
}

.header-light {
  border-bottom: solid 1px rgba(255, 255, 255, 0);
}

.header-light #mainmenu > li > a {
  color: #222;
}

#mainmenu li li {
  font-family: var(--body-font);
  font-size: 14px;
}

#mainmenu li li:last-child {
  border-bottom: none;
}

#mainmenu li li a {
  padding: 5px 15px;
  border-top: none;
  color: #606060;
  width: 100%;
  border-left: none;
  text-align: left;
  font-weight: normal;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  margin-left: 0;
  text-transform: capitalize;
  font-size: 15px;
}

#mainmenu li:last-child > a {
  border-bottom: none;
}

.dark-scheme #mainmenu li li a {
  color: #ffffff;
}

.rtl #mainmenu li li a {
  text-align: right;
}

#mainmenu li li a:hover {
  color: #111;
}

#mainmenu li li a:after {
  content: none;
}

#mainmenu li li li a {
  padding: 2px 15px 2px 15px;
  background: #171a21;
}

#mainmenu li li a:hover,
#mainmenu ul li:hover > a {
  color: #fff;
  background: #ed7b34;
}

#mainmenu li ul {
  width: 180px;
  height: auto;
  position: absolute;
  left: 0px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
}

#mainmenu li li {
  font-size: 14px;
  display: block;
  float: none;
  text-transform: none;
}

#mainmenu li:hover > ul {
  visibility: visible;
  opacity: 1;
  margin-top: 0px;
}

#mainmenu li ul ul {
  left: 100%;
  top: 0px;
}

#mainmenu li ul ul li a {
  background: #202020;
}

#mainmenu li:hover > ul {
  color: #1a8b49;
}

#mainmenu select {
  padding: 10px;
  height: 36px;
  font-size: 14px;
  border: none;
  background: #ff4200;
  color: #eceff3;
}

#mainmenu select option {
  padding: 10px;
}

#mainmenu .btn-type {
  padding: 0;
  margin: 0;
}

#mainmenu .btn-type a {
  background: #eee;
  padding: 0;
  margin: 0;
}

#mainmenu > li ul.mega {
  position: fixed;
  left: 0;
  height: 0%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  padding-left: 40px;
  padding-right: 40px;
}

#mainmenu li:hover ul.mega {
  visibility: visible;
  opacity: 1;
}

#mainmenu li ul.mega > li,
#mainmenu li ul.mega > li a {
  width: 100%;
}

#mainmenu li ul.mega li.title {
  padding: 0px 10px 15px 10px;
  text-align: left;
  color: #fff;
  font-weight: bold;
}

#mainmenu li ul.mega > li ul {
  position: static;
  visibility: visible;
  opacity: 1;
  left: 0;
  float: none;
  width: 100%;
}

#mainmenu ul.mega > li ul li {
  margin: 0;
  padding: 0;
}

#mainmenu ul.mega > li ul li:last-child {
  border-bottom: solid 1px #333333;
}

#mainmenu li ul.mega .menu-content {
  background: #171a21;
  padding: 30px;
}

header.header-mobile #mainmenu > li ul.mega {
  visibility: visible;
  opacity: 1;
}

header:not(.header-mobile) #mainmenu > li:hover ul.mega {
  height: auto;
}

/* menu toggle for mobile */

#menu-btn {
  display: none;
  float: right;
  margin-top: -3px;
  width: 32px;
  height: 32px;
  padding: 4px;
  text-align: center;
  cursor: poInter;
  color: #fff;
  margin-left: 20px;
}

#menu-btn:before {
  font-family: FontAwesome;
  content: "\f0c9";
  font-size: 20px;
}

#menu-btn:hover {
  background: #cf129a;
}

.rtl #menu-btn {
  float: left;
}

header.header-mobile.header-light,
header.header-mobile.header-light #menu-btn,
header.header-mobile.header-light #menu-btn:hover {
  background: none;
}

header.header-mobile.header-light #menu-btn {
  color: #222;
}

/* ================================================== */

/* content */

/* ================================================== */

#content {
  width: 100%;
  background: #fff;
  padding: 90px 0 90px 0;
  z-index: 100;
}

.de_light #content {
  background: #ffffff;
}

#content {
  background: #ffffff;
}

.dark-scheme #content {
  background: #ffffff;
}

/* ================================================== */

/* section */

/* ================================================== */

section {
  padding: 90px 0 90px 0;
  position: relative;
}

.dark-scheme section {
  background: transparent;
}

section:not(.relative) {
}

section.no-bg,
#content.no-bg {
  background: none !important;
}

.bgcolor-variation {
  background: #f6f6f6;
}

.overlay-gradient {
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  padding: 90px 0 90px 0;
}

.de-navbar-left .container-fluid .container {
  width: 100%;
}

.de-navbar-left section {
  padding: 70px;
}

.de_light section {
  background: #ffffff;
}

.section-fixed {
  position: fixed;
  width: 100%;
  min-height: 100%;
  height: auto;
}

.full-height {
  overflow: hidden;
}

#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.text_top {
  position: relative;
  z-index: 1000;
}

/* ================================================== */

/* footer */

/* ================================================== */

footer {
  /*background:url('../images/logo-big.png') -50% center no-repeat var(--primary-color) !important;*/
  color: #9fa4dd;
}
.mainFooter {
  padding-top: 50px;
}
.dark-scheme footer {
  background: #292f45;
}

footer.footer-light {
  background: #ffffff !important;
  border-top: solid 1px #eeeeee;
  color: #595d69;
}

footer.footer-light a {
  color: #595d69;
}

.dark-scheme footer a {
  color: #9fa4dd;
}

footer.footer-black {
  background: #222222;
}

footer h5,
.de_light footer h5 {
  color: #fff;
  font-size: 18px;
  text-transform: none;
}

footer a {
  font-weight: 400;
  color: #ffffff;
  text-decoration: none !important;
}

.de-navbar-left footer {
  padding: 70px 70px 0 70px;
}

.subfooter {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  margin-top: 40px;
  padding: 20px 0 20px 0;
}

.footer-light .subfooter {
  border-top: solid 1px #eeeeee;
}

.de-navbar-left .subfooter {
  background: none;
}

header.side-header {
  border-bottom: none;
  margin: 0;
}

header.side-header .social-icons-2 {
  margin: 0 auto;
  margin-top: 50px;
  float: none;
  text-align: center;
}

header.side-header .social-icons-2 a {
  display: inline-block;
  margin: 0 2px 0 2px;
  background: #555;
  width: 36px;
  height: 36px;
  padding-top: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  opacity: 0.2;
}

.de_light header.side-header .social-icons-2 a {
  background: #fff;
  color: #333;
}

header.side-header .social-icons-2 a:hover {
  opacity: 1;
}

.de-navbar-left.de_light header,
.de-navbar-left.de_light {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

header.header-light-transparent {
  background: rgba(255, 255, 255, 0.5);
}

header.header-solid.header-light #mainmenu > li > a {
  color: #555;
}

header.header-solid.header-light .info {
  background: #eee;
  color: #555;
}

header.header-solid.header-light .info .social i {
  color: #222;
}

header.header-solid {
  background: #fff;
}

header.header-light {
  background: #ffffff;
}

header.smaller.header-dark {
  background: #222222;
  border: none;
}

header.smaller.header-dark #mainmenu a {
  color: #fff;
}

header.s2 {
  background: #ffffff !important;
  border-bottom: solid 1px #eee;
  top: 0;
}

header.s2 #mainmenu > li > a {
  padding-top: 18px;
  padding-bottom: 18px;
}

.side-layout header.smaller.header-dark #mainmenu > li {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.side-layout header.smaller.header-dark #mainmenu > li:last-child {
  border-bottom: none;
}

header.header-mobile {
  position: absolute;
  background: #fff;
  top: 0;
  height: 90px;
}

.dark-scheme header.header-mobile {
  background: #161d30;
}

header.header-mobile.has-topbar {
  height: 100px;
}

header.header-mobile.has-topbar .header-row,
header.header-mobile.has-topbar .header-col {
  margin-top: 10px;
}

header.header-mobile.has-topbar #topbar {
  margin-top: -15px;
  padding: 0;
}

header.header-mobile nav {
  float: none;
}

header.header-mobile .logo {
  display: none;
}

header.header-mobile .btn-rsvp {
  padding-top: 40px;
  height: 90px;
}

header.header-mobile .logo-2 {
  display: inline-block;
}

header.header-mobile.header-light {
  background: #fff;
}

header.header-mobile #mainmenu ul {
  padding: 0;
}

header.header-solid.header-light {
  background: #fff;
}

header.header-light .logo {
  display: none;
}

header.header-light .logo-2 {
  display: inline-block;
}

/* topbar start here */

#topbar {
  z-index: 1000;
  width: 100%;
  padding: 0 30px;
  overflow: hidden;
  top: 0;
}

#topbar.topbar-dark {
  background: #202020;
}

#topbar a {
  text-decoration: none;
  color: #606060;
}

#topbar.text-light a {
  color: #ffffff;
}

.topbar-left,
.topbar-right {
  display: flex;
}

.topbar-right {
  float: right;
}

.topbar-solid {
  background: #ffffff;
}

.tb-light {
  background: #ffffff;
}

#topbar:not(.topbar-noborder) .topbar-right .topbar-widget {
  border-left: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .topbar-right span:last-child {
  border-right: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-right .topbar-widget {
  border-left: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-right span:last-child {
  border-right: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .tb-light .topbar-right .topbar-widget {
  border-left: solid 1px #eeeeee;
}

#topbar:not(.topbar-noborder) .tb-light .topbar-right span:last-child {
  border-right: solid 1px #eeeeee;
}

/* topbar left content */
.topbar-left {
  float: left;
}

#topbar:not(.topbar-noborder) .topbar-left .topbar-widget {
  border-right: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-left .topbar-widget {
  border-right: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .topbar-left span:first-child {
  border-left: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .tb-light .topbar-left .topbar-widget {
  border-right: solid 1px #eeeeee;
}

#topbar:not(.topbar-noborder) .tb-light .topbar-left span:first-child {
  border-left: solid 1px #eeeeee;
}

.h-phone {
  position: relative;
  font-weight: bold;
  color: #ffffff;
  font-family: var(--body-font);
  padding-left: 40px;
  font-size: 18px;
  padding-top: 20px;
}

.h-phone span {
  display: inline-block;
  position: absolute;
  left: 40px;
  top: 0;
  font-size: 12px;
  font-weight: 500;
}

.h-phone i {
  display: inline-block;
  position: absolute;
  font-size: 32px;
  left: 0;
  top: 12px;
}

/* topbar widget */
.topbar-widget {
  font-size: 13px;
  display: flex;
  padding: 6px 15px;
  font-weight: 400;
  height: 40px;
}

.topbar-widget i {
  font-size: 16px;
  margin-top: 8px;
  margin-right: 10px;
}

.h-sub .topbar-widget {
  padding: 12px;
}

.topbar-widget:before {
  float: left;
  position: relative;
  font-family: "FontAwesome";
  font-size: 16px;
  margin-right: 10px;
}

.topbar-widget.tb-phone:before {
  content: "\f095";
}

.topbar-widget.tb-email:before {
  content: "\f003";
}

.topbar-widget.tb-opening-hours:before {
  content: "\f017";
}

.topbar-widget.tb-social {
  padding: 0;
}

.topbar-widget.tb-social a {
  font-size: 14px;
  display: flex;
  padding: 5px;
  padding-left: 15px;
  display: inline-block;
  text-align: center;
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-widget.tb-social a,
#topbar:not(.topbar-noborder)
  .topbar-light
  .topbar-widget.tb-social
  a:first-child {
  border-left: solid 1px #eeeeee;
}

#topbar:not(.topbar-noborder) .topbar-widget.tb-social a {
  border-left: solid 1px rgba(255, 255, 255, 0.1);
}

.tb-light .topbar-widget.tb-social a {
  border-left: solid 1px #eeeeee;
}

.topbar-widget.tb-social a:first-child {
  border: none;
}

/* topbar end here */

#de-sidebar {
  padding: 0px;
}

/* ================================================== */

/* blog */

/* ================================================== */

.blog-list {
  margin: 0;
  padding: 0;
}

/* blog list */

.blog-list > div {
  list-style: none;
  line-height: 1.7em;
  margin-top: 0px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
}

.blog-list img {
  margin-bottom: 20px;
}

.de_light .blog-list li {
  border-bottom: solid 1px #ddd;
}

.blog-list h4 {
  margin-top: 20px;
}

.blog-list h4 a {
  color: #fff;
}

.de_light .blog-list h4 a {
  color: #222222;
}

.de_light .text-light .blog-list h4 a {
  color: #fff;
}

.blog-list .btn-more {
  padding: 3px 25px 3px 25px;
  color: #111;
  text-decoration: none;
  font-weight: 700;
  float: right;
}

.blog-list .btn-more:hover {
  background: #fff;
}

.de_light .blog-list .btn-more:hover {
  background: #222;
  color: #fff;
}

.blog-list .post-text {
  padding-left: 80px;
}

.bloglist .post-text a.btn-main {
  display: inline-block;
  margin-top: 0px;
  padding: 5px 25px;
}

.blog-list .blog-slider {
  margin-bottom: -60px;
}

.blog-list .date-box {
  position: absolute;
  text-align: center;
  text-shadow: none;
}

.blog-list .date-box .day,
.blog-list .date-box .month {
  display: block;
  color: #fff;
  text-align: center;
  width: 40px;
  z-index: 100;
}

.de_light .blog-list .date-box .month {
  color: #222222;
}

.blog-list .date-box {
  text-align: center;
  background: #f6f6f6;
  padding: 10px;
}

.blog-list .date-box .month {
  font-size: 14px;
}

.blog-list .date-box .day {
  font-family: var(--body-font);
  color: #222222;
  font-weight: bold;
  font-size: 28px;
}

.blog-list .date-box .month {
  font-family: var(--body-font);
  color: #fff;
  font-size: 13px;
  letter-spacing: 3px;
}

.de_light .blog-list .date-box .month {
  border-bottom-color: #ddd;
}

.de_light .text-light .blog-list .date-box .month {
  color: #fff;
}

.blog-snippet li {
  border-bottom: none;
  margin-bottom: 0;
}

#blog-carousel .item.item {
  width: 100%;
}

/* blog comment */

#blog-comment {
  margin-top: 40px;
}

#blog-comment h5 {
  margin-bottom: 10px;
}

#blog-comment ul,
#blog-comment li {
  list-style: none;
  margin-left: -40px;
  padding-left: 0;
}

#blog-comment li {
  min-height: 70px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #eee;
}

.dark-scheme #blog-comment li {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

#blog-comment li .avatar {
  position: absolute;
  margin-top: 5px;
}

#blog-comment .comment {
  margin-left: 85px;
}

#blog-comment li {
  min-height: 70px;
  font-size: 14px;
  line-height: 1.6em;
}

#blog-comment li li {
  margin-left: 55px;
  padding-bottom: 0px;
  margin-top: 20px;
  padding-top: 10px;
  border-top: solid 1px #eee;
  border-bottom: none;
}

.dark-scheme #blog-comment li li {
  border-top-color: rgba(255, 255, 255, 0.1);
}

#blog-comment li li .avatar {
  position: absolute;
}

#blog-comment li li .comment {
}

.comment-info {
  margin-left: 85px;
  margin-bottom: 5px;
}

.comment-info span {
}

.comment-info .c_name {
  display: block;
  font-weight: 700;
  color: #555555;
}

.dark-scheme .comment-info .c_name {
  color: #ffffff;
}

.comment-info .c_reply {
  padding-left: 20px;
  margin-left: 20px;
  border-left: solid 1px #ddd;
  font-size: 12px;
}

.dark-scheme .comment-info .c_reply {
  border-left-color: rgba(255, 255, 255, 0.2);
}

.comment-info .c_date {
  font-size: 12px;
}

.blog-list h4 {
  color: #333;
}

.blog-list .post-wrapper {
}

.blog-read h4 {
  color: #333;
  letter-spacing: normal;
  font-size: 24px;
  text-transform: none;
}

.blog-carousel ul,
.blog-carousel li {
  margin: 0;
  list-style: none;
}

.blog-carousel h4 {
  margin-bottom: 0px;
}

.blog-carousel p {
  margin-bottom: 10px;
}

.blog-carousel .post-date {
  letter-spacing: 3px;
  font-size: 11px;
  color: #888;
}

.post-content {
  position: relative;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.post-content p {
  margin-bottom: 20px;
}

.post-text {
  padding-top: 20px;
}

.post-text h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.post-text h4 a {
  color: #1a1c26;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
}

.dark-scheme .post-text h4 a {
  color: #ffffff;
}

.post-text .tags {
  font-size: 10px;
}

.p-tagline {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
}

.p-tagline,
.p-title {
  font-family: var(--body-font);
  background: rgba(var(--primary-color-rgb), 0.2);
  border-radius: 3px;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 10px;
  padding: 7px 10px 6px 10px;

  margin-bottom: 5px;
}

.p-tagline,
.p-title.invert {
  background: var(--secondary-color);
}

.p-title {
  font-size: 12px;
  margin-bottom: 20px;
}

.post-text .p-date {
  letter-spacing: 2px;
  font-size: 10px;
  font-weight: 500;
}

.post-info {
  border-top: solid 1px #eeeeee;
  padding-top: 15px;
}

.blog-read img {
  margin-bottom: 20px;
}

.blog-read .post-text {
  padding: 0;
}

.post-date,
.post-comment,
.post-like,
.post-by,
.post-author {
  margin-right: 20px;
  font-size: 13px;
  color: #999;
  font-family: var(--body-font);
}

.dark-scheme .post-date,
.dark-scheme .post-comment,
.dark-scheme .post-like,
.dark-scheme .post-by,
.dark-scheme .post-author {
  color: #ffffff;
}

.post-author {
  color: #777;
}

.post-date:before,
.post-comment:before,
.post-like:before,
.post-by:before {
  font-family: FontAwesome;
  padding-right: 10px;
  font-size: 11px;
}

/*.post-date:before{
    content: "\f133";
}*/
.post-comment:before {
  content: "\f0e5";
}

.post-like:before {
  content: "\f08a";
}

.post-author:before {
  content: "By: ";
}

/* blog comment form */

#commentform label {
  display: block;
}

#commentform input {
  width: 290px;
}

#commentform input:focus {
  border: solid 1px #999;
  background: #fff;
}

#commentform textarea {
  width: 97%;
  padding: 5px;
  height: 150px;
  color: #333;
}

#commentform textarea:focus {
  border: solid 1px #999;
  background: #fff;
}

#commentform input.btn {
  width: auto;
}

.post-meta {
  margin: 0px 0 10px 0px;
  border: solid 1px #eee;
  border-left: none;
  border-right: none;
  border-left: none;
  font-size: 11px;
  display: table;
  width: 100%;
  table-layout: fixed;
}

.post-meta a {
  color: #888;
}

.post-meta span {
  display: table-cell;
  padding: 10px 0 10px 0;
  text-align: center;
}

.post-meta span i {
  float: none;
}

.post-image img {
  width: 100%;
  margin-bottom: 0px;
}

.blog-slide {
  padding: 0;
  margin: 0;
}

.blog-item {
  margin-bottom: 60px;
}

.blog-item img.preview {
  width: 100%;
  margin-bottom: 30px;
}

.post-image {
  position: relative;
}

.post-image .post-info {
  position: absolute;
  bottom: 0;
  padding: 0;
  border: none;
  width: 100%;
}

.post-image .post-info .inner {
  margin: 0 30px 0 30px;
  padding: 3px 20px;
  border-radius: 10px 10px 0 0;
}

.post-image .post-date,
.post-image .post-author {
  color: #ffffff;
}

/* ================================================== */

/* products */

/* ================================================== */
.nft__item_info {
  margin-top: 25px;
}
.products {
  padding: 0;
  list-style: none;
  text-align: center;
}

.products li {
  margin-bottom: 40px;
}

.product .price {
  margin-bottom: 10px;
  font-size: 20px;
  color: #222;
}

.de_light .product h4 {
  font-size: 16px;
  margin: 0;
  margin-top: 20px;
}

.product img {
  padding: 2px;
  background: #333;
  border: solid 1px #fff;
}

.de_light .product img {
  padding: 0;
  border: solid 1px #eee;
}

.ratings {
  color: #f86e4e;
}

.product .ratings {
  margin: 5px 0 5px 0;
}

/* ================================================== */

/* contact form */

/* ================================================== */

.error {
  clear: both;
  display: none;
  color: #e7505a;
  padding-top: 20px;
}

.success {
  clear: both;
  display: none;
  color: #96c346;
  padding-top: 20px;
}

.error img {
  vertical-align: top;
}

.full {
  width: 98%;
}

#contact_form textarea {
  height: 195px;
}

#contact_form.s2 textarea {
  height: 180px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
}

.form-underline input[type="text"],
.form-underline textarea,
.form-underline input[type="email"],
.form-underline select {
  padding: 10px 0 10px 0;
  margin-bottom: 20px;
  border: none;
  border-bottom: solid 2px #bbbbbb;
  background: none;
  border-radius: 0;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.form-underline select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  /* background: url('../images/ui/arrow-down-form.png') no-repeat 98% 60%;*/
}

.form-underline select:focus {
  /*background: url('../images/ui/arrow-down-form-hover.png') no-repeat 98% 60%;*/
}

.form-underline input[type="text"],
.form-underline textarea,
.form-underline input[type="email"],
.form-underline select {
  color: #333;
  border-bottom: solid 1px #bbb;
}

.form-default input[type="text"],
.form-default textarea,
.form-default input[type="email"],
.form-default select {
  padding: 10px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  font-weight: 400;
  font-size: 18px;
}

#contact_form.form-default textarea {
  padding: 10px;
  height: 170px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

#form_subscribe input[type="text"] {
  padding: 7px 12px 7px 12px;
  width: 80%;
  float: left;
  display: table-cell;
  border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  border: none;
  border-right: none;
  background: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
}

.d-create-file {
  padding: 50px;
  border-radius: 10px;
  border: dashed 3px #dddddd;
  text-align: center;
}

.dark-scheme .d-create-file {
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-scheme .d-create-file p {
  color: #ffffff;
}

footer:not(.footer-light)
  #form_subscribe
  input[type="text"]::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1 !important;
}

footer:not(.footer-light)
  #form_subscribe
  input[type="text"]::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1 !important;
}

footer:not(.footer-light) #form_subscribe.form-dark input[type="text"] {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

.footer-light #form_subscribe input[type="text"] {
  border: solid 1px #333333;
  border-right: none;
  padding: 6px 12px;
}

#form_subscribe input[type="text"]:focus {
  background: rgba(255, 255, 255, 0.2);
}

#form_subscribe #btn-subscribe i {
  text-align: center;
  font-size: 28px;
  float: left;
  width: 20%;
  background: #171a21;
  color: #ffffff;
  display: table-cell;
  padding: 5px 0 5px 0;
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
}

#form_quick_search {
  font-family: var(--body-font);
  display: inline-block;
  margin-right: 0px;
  margin-bottom: 0px;
}

#form_quick_search input[type="text"] {
  padding: 8px 12px;
  width: 200px;
  float: left;
  display: table-cell;
  border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  border: solid 1px #ddd;
  border-right: none;
  background: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  font-size: 16px;
}

.dark-scheme #form_quick_search input[type="text"] {
  color: #ffffff;
  background: #150523;
  border-color: rgba(255, 255, 255, 0.2);
}

#form_quick_search #btn-submit i {
  text-align: center;
  font-size: 16px;
  float: left;
  width: 60px;
  background: #171a21;
  color: #ffffff;
  display: table-cell;
  padding: 13px 0;
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
}

.text-light #form_quick_search input[type="text"] {
  color: #ffffff;
  background: none;
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.text-light #form_quick_search input[type="text"] {
  color: #ffffff;
}

.text-light #form_quick_search input[type="text"]::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1 !important;
}

.text-light #form_quick_search input[type="text"]::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1 !important;
}

.header-light #quick_search {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.form-default input[type="text"]:focus,
.form-default textarea:focus,
.form-default input[type="email"]:focus,
.form-default select:focus {
  background: #f6f6f6;
}

.error_input {
  border-bottom: solid 1px #ff0000 !important;
}

/* ================================================== */

/* coming soon page */

/* ================================================== */

.coming-soon .logo {
  margin: 0;
  padding: 0;
}

.coming-soon h2 {
  margin-top: 0;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
}

.coming-soon .social-icons i {
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.coming-soon .social-icons i:hover {
  background: none;
  color: rgba(255, 255, 255, 0.2);
}

.arrow-up,
.arrow-down {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 40px solid #fff;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: poInter;
  z-index: 101;
}

.arrow-down {
  border-top: 40px solid #fff;
  border-bottom: none;
  position: fixed;
  z-index: 102;
  top: -40px;
}

.arrow-up:before {
  font-family: "FontAwesome";
  font-size: 20px;
  content: "\f067";
  color: #222;
  position: relative;
  top: 12px;
  margin: 0 0 0 -8px;
}

.arrow-down:before {
  font-family: "FontAwesome";
  font-size: 20px;
  content: "\f068";
  color: #222;
  position: relative;
  top: -35px;
  margin: 0 0 0 -8px;
}

/* ================================================== */

/* elements */

/* ================================================== */

/* address */

/* ------------------------------ */
address {
  margin-top: 20px;
}

address span {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 14.5px;
  background: #f5f5f5;
  border-radius: 300px;
}

address span i {
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 15px;
}

.rtl address span i {
  margin-left: 15px;
  margin-right: 5px;
}

.text-light address span a {
  color: #ffffff;
}

.text-white address span a,
.text-white address span a:hover {
  color: #fff;
}

address.s1 span {
  background: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 1.7em;
  margin-bottom: 5px;
}

address.s1 span i {
  font-size: 15px;
}

address span strong {
  display: inline-block;
}

.de_light #sidebar .widget address span {
  border-bottom: solid 1px #ddd;
}

.de_light #sidebar .widget address a {
  color: #333;
}

/* border */

/* ------------------------------ */

.small-border {
  width: 50px;
  height: 2px;
  background: rgba(0, 0, 0, 0.5);
  border-left: none;
  border-right: none;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}

.small-border.sm-left {
  margin-left: 0;
}

.rtl .small-border.sm-left {
  margin-right: 0;
}

.text-light .small-border {
  background: rgba(255, 255, 255, 0.2);
}

.tiny-border {
  height: 1px;
  background: #333;
  width: 40px;
  margin: 20px 0 20px 0;
  display: block;
}

.tiny-border.center {
  margin-left: auto;
  margin-right: auto;
}

.small-border.white {
  border-top: solid 2px #ffffff;
}

/* breadcrumb */

/* ------------------------------ */

.crumb {
  color: #777;
  margin: 0;
  padding: 0;
}

.de-navbar-left.de_light #subheader .crumb {
  color: #333;
}

.crumb li {
  display: inline;
  text-decoration: none;
  letter-spacing: 0;
}

.crumb li:after {
  font-family: "FontAwesome";
  content: "\f054";
  font-size: 10px;
  margin: 0 10px 0 10px;
  opacity: 0.5;
}

.crumb li:last-child:after {
  display: none;
}

.crumb a {
  text-decoration: none;
}

.crumb li .active {
  font-weight: bold;
}

.de_light .crumb a:hover {
  color: #fff;
}

/* box icon */

.icon_box .icon_wrapper {
  margin-bottom: 20px;
}

.icon_box .icon_wrapper i {
  font-size: 60px;
}

.icon_box.icon_left .icon_wrapper {
  position: absolute;
  width: 80px;
}

.icon_box.icon_left .desc_wrapper {
  margin-left: 80px;
}

.icon_box.icon_right .icon_wrapper {
  position: absolute;
  right: 0;
  width: 80px;
}

.icon_box.icon_right .desc_wrapper {
  margin-right: 80px;
  text-align: right;
}

.icon-big {
  border-radius: 100%;
  text-align: center;
  font-size: 56px;
  display: inline-block;
  padding: 16px 0;
  width: 96px;
  height: 96px;
  -webkit-box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), 0.3);
  -moz-box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), 0.3);
  box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), 0.3);
}

.icon-box {
  display: block;
  text-align: center;
  border: solid 1px #dddddd;
  padding: 20px 0 20px 0;
}

.icon-box.s2 {
  margin: 1%;
}

.icon-box.style-2 {
  border: none;
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.text-light .icon-box {
  border: solid 2px rgba(255, 255, 255, 0.1);
}

.icon-box i {
  display: block;
  font-size: 40px;
  margin-bottom: 10px;
}

.icon-box span {
  display: block;
  color: #606060;
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
  line-height: 1.4em;
}

.dark-scheme .icon-box span {
  color: #ffffff;
}

.text-light .icon-box span {
  color: #ffffff;
}

.icon-box:hover {
  color: #ffffff;
  -webkit-box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), 0.3);
  -moz-box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), 0.3);
  box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), 0.3);
}

.icon-box:hover i,
.icon-box:hover span {
  color: #ffffff;
}

/* button and link */

/* ------------------------------ */

a {
  text-decoration: none;
}

.de_light a {
  color: #888;
}

.de_light a:hover {
  color: #555;
}

a.btn-text {
  text-decoration: none;
  display: inline-block;
  color: #111;
  font-weight: 600;
  padding: 0;
}

a.btn-text:after {
  font-family: FontAwesome;
  content: "\f054";
  padding-left: 10px;
}

a.btn-text {
  color: #fff;
}

a.btn-big {
  font-size: 14px;
  color: #eceff3;
  letter-spacing: 1px;
  line-height: normal;
  font-weight: bold;

  border: solid 1px #fff;
  padding: 10px 30px 10px 30px;
}

a.btn-big:after {
  font-family: FontAwesome;
  content: "\f054";
  margin-left: 20px;
}

a.btn,
.btn {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

a.btn:before {
  content: "";
  background: rgba(0, 0, 0, 0);
  width: 0;
  height: 100%;
  position: absolute;
  z-index: -1;
}

a.btn-fx:after {
  font-family: FontAwesome;
  content: "\f178";
  margin-left: 15px;
  position: absolute;
  right: -20px;
  margin-top: 0px;
}

a.btn-fx {
  font-size: 14px;
  color: #eceff3;
  letter-spacing: 1px;
  line-height: normal;
  font-weight: bold;

  border: solid 1px #fff;
  padding: 10px 30px 10px 30px;
}

a.btn-fx:hover {
  padding-left: 20px;
  padding-right: 40px;
}

a.btn-fx:before {
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

a.btn-fx:hover:after {
  right: 15px;
}

a.btn-fx:hover:before {
  width: 100%;
  background: rgba(0, 0, 0, 1);
}

a.btn-fx.light:hover:before {
  width: 100%;
  background: rgba(255, 255, 255, 1);
}

.btn-fullwidth {
  width: 100%;
}

a.btn-slider {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: normal;
  text-decoration: none;

  border: solid 2px #fff;
  padding: 10px 30px 10px 30px;
  border-radius: 60px;
}

a.btn-slider:hover {
  color: #222;
  background: #fff;
  border-color: #000;
  border: solid 2px #fff;
}

.input-group-append .btn-main {
  border-radius: 0 6px 6px 0;
}
a.btn-slider:hover:after {
  color: #222;
}

a.btn-id,
a.btn-id:hover {
  border: none;
}

a.btn-light.btn-id {
  color: #222;
}

a.btn-dark.btn-id {
  color: #fff;
}

.btn-main.btn-small {
  padding: 5px 20px 5px 20px;
  font-weight: bold;
}

.btn-fx.btn-main {
  text-transform: normal;
}

a.btn-bg-dark {
  background: #222;
}

a.btn-text-light {
  color: #fff;
}

.btn-icon-left i {
  margin-right: 12px;
  color: #fff;
}

.btn-add_to_cart,
a.btn-add_to_cart {
  border: solid 1px #bbb;
  font-size: 12px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 3px;
  padding-left: 40px;
  padding-right: 20px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #555;
}

.btn-add_to_cart:before,
a.btn-add_to_cart:before {
  font-family: "FontAwesome";
  content: "\f07a";
  position: absolute;
  left: 20px;
}

a.btn-main,
a.btn-main:active,
a.btn-main:focus,
a.btn-main:visited,
.btn-main,
input[type="button"].btn-main,
a.btn-line {
  text-align: center;
  color: #fff;
  outline: 0;
  font-weight: 600;
  text-decoration: none;
  padding: 8px 30px;
  font-size: 16px;
  border: none;
}
.input-group-append .btn-main {
  clip-path: none;
}
a.btn-line,
a.btn-line:hover {
  background: none;
  color: #0d0c22;
  border: solid 2px rgba(0, 0, 0, 0.1);
}

a.btn-line:hover {
  color: #ffffff;
  border-color: rgba(0, 0, 0, 0) !important;
}

header:not(.header-light) a.btn-line,
.dark-scheme a.btn-line,
.dark-schem a.btn-line:hover {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.1);
}

a.btn-main.btn-white {
  background: #ffffff;
}

header a.btn-main,
header a.btn-line {
  padding: 7px 20px 7px 20px !important;
  position: relative;
  background: #fc943e;
  color: #ffffff !important;
  transition: none !important;

  min-width: 100px;
}
header a.btn-main:hover {
  background-color: #ffffff !important;
  background-image: none !important;
  color: #000000 !important;
}

/* header a.btn-main::after{
  content: "";
  background: url(../images/btnrt-shape.png) no-repeat scroll 0 0;
  width: 28px;
  height: 40px;
  position: absolute;
  top: 0;
  right: -28px;
  filter: contrast(0) brightness(0) invert(1);
} */
/* header a.btn-main:hover::after{
  filter: unset;
} */

header a.btn-main i {
  display: none;
}

.col-right a.btn-main {
  font-size: 14px;
  text-transform: none;
}

a.btn-border {
  border: solid 2px rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  min-width: 120px;
  outline: 0;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 30px;
  min-width: 120px;

  font-size: 12px;
  letter-spacing: 2px;
}

a.btn-border:hover {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0);
  margin-top: -2px;
  margin-bottom: 2px;
  box-sizing: border-box;
}

a.btn-border.light {
  border: solid 1px #ffffff;
  color: #ffffff;
}

a.btn-border.light:hover {
  background: #ffffff;
}

a.btn-border:hover a {
  color: #ffffff !important;
}

.d-btn-close {
  color: #ffffff;
  cursor: poInter;
  text-align: center;
  display: block;
  text-align: center;
  width: 60px;
  height: 60px;
  background: #333;
  font-size: 32px;
  font-weight: bold;
  text-decoration: none;
  margin: 0 auto;
  padding-top: 12px;
  position: absolute;
  left: 0;
  right: 0;
}

a.btn-link {
  display: block;
  text-decoration: none;
  margin-top: 10px;
}

.cover a.btn-link:hover {
  color: #fff;
}

/*
    a.btn-rsvp:before{
    font-size:32px;
    font-family:"FontAwesome";
    content:"\f073";
    margin-right:15px;
    display:block;
    margin:0 auto;
    margin-bottom:10px;
    }
    */

.play-button {
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  color: #fff;
  text-decoration: none;
  border: solid 5px rgba(255, 255, 255, 0.3);
  display: inline-block;
  text-align: center;
  width: 80px;
  height: 80px;
  padding-top: 22px;
  padding-left: 5px;
}

.play-button:before {
  font-family: "FontAwesome";
  font-size: 20px;
  content: "\f04b";
  position: relative;
  color: #fff;
}

.play-button:hover {
  border: solid 5px rgba(255, 255, 255, 1);
}

.play-button.dark {
  color: #222;
  border: solid 5px rgba(0, 0, 0, 0.1);
}

.play-button.dark:before {
  color: #222;
}

.play-button.dark:hover {
  border: solid 5px rgba(0, 0, 0, 0.5);
}

.text-light .play-button {
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  color: #fff;
  text-decoration: none;
  border: solid 5px rgba(255, 255, 255, 0.3);
  display: inline-block;
  text-align: center;
  width: 80px;
  height: 80px;
  padding-top: 22px;
  padding-left: 5px;
}

.text-light .play-button:before {
  font-family: "FontAwesome";
  font-size: 20px;
  content: "\f04b";
  position: relative;
  color: #fff;
}

.text-light .play-button.dark {
  color: #fff;
  border: solid 5px rgba(255, 255, 255, 0.3);
}

.text-light .play-button.dark:before {
  color: #fff;
}

.text-light .play-button.dark:hover {
  border: solid 5px rgba(255, 255, 255, 1);
}

/* columns */

/* ------------------------------ */

/* counter */

/* ------------------------------ */

.de_count {
  padding: 20px 0 10px 0;
  background: none;
}

.de_count h3 {
  font-size: 36px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  line-height: 0.5em;
}

.de_count h5 {
  font-size: 14px;
  font-weight: 500;
}

.de_count h3 span {
  font-size: 36px;
}

.de_count.big h3 {
  font-size: 120px;
}

.de_count span {
  font-size: 12px;
}

.de_count i {
  display: block;
  font-size: 30px;
  margin: 0 auto;
  margin-bottom: 0px;
  width: 100%;
  font-weight: lighter;
}

.de_count.small h4 {
  font-size: 28px;
  margin-bottom: 0;
}

/* divider */

/* ------------------------------ */

.spacer-single {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}

.spacer-double {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}

.spacer-half {
  width: 100%;
  height: 15px;
  display: block;
  clear: both;
}

.spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}

.spacer-20 {
  width: 100%;
  height: 20px;
  display: block;
  clear: both;
}

.spacer-30 {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}

.spacer-40 {
  width: 100%;
  height: 40px;
  display: block;
  clear: both;
}

.spacer-50 {
  width: 100%;
  height: 50px;
  display: block;
  clear: both;
}

.spacer-60 {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}

hr {
  display: block;
  clear: both;
  border-top: solid 1px #ddd;
  margin: 40px 0 40px 0;
}

/* dropcap */

/* ------------------------------ */

.dropcap {
  display: inline-block;
  font-size: 48px;
  float: left;
  margin: 10px 15px 15px 0;
  color: #eceff3;
  padding: 20px 10px 20px 10px;
}

/* form */

/* ------------------------------ */

.form-transparent input[type="text"],
.form-transparent input[type="number"],
.form-transparent textarea,
.form-transparent input[type="email"] {
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
  border: solid 1px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0;
  height: auto;
}

.form-border input[type="text"],
.PhoneInputInput,
.form-border input[type="number"],
.form-border textarea,
.form-underline input[type="email"],
.form-border input[type="password"],
.form-border input[type="date"],
.form-border select {
  padding: 8px;
  margin-bottom: 20px;
  border: none;
  border: solid 1px #cccccc;
  background: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: #333;
}
.PhoneInputInput {
  margin-bottom: 0 !important;
}
.dark-scheme .form-border input[type="text"],
.PhoneInputInput,
.dark-scheme .form-border input[type="number"],
.dark-scheme .form-border textarea,
.dark-scheme .form-underline input[type="email"],
.dark-scheme .form-border input[type="password"],
.dark-scheme .form-border input[type="date"],
.dark-scheme .form-border select {
  color: #ffffff;
  border: solid 1px rgba(255, 255, 255, 0.2);
  background: #150523;
}

.dark-scheme ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.dark-scheme .form-border input::-moz-input-placeholder,
.dark-scheme .form-border textarea::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1 !important;
}

.dark-scheme .form-border input::-webkit-input-placeholder,
.dark-scheme .form-border textarea::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1 !important;
}

.bor-0 {
  border: 0 !important;
}
.form-subscribe {
  display: table;
  margin: 0 auto;
}

.form-subscribe input[type="text"] {
  display: table-cell;
  border: solid 1px rgba(255, 255, 255, 0.3);
  border-right: none;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 20px 6px 20px;
  border-radius: 60px 0 0 60px;
  height: 40px;
  vertical-align: middle;
}

.form-subscribe .btn-main {
  display: table-cell;
  border-radius: 0 60px 60px 0;
  height: 40px;
  vertical-align: middle;
}

.form-subscribe input[type="text"]:hover {
  background: rgba(255, 255, 255, 0.3);
}

.field-set label {
  color: #606060;
  font-weight: 500;
}

.dark-scheme .field-set label {
  color: #ffffff;
}
.uploadCustomFile {
  position: relative;
}
#upload_file {
  position: absolute;
  left: 50%;
  top: 5px;
  width: 130px;
  height: 42px;
  margin-left: -65px;
  opacity: 0;
  cursor: pointer;
}

#form-create-item textarea {
  height: 45px;
}

.de_form input[type="radio"],
.de_form input[type="checkbox"] {
  /* hide the inputs */
  display: none;
}

.de_form input[type="radio"] + label:before {
  content: "\f111";
  font-family: "FontAwesome";
  margin-right: 10px;
  border: solid 1px #dddddd;
}

.de_form input[type="checkbox"] + label:before {
  position: absolute;
  content: "";
  display: inline-block;
  font-family: "FontAwesome";
  margin-right: 10px;
  border: solid 2px rgba(0, 0, 0, 0.2);
  width: 16px;
  height: 16px;
  margin-top: 5px;
  left: 0;
  font-size: 11px;
  padding: 1px 3px 0 3px;
  line-height: 15px;
  border-radius: 4px;
}

.dark-scheme .de_form input[type="checkbox"] + label:before {
  border: solid 2px rgba(255, 255, 255, 0.2);
}

.de_checkbox {
  position: relative;
  display: block;
  padding-left: 25px;
}

.de_form input[type="radio"] + label,
.de_form input[type="checkbox"] + label {
  cursor: pointer;
  border: none;
  border-radius: 3px;
}

.no-bg input[type="radio"] + label,
.de_form.no-bg input[type="checkbox"] + label {
  padding: 4px 0px;
  border: none;
  background: none;
}

.de_form input[type="radio"]:checked + label,
.de_form input[type="checkbox"]:checked + label {
  /* style for the checked/selected state */
  border: none;
}

.de_form input[type="checkbox"]:checked + label:before {
  content: "\f00c";
  color: #ffffff;
  border: rgba(0, 0, 0, 0);
}

.item_filter_group {
  border: solid 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 25px;
  padding: 30px;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}

.dark-scheme .item_filter_group {
  border: solid 1px rgba(255, 255, 255, 0.075);
}

.item_filter_group h4 {
  font-size: 18px;
}

/* heading */

/* ------------------------------ */

h1,
h2,
h4,
h3,
h5,
h6,
.h1_big,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
footer.footer-light h5 {
  margin-top: 0;
  font-family: var(--title-font);
  font-weight: 700;
  color: #0d0c22;
}

.dark-scheme h1,
.dark-scheme h2,
.dark-scheme h4,
.dark-scheme h3,
.dark-scheme h5,
.dark-scheme h6,
.dark-scheme .h1_big,
.dark-scheme .h1,
.dark-scheme .h2,
.dark-scheme .h3,
.dark-scheme .h4,
.dark-scheme .h5,
.dark-scheme .h6 {
  color: #ffffff;
}

footer h1,
footer h2,
footer h4,
footer h4,
footer h5,
footer h6 {
  color: #fff;
}

h2.text-light,
footer h4,
footer h4,
footer h5,
footer h6 {
  color: #fff;
}

h1,
.h1 {
  font-size: 50px;
  margin-bottom: 20px;
  line-height: 1.2em;
  letter-spacing: -1px;
}

h1 .label {
  display: inline-block;
  font-size: 36px;
  padding: 0 6px;
  margin-left: 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

h1.s1 {
  letter-spacing: 30px;
  font-size: 26px;
}

h1 .small-border {
  margin-top: 30px;
}

h1.big,
.h1_big {
  font-size: 64px;
  margin: 0;
  line-height: 70px;
}

h1.very-big {
  font-size: 120px;
  letter-spacing: -5px;
}

h1.ultra-big {
  font-size: 140px;
  line-height: 120px;
  letter-spacing: -6px;
  font-weight: 700;
  margin-bottom: 0;
}

h1.ultra-big span {
  display: inline-block;
}

h1.ultra-big span.underline span {
  display: block;
  border-bottom: solid 12px #fff;
  position: relative;
  margin-top: -5px;
}

h1.very-big-2 {
  font-size: 90px;
  letter-spacing: 25px;

  font-weight: bold;
}

.h2_title {
  font-size: 28px;
  display: block;
  margin-top: 0;
  line-height: 1.2em;
}

h2 .small-border {
  margin-left: 0;
  margin-bottom: 15px;
  width: 40px;
}

h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 46px;
}

h2.style-2 {
  font-size: 26px;
  margin-bottom: 20px;
}

h2.big {
  font-size: 48px;
  line-height: 1.3em;
  margin-bottom: 0;
}

h2 .uptitle {
  display: block;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  font-weight: 500;
}

h2.s1,
.h2_s1 {
  font-size: 24px;
}

h2.deco-text span {
  font-family: "Parisienne";
  display: block;
  line-height: 0.85em;
  font-weight: lighter;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

h2.deco-text .md {
  font-size: 80px;
}

h2.deco-text .lg {
  font-size: 120px;
}

h2.deco-text .xl {
  font-size: 150px;
}

h3 {
  font-size: 22px;
  margin-bottom: 25px;
}

h4 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 10px;
}

h4.teaser {
  font-weight: 300;
  font-size: 22px;
}

.subtitle.s2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.subtitle.s2 span {
  margin-right: 20px;
  padding-left: 20px;
}

.subtitle.s2 i {
  margin-right: 10px;
}

.subtitle.s2 span:first-child {
  padding-left: 0;
}

h4.s1 {
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 16px;
}

h4.s2 {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 200;
  line-height: 1.8em;
}

h4.s3 {
  font-family: "Parisienne";
  font-size: 60px;
  font-weight: lighter;
}

h4.s3 {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 15px;
}

.call-to-action h4 {
  text-transform: none;
  font-size: 20px;
}

h1.slogan_big {
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: -2px;
  padding: 0;
  margin: 0px 0 30px 0;
}

h1.title {
  font-size: 64px;
  letter-spacing: 10px;
}

h1.title strong {
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
}

h1.hs1 {
  font-family: "Parisienne";
  font-size: 96px;
  display: inline-block;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

h1.hs2 {
  font-family: "Parisienne";
  font-size: 72px;
  display: inline-block;
  font-weight: lighter;
}

h2.subtitle {
  margin-top: 0;
}

h2.name {
  font-family: var(--body-font);
  color: #fff;
  font-size: 84px;
  line-height: 50px;
}

h2.name span {
  display: block;
  font-size: 32px;
}

h2.name-s1 {
  font-family: var(--body-font);
  color: #fff;
  font-size: 84px;
  font-weight: 700;
  line-height: 50px;
}

h2.name-s1 span {
  display: block;
  font-size: 32px;
}

h2.hw {
  display: block;
  font-family: "Parisienne";
  font-size: 48px;
  text-transform: none;
  font-weight: lighter;
}

h2.deco {
  font-family: var(--body-font);
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

h2.deco span {
  display: inline-block;
  position: relative;
}

h2.deco span:before,
h2.deco span:after {
  content: "";
  position: absolute;
  border-top: 1px solid #bbb;
  top: 10px;
  width: 100px;
}

h2.deco span:before {
  right: 100%;
  margin-right: 15px;
}

h2.deco span:after {
  left: 100%;
  margin-left: 15px;
}

h2.hs1 {
  font-family: var(--body-font);
  font-size: 40px;
}

h2.hs1 i {
  font-size: 48px;
  position: relative;
  top: 10px;
  color: #ff0042;
  margin: 0 10px 0 10px;
}

h2.hs1 span {
  font-size: 48px;
  position: relative;
  top: 10px;
  font-family: "Miama";
  margin: 0 15px 0 10px;
  font-weight: normal;
}

h2 .de_light .text-light h2 {
  color: #fff;
}

.text-light h2.deco span:before,
.text-light h2.deco span:after {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

h2.s2 {
  font-family: var(--body-font);
  font-weight: 400;
}

h2.s3 {
  font-size: 36px;
  margin-bottom: 20px;
}

h4.title {
  border-bottom: solid 1px #ddd;
  margin-bottom: 20px;
}

h4.style-2 {
  font-size: 18px;
}

h4.title {
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h5.s2 {
  font-family: var(--body-font);
  letter-spacing: 1px;
  font-size: 14px;
}

h5 {
  font-size: 18px;
}

h4.s1 {
  font-size: 12px;
  letter-spacing: 20px;

  font-weight: 500;
}

span.teaser {
  font-family: "Georgia";
  font-style: italic;
  font-size: 18px;
}

.wm {
  font-size: 200px;
  opacity: 0.2;
  position: absolute;
  left: 0;
  z-index: 0;
  letter-spacing: -0.05em;
}

.text-light {
  color: #ffffff;
}

.text-dark {
  color: #223044;
}

.pricing-s2.bg.text-light .top p {
  color: #fff;
}

.text-white,
.de_light .text-white {
  color: #fff;
}

.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6 {
  color: #fff;
}

.de_light .text-light h1,
.de_light .text-light h2,
.de_light .text-light h4,
.de_light .text-light h4,
.de_light .text-light h5,
.de_light .text-light h6 {
  color: #fff;
}

.text-white h1,
.text-white h2,
.text-white h4,
.text-white h4,
.text-white h5,
.text-white h6 {
  color: #fff;
}

.de_light .text-white h1,
.de_light .text-white h2,
.de_light .text-white h4,
.de_light .text-white h4,
.de_light .text-white h5,
.de_light .text-white h6 {
  color: #fff;
}

.de_light h1,
.de_light h2,
.de_light h4,
.de_light h4,
.de_light h5,
.de_light h6 {
  color: #202020;
}

/* feature box style 1 */

.feature-box .inner {
  position: relative;
  overflow: hidden;
  padding: 40px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.feature-box.s1 .inner {
  background: #ffffff;
}

.feature-box.s1:hover .inner i {
  background: none;
  color: #303030;
}

.text-light .feature-box .inner {
  color: #fff;
}

.feature-box i {
  font-size: 40px;
  margin-bottom: 20px;
}

.feature-box.left i {
  position: absolute;
}

.feature-box.left .text {
  padding-left: 70px;
}

.feature-box.center {
  text-align: center;
}

.feature-box i.circle,
.feature-box i.square {
  display: inline-block;
  padding: 20px;
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  position: relative;
  z-index: 2;
}

.feature-box i.square {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.feature-box i.circle {
  display: inline-block;
  padding: 20px;
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  position: relative;
  z-index: 2;
}

i.font60 {
  font-size: 60px;
}

.feature-box i.wm {
  font-size: 800px;
  position: absolute;
  top: 0%;
  width: 100%;
  left: 50%;
}

.feature-box:hover .inner i.wm {
  transform: scale(1.2);
  color: rgba(0, 0, 0, 0.05);
  z-index: 0;
}

.feature-box:hover .inner i.circle {
  -webkit-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
}

.feature-box.style-3 {
  position: relative;
  overflow: hidden !important;
}

.feature-box.style-3 i {
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  padding: 14px 0;
  width: 56px;
  height: 56px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;

  /*-webkit-box-shadow: 8px 8px 18px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 8px 8px 18px 0px rgba(0,0,0,0.2);
    box-shadow: 8px 8px 18px 0px rgba(0,0,0,0.2);*/
}

.feature-box.style-3 i.wm {
  position: absolute;
  font-size: 240px;
  background: none;
  width: auto;
  height: auto;
  color: rgba(var(--secondary-color-rgb), 0.5);
  right: 0;
  top: 30%;
}

.feature-box.style-3:hover i.wm {
  -webkit-transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
  transform: rotate(-25deg);
}

.feature-box.style-4 i {
  position: relative;
  z-index: 2;
  padding: 10px;
  min-width: 60px;
}

.feature-box.style-4 .wm {
  display: block;
  position: absolute;
  font-size: 160px;
  background: none;
  width: 100%;
  height: auto;
  right: 0;
  top: 120px;
  z-index: 2;
  font-weight: bold;
}

.feature-box.style-4 .text {
  margin-top: -50px;
  position: relative;
  z-index: 1;
  background: #ffffff;
  padding: 30px;
  padding-top: 50px;
}

.feature-box.f-boxed {
  overflow: none;
  padding: 50px;
  background: rgba(var(--secondary-color-rgb), 0.1);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.text-light .feature-box.f-boxed {
  background: rgba(0, 0, 0, 0.1);
}

.feature-box.f-boxed:hover {
  color: #ffffff;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
}

.feature-box.f-boxed:hover h4 {
  color: #ffffff;
}

.feature-box i.i-circle {
  border-radius: 60px;
}

.feature-box i.i-boxed {
  border-radius: 5px;
}

.feature-box-small-icon.center {
  text-align: center;
}

.dark .feature-box-small-icon .inner:hover > i {
  color: #fff;
}

.feature-box-small-icon .text {
  padding-left: 80px;
}

.feature-box-small-icon.center .text {
  padding-left: 0;
  display: block;
}

.feature-box-small-icon i {
  text-shadow: none;
  font-size: 40px;
  width: 68px;
  height: 68px;
  text-align: center;
  position: absolute;
  text-align: center;
}

.feature-box-small-icon i.hover {
  color: #333;
}

.feature-box-small-icon .border {
  height: 2px;
  width: 30px;
  background: #ccc;
  display: block;
  margin-top: 20px;
  margin-left: 85px;
}

.feature-box-small-icon .btn {
  margin-top: 10px;
}

.feature-box-small-icon.center i {
  position: inherit;
  float: none;
  display: inline-block;
  margin-bottom: 20px;
  border: none;
  font-size: 60px;
  background: none;
  padding: 0px;
}

.feature-box-small-icon.center .fs1 {
  position: inherit;
  float: none;
  display: inline-block;
  margin-bottom: 20px;
  border: none;
  font-size: 60px;
  background: none;
  padding: 0px;
}

.feature-box-small-icon i.dark {
  background-color: #1a1c26;
}

.feature-box-small-icon h4 {
  line-height: normal;
  margin-bottom: 5px;
}

.feature-box-small-icon.no-bg h2 {
  padding-top: 12px;
}

.feature-box-small-icon .number {
  font-size: 40px;
  text-align: center;
  position: absolute;
  width: 70px;
  margin-top: -5px;
}

.feature-box-small-icon .number.circle {
  border-bottom: solid 2px rgba(255, 255, 255, 0.3);
  padding: 20px;
  width: 70px;
  height: 70px;
}

.feature-box-small-icon.style-2 .number {
  font-size: 32px;
  color: #fff;
  padding-top: 22px;
  background: #253545;
  width: 70px;
  height: 70px;
}

.feature-box-small-icon.no-bg .inner,
.feature-box-small-icon.no-bg .inner:hover {
  padding: 0;
  background: none;
}

.f-hover {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

/* feature-box style 2 */

.feature-box-small-icon-2 {
  margin-bottom: 30px;
}

.feature-box-small-icon-2 .text {
  padding-left: 48px;
}

.feature-box-small-icon-2 i {
  text-shadow: none;
  color: #eceff3;
  font-size: 24px;
  text-align: center;
  position: absolute;
}

.feature-box-small-icon-2 i.dark {
  background-color: #1a1c26;
}

.feature-box-small-icon-2 h4 {
  line-height: normal;
  margin-bottom: 5px;
}

.feature-box-small-icon-2.no-bg h2 {
  padding-top: 12px;
}

.feature-box-small-icon-2.no-bg .inner,
.feature-box-small-icon-2.no-bg .inner:hover {
  padding: 0;
  background: none;
}

/* feature-box image style 3 */

.feature-box-image-2 {
  margin-bottom: 30px;
}

.feature-box-image-2 .text {
  padding-left: 100px;
}

.feature-box-image-2 img {
  position: absolute;
}

.feature-box-image-2 i {
  background-color: #1a1c26;
}

.feature-box-image-2 h4 {
  line-height: normal;
  margin-bottom: 5px;
}

.feature-box-image-2.no-bg h2 {
  padding-top: 12px;
}

.feature-box-image-2.no-bg {
}

.feature-box-image-2.no-bg .inner,
.feature-box-image-2.no-bg .inner:hover {
  padding: 0;
  background: none;
}

/* with image */

.feature-box-image img {
  width: 100%;
  margin-bottom: 10px;
  height: auto;
}

.feature-box-image h4 {
  line-height: normal;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
}

/* feature-box image style 4 */

.feature-box-big-icon {
  text-align: center;
}

.feature-box-big-icon .text {
}

.feature-box-big-icon i {
  float: none;
  display: inline-block;
  text-shadow: none;
  color: #eceff3;
  font-size: 40px;
  padding: 40px;
  width: 120x;
  height: 120x;
  text-align: center;
  border: none;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -web-kit-border-radius: 10px;
  background: #333;
  margin-bottom: 40px;
  cursor: default;
}

.feature-box-big-icon i:after {
  content: "";
  position: absolute;
  margin: 75px 0 0 -40px;
  border-width: 20px 20px 0 20px;
  /*size of the triangle*/
  border-style: solid;
}

.feature-box-big-icon:hover i,
.feature-box-big-icon:hover i:after {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.feature-box-big-icon:hover i {
  background: #333;
}

.feature-box-big-icon:hover i:after {
  border-color: #333 transparent;
}

.feature-box-type-1 {
  position: relative;
  background: #ffffff;
  border-radius: 7px;
  overflow: hidden;
  -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
}

.feature-box-type-1.s2 {
  border: solid 1px #eeeeee;
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
}

.feature-box-type-1 i {
  text-align: center;
  position: absolute;
  font-size: 36px;
  color: #ffffff;
  padding: 12px;
  border-radius: 0 0 7px 0;
}

.feature-box-type-1.s2 i,
.feature-box-type-1.s2:hover i {
  background: rgba(var(--secondary-color-rgb), 0.1);
  color: #111111;
}

.feature-box-type-1 .text {
  padding: 30px;
  padding-left: 80px;
}

.feature-box-type-2 {
  padding: 50px;
  padding-bottom: 30px;
  border-radius: 3px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 2px 10px 30px 0px rgba(10, 10, 10, 0.1);
  -moz-box-shadow: 2px 10px 30px 0px rgba(10, 10, 10, 0.1);
  box-shadow: 2px 10px 30px 0px rgba(10, 10, 10, 0.1);
}

.feature-box-type-2 i {
  font-size: 60px;
  margin-bottom: 20px;
}

.feature-box-type-2:hover i {
  text-shadow: 0px 0px 0px #ffffff;
}

.feature-box-type-2 .wm {
  font-size: 200px;
  top: 50%;
  right: -50%;
  opacity: 0.1;
}

.feature-box-type-2 i {
  /*text-shadow: 5px 5px 5px rgba(var(--primary-color-rgb), 0.3);*/
}

.f-box i {
  font-size: 36px;
}

.f-box.f-icon-rounded i {
  display: block;
  text-align: center;
  padding: 22px;
  width: 80px;
  height: 80px;
  border-radius: 3px;
}

.f-box.f-border i {
  border: solid 10px rgba(255, 255, 255, 0.1);
  padding: 12px;
}

.f-box.f-icon-rounded:hover i {
  border-radius: 3px;
  -webkit-box-shadow: 5px 5px 30px 0px rgba(20, 20, 20, 0.2);
  -moz-box-shadow: 5px 5px 30px 0px rgba(20, 20, 20, 0.2);
  box-shadow: 5px 5px 30px 0px rgba(20, 20, 20, 0.2);
}

.f-box.f-icon-shadow i {
  -webkit-box-shadow: 5px 5px 10px 0px rgba(var(--primary-color-rgb), 0.3);
  -moz-box-shadow: 5px 5px 10px 0px rgba(var(--primary-color-rgb), 0.3);
  box-shadow: 5px 5px 10px 0px rgba(var(--primary-color-rgb), 0.3);
}

.f-box.f-icon-circle i {
  display: block;
  text-align: center;
  padding: 22px 0;
  padding-top: 12px;
  min-width: 80px;
  height: 80px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
}

.f-box.f-icon-left {
  display: flex;
}

.f-box.f-icon-left i {
  margin-right: 30px;
}

.f-box.f-box-s1 {
  padding: 40px;
  padding-left: 0;
  margin-left: 20px;
  background: rgba(var(--primary-color-rgb), 0.1);
}

.f-box.f-box-s2 {
  background: rgba(255, 255, 255, 0.1);
}

.f-box.f-box-s1 i {
  margin-left: -20px;
}

.f-box.f-box-s1 p {
  margin: 0;
}

.rtl .f-box.f-icon-left i {
  margin-right: -60px;
  margin-left: 30px;
}

.f-box.f-icon-left .fb-text {
}

.d-gallery-item {
  overflow: hidden;
  position: relative;
  color: #ffffff;
}

.d-gallery-item img {
  width: 100%;
  height: 100%;
}

.d-gallery-item .dgi-1 {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.d-gallery-item .dgi-2 {
  display: table;
  width: 100%;
  height: 100%;
  position: absolute;
}

.d-gallery-item .dgi-3 {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.d-gallery-item:hover .dgi-1 {
  opacity: 0.8;
}

.d-gallery-item:hover img {
  transform: scale(1.2);
}

.exp-box {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.exp-box h5 {
  border: solid 2px rgba(255, 255, 255, 0.5);
  display: inline-block;
  padding: 6px 10px 5px 10px;
  margin-bottom: 20px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.box-fx .inner {
  position: relative;
  overflow: hidden;
  border-bottom: solid 2px;
}

.box-fx .inner {
  border-bottom: solid 2px;
}

.box-fx .front {
  padding: 60px 0 60px 0;
}

.box-fx .front span {
  font-size: 14px;
}

.box-fx .info {
  position: absolute;
  padding: 60px 30px 60px 30px;
  background: #fff;
  color: #fff;
}
.main__title ol li::before {
  counter-increment: li;
  content: counters(li, ".") ". ";
}
.main__title ol li {
  font-size: 16px;
  line-height: 24px;
  color: #bdbdbd;
  position: relative;
}
.main__title ol h4 {
  font-size: 16px;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.box-fx i {
  font-size: 72px;
  margin-bottom: 20px;
}

.box-fx .btn-line,
.box-fx .btn-line a.btn-line {
  border: solid 1px rgba(255, 255, 255, 0.5);
  color: #fff;
}

.box-fx .btn-line:hover,
.box-fx a.btn-line:hover {
  background: #fff;
  color: #111;
  border-color: #fff;
}

.box-fx .btn-line:after,
.box-fx a.btn-line:after {
  color: #fff;
}

.box-fx .btn-line:hover:after,
.box-fx a.btn-line:hover:after,
.box-fx a.btn-line.hover:after {
  color: #111;
}

.box-fx .bg-icon {
  left: 50%;
  bottom: 50px;
  color: #f6f6f6;
  position: absolute;
  font-size: 190px;
  z-index: -1;
}

.box-icon-simple i {
  color: #222;
}

.box-icon-simple.right {
  margin-right: 70px;
  text-align: right;
}

.box-icon-simple.right i {
  font-size: 42px;
  right: 0;
  position: absolute;
}

.box-icon-simple .num {
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: #333333;
  width: 48px;
  height: 48px;
  padding: 12px 12px;
  position: absolute;
  text-align: center;
  font-weight: bold;
  border-radius: 30px;
}
.teamSocialLinks {
  list-style: none;
  display: inline-block;
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
}
.teamSocialLinks li {
  font-size: 20px;
  display: inline-block;
  margin: 0 5px;
}
.backedCard {
  display: flex;
  align-content: center;
  justify-content: center;
}
.backedCard div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.backedCard img {
  filter: invert(1);
}
.aboutBg {
  background: url(../images/background/6.png) no-repeat scroll center center !important;
}
.teamSocialLinks li a:hover {
  color: #ffffff;
}
.ourTeam .nft_coll_info {
  padding-top: 15px;
}
.box-icon-simple.right .num {
  right: 10px;
}

.box-icon-simple.left .text {
  margin-left: 70px;
}

.box-icon-simple.left i {
  font-size: 42px;
  position: absolute;
}

.box-number .number {
  display: block;
  font-size: 48px;
  color: #222;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 25px;
  border-radius: 60px;
  position: absolute;
}

.box-number .text {
  margin-left: 100px;
}

.box-border {
  border: solid 2px #333;
}

/* list */

.activity-list,
.activity-list li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.activity-list li {
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
  padding-right: 100px;
}
.itemDetailsList li {
  padding-right: 20px;
}
.form-border .dropdown a {
  margin-bottom: 20px;
}
.form-border .dropdown ul {
  top: 45px;
}
.activity-list.itemDetailsList {
  margin: 15px 0;
}
.activity-list {
  background-color: #180e51;
  border: 1px solid #302089;
  padding: 25px;
  border-radius: 10px;
}
.tab-content.de_tab_content.detailPageTab {
  padding-top: 0;
}
.activity-list li:after {
  font-family: "FontAwesome";
  margin-right: 15px;
  float: right;
  position: absolute;
  right: 20px;
  top: 5px;
  font-size: 26px;
  background: #fff;
  height: auto;
  width: 48px;
  padding: 0 !important;
  text-align: center;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.activity-list li.act_follow:after {
  content: "\f00c";
}

.activity-list li.act_like:after {
  content: "\f004";
}

.activity-list li.act_sale:after {
  content: "\f291";
}

.activity-list li.act_offer:after {
  content: "\f0e3";
}
.activity-list li.act_burn:after {
  content: "\f06d";
}
.activity-list li.act_list:after {
  content: "\f03a";
}
.activity-filter {
  border-bottom: 0 !important;
}
.activity-filter,
.activity-filter a {
  margin: 0;
  padding: 0;
  list-style: none;
}

.activity-filter a {
  background-color: #180e51;
  border: 1px solid #302089;
  display: inline-block;
  padding: 8px 10px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  width: calc(50% - 5px);
  font-weight: bold;
  cursor: pointer;
  font-family: var(--title-font);
  color: #ffffff;
}
.activity-filter a:last-child {
  margin-bottom: 0;
}
.dark-scheme .activity-filter a {
  border-color: rgba(255, 255, 255, 0.2);
}

.activity-filter a:nth-child(even) {
  margin-right: 0;
}

.activity-filter i {
  height: 32px;
  width: 32px;
  padding: 9px 0;
  text-align: center;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  background: #eee;
  margin-right: 10px;
}

.activity-filter a.active {
  color: #fff;
}

.activity-filter a.active i {
  background: #ffffff;
}

.activity-list img {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.activity-list .act_like img,
.activity-list .act_sale img,
.activity-list .act_offer img {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.act_list_text {
  padding-left: 100px;
}

.dark-scheme .act_list_text a {
  color: #ffffff;
}

.act_list_text h4 {
  margin-bottom: 5px;
  font-size: 16px;
}

.act_list_date {
  display: block;
}

.ul-style-2 {
  padding: 0;
  list-style: none;
}

.ul-style-2 li {
  margin: 5px 0 5px 0;
}

.ul-style-2 li:before {
  font-family: "FontAwesome";
  content: "\f00c";
  margin-right: 15px;
}

/* pagination */

/* ======================================== */

.pagination {
  margin: 0 auto;
  font-weight: 500;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.pagination li a {
  font-size: 14px;
  color: #888;
  border: solid 1px #dddddd;
  border-right: none;
  background: none;
  padding: 15px 20px 15px 20px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;

  background: #ffffff;
}

.dark-scheme .pagination li a {
  border-color: rgba(255, 255, 255, 0.1);
  background: #21273e;
}

.pagination li:last-child a {
  border-right: solid 1px #dddddd;
}

.dark-scheme .pagination li:last-child a {
  border-right-color: rgba(255, 255, 255, 0.1);
}

.pagination > .active > a {
  color: #ffffff;
}

/* pricing table */

/* ================================================== */

.pricing-box {
  color: #888;
}

.pricing-2-col {
  border-right: solid 1px #eee;
}

.pricing-2-col .pricing-box {
  width: 49%;
}

.pricing-3-col {
}

.pricing-3-col .pricing-box {
  width: 32.33%;
}

.pricing-4-col {
}

.pricing-4-col .pricing-box {
  width: 24%;
}

.pricing-5-col {
}

.pricing-5-col .pricing-box {
  width: 19%;
}

.pricing-2-col,
.pricing-3-col,
.pricing-4-col,
.pricing-5-col {
}

.pricing-box {
  float: left;
  text-align: center;
  margin: 5px;
  padding: 0;
  opacity: 0.8;
}

.pricing-box ul {
  margin: 0;
  padding: 0;
}

.pricing-box li {
  list-style: none;
  margin: 0;
  padding: 15px 0 15px 0;
  background: #eee;
}

.pricing-box li h4 {
  font-size: 22px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #888;
}

.pricing-box li h1 {
  font-size: 48px;
  margin: 0;
  margin-bottom: 5px;
  color: #1a8b49;
  letter-spacing: 0px;
}

.pricing-box li.title-row {
  padding: 15px 0 15px 0;
}

.pricing-box li.title-row h4 {
}

.pricing-box li.price-row {
  padding: 15px 0 15px 0;
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.pricing-box li.deco {
  background: #f6f6f6;
}

.pricing-box a.btn {
  color: #eceff3;
}

.pricing-box li.price-row span {
  display: block;
  font-weight: 600;
}

.pricing-box li.btn-row {
  background: #ddd;
}

.pricing.pricing-box {
}

.pricing.pricing-box ul {
  margin: 0;
  padding: 0;
}

.pricing.pricing-box li {
  background: #3b3b3b;
  color: #bbb;
  list-style: none;
  margin: 0;
  padding: 15px 0 15px 0;
}

.pricing.pricing-box li.deco {
  background: #333;
}

.pricing.pricing-box li h4 {
  font-size: 22px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #eceff3;
}

.pricing.pricing-box li h1 {
  font-size: 48px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
  color: #eceff3;
}

.pricing.pricing-box li.title-row {
  background: #323232;
  padding: 15px 0 15px 0;
}

.pricing.pricing-box li.price-row {
  background: #2b2b2b;
  padding: 15px 0 15px 0;
}

.pricing.pricing-box li.price-row h1 {
  font-weight: 400;
}

.pricing.pricing-box li.price-row h1 span {
  display: inline-block;
  font-weight: 600;
}

.pricing.pricing-box li.price-row span {
  display: block;
  font-weight: 600;
}

.pricing.pricing-box li.btn-row {
  background: #202220;
}

.pricing-featured {
  position: relative;
}

.pricing-featured li {
  padding-top: 17px;
  padding-bottom: 17px;
}

.pricing-s1 {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
}

.dark-scheme .pricing-s1 {
  background: #21273e;
}

.text-light .pricing-s1 {
  background: rgba(0, 0, 0, 0.1);
}

.pricing-s1 .top {
  padding: 30px;
  text-align: center;
}

.pricing-s1 .top h2 {
  font-size: 32px;
  letter-spacing: normal;
  line-height: 1em;
  margin-bottom: 0;
}

.dark-scheme .pricing-s1 .top h2 {
  color: #ffffff;
}

.pricing-s1 .top p.plan-tagline {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.top .num {
  font-size: 40px;
  font-weight: 500;
}

.opt-2 {
  display: none;
}

.pricing-s1 .top > * {
  margin: 0px;
}

.pricing-s1 .top .price {
  margin-top: 40px;
  margin-bottom: 30px;
}

.pricing-s1 .top .price .currency {
  font-size: 24px;
  vertical-align: top;
  display: inline-block;
}

.pricing-s1 .top .price b {
  color: #fff;
  font-family: var(--body-font);
  font-weight: 500;
}

.pricing-s1 .top .price .month {
  color: #fff;
  font-size: 14px;
}

.pricing-s1 .top .price .txt {
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
}

.pricing-s1 .m,
.pricing-s1 .y {
  font-size: 48px;
  font-weight: 500;
}

.pricing-s1 .top .y {
  display: none;
}

.pricing-s1 .mid {
  padding: 40px;
  padding-bottom: 10px;
  text-align: center;
}

.pricing-s1 .bottom {
  border-top: 0px;
  text-align: center;
}

.pricing-s1 .bottom ul {
  display: block;
  list-style: none;
  list-style-type: none;
  margin: 30px 0;
  padding: 0px;
  text-align: left;
  overflow: hidden;
}

.rtl .pricing-s1 .bottom ul {
  text-align: right;
}

.pricing-s1 .bottom ul li {
  padding: 10px 30px;
  color: #505050;
}

.dark-scheme .pricing-s1 .bottom ul li {
  color: #ffffff;
}

.text-light .pricing-s1 .bottom ul li {
  color: #ffffff;
}

.pricing-s1 .bottom ul li > span {
  color: #16a085;
  font-size: 20px;
  margin-right: 20px;
}

.pricing-s1.zebra .bottom ul li:nth-child(odd) {
  background: #ffffff;
}

.pricing-s1.zebra .bottom ul li:nth-child(even) {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.pricing-s1 .bottom > a {
  margin: 40px;
  width: auto;
}

.pricing-s1 .bottom i {
  margin-right: 10px;
}

.rtl .pricing-s1 .bottom i {
  margin-right: 0px;
  margin-left: 10px;
}

.pricing-s1 .ribbon {
  width: 200px;
  position: absolute;
  top: 25px;
  left: -55px;
  text-align: center;
  line-height: 40px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: bold;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}

.pricing-s1.light {
  color: #222;
}

.pricing-s1.light .top {
  background: rgba(var(--secondary-color-rgb), 0.1);
  color: #222;
}

.pricing-s1.light .top h2 {
  color: #222;
}

.pricing-s1.light .top .price b,
.pricing-s1.light .top .price .txt {
  color: #222;
}

.pricing-s1.light .top .price .txt {
  color: #555;
}

.pricing-s1.light .top .price .month {
  color: #555;
}

.pricing-s1.light .top .price .currency {
  color: #555;
}

.pricing-s1.light .bottom {
  background: #eee;
}

.pricing-s1.light .bottom ul li > span {
  color: #16a085;
}

.pricing-s1.light .bottom ul li:nth-child(odd) {
  background: #ffffff;
}

.pricing-s1.light .bottom ul li:nth-child(even) {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.pricing-s1 .ribbon {
  color: #fff;
}

.pricing-s1.rec {
  -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.2);
}

.pricing-s1 .action {
  text-align: center;
  padding: 40px 0;
  border-top: solid 1px #eeeeee;
}

.dark-scheme .pricing-s1 .action {
  border-top: solid 1px rgba(255, 255, 255, 0.2);
}

.text-light .pricing-s1 .action {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}

.pricing-s2 {
  background: #ffffff;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
}

.pricing-s2 .top {
  padding: 30px;
  padding-bottom: 20px;
  text-align: center;
}

.pricing-s2 .top h2 {
  font-size: 22px;
  margin-bottom: 5px;
  letter-spacing: normal;
}

.pricing-s2 .top p {
  margin-bottom: 30px;
}

.pricing-s2 .top > * {
  margin: 0px;
}

.pricing-s2 .top .price {
  margin-bottom: 30px;
  color: #111111;
}

.pricing-s2.text-light .top .price,
.pricing-s2.text-white .top .price {
  color: #ffffff;
}

.pricing-s2 .top .price .currency {
  font-size: 24px;
  vertical-align: top;
  display: inline-block;
}

.pricing-s2 .top .price b {
  font-size: 40px;
  font-family: var(--body-font);
  font-weight: 500;
}

.pricing-s2 .top .price .month {
  font-size: 14px;
}

.pricing-s2 .top .price .txt {
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
}

.pricing-s2 .top a.btn-main {
  display: block;
  width: 100%;
  padding: 6px 10px;
}

.pricing-s2 .bottom {
  border-top: 0px;
  text-align: center;
}

.pricing-s2 .bottom ul {
  display: block;
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0px;
  text-align: left;
  overflow: hidden;
}

.pricing-s2 .bottom ul li {
  padding: 15px 30px;
  color: #505050;
}

.pricing-s2 .bottom ul li > span {
  color: #16a085;
  font-size: 20px;
  margin-right: 20px;
}

.pricing-s2 .bottom ul li:nth-child(odd) {
  background: #ffffff;
}

.pricing-s2 .bottom ul li:nth-child(even) {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.pricing-s2 .bottom > a {
  margin: 40px;
  width: auto;
}

.pricing-s2 .bottom i {
  margin-right: 10px;
}

.pricing-s2 .ribbon {
  width: 200px;
  position: absolute;
  top: 25px;
  left: -55px;
  text-align: center;
  line-height: 40px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: bold;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}

.pricing-s2 .inner {
  padding-bottom: 10px;
}

.pricing-s2.bg .inner {
  background: rgba(var(--primary-color-rgb), 0.8);
}

.pricing-s2.bg-2 .inner {
  background: rgba(255, 88, 55, 0.8);
}

.pricing-s2.bg .top p {
  color: #cccccc;
}

.pricing-s2.bg .top h2,
.pricing-s2.bg .top .price,
.pricing-s2.bg .top .price .currency,
.pricing-s2.bg .top .month,
.pricing-s2.bg-2 .top h2,
.pricing-s2.bg-2 .top .price,
.pricing-s2.bg-2 .top .price .currency,
.pricing-s2.bg-2 .top .month {
  color: #ffffff;
}

.spinner {
  display: table-cell;
  vertical-align: middle;
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* preloader */

/* ================================================== */

#preloader {
  display: table;
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  text-align: center;
}

.preloader1 {
  width: 60px;
  height: 60px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 2px solid;
  border-top-color: rgba(0, 0, 0, 0.65);
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-left-color: rgba(0, 0, 0, 0.1);
  border-right-color: rgba(0, 0, 0, 0.1);
  -webkit-animation: preloader1 1s ease-in infinite;
  animation: preloader1 1s ease-in infinite;
  position: absolute;
  margin-left: -30px;
  top: 45%;
}

@keyframes preloader1 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes preloader1 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.snow {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #1d3b72;
  border-radius: 50%;
}
.snow:nth-child(1) {
  opacity: 0.741;
  transform: translate(76.9488vw, -10px) scale(0.7256);
  animation: fall-1 16s -7s linear infinite;
}
@keyframes fall-1 {
  72.557% {
    transform: translate(67.5666vw, 72.557vh) scale(0.7256);
  }
  to {
    transform: translate(72.2577vw, 100vh) scale(0.7256);
  }
}
.snow:nth-child(2) {
  opacity: 0.1891;
  transform: translate(84.5518vw, -10px) scale(0.3614);
  animation: fall-2 16s -23s linear infinite;
}
@keyframes fall-2 {
  43.151% {
    transform: translate(81.9314vw, 43.151vh) scale(0.3614);
  }
  to {
    transform: translate(83.2416vw, 100vh) scale(0.3614);
  }
}
.snow:nth-child(3) {
  opacity: 0.7521;
  transform: translate(49.2101vw, -10px) scale(0.7907);
  animation: fall-3 21s -6s linear infinite;
}
@keyframes fall-3 {
  70.478% {
    transform: translate(47.8895vw, 70.478vh) scale(0.7907);
  }
  to {
    transform: translate(48.5498vw, 100vh) scale(0.7907);
  }
}
.snow:nth-child(4) {
  opacity: 0.8617;
  transform: translate(25.6829vw, -10px) scale(0.4269);
  animation: fall-4 19s -26s linear infinite;
}
@keyframes fall-4 {
  56.703% {
    transform: translate(30.6969vw, 56.703vh) scale(0.4269);
  }
  to {
    transform: translate(28.1899vw, 100vh) scale(0.4269);
  }
}
.snow:nth-child(5) {
  opacity: 0.2937;
  transform: translate(69.1551vw, -10px) scale(0.5757);
  animation: fall-5 23s -17s linear infinite;
}
@keyframes fall-5 {
  76.773% {
    transform: translate(69.2145vw, 76.773vh) scale(0.5757);
  }
  to {
    transform: translate(69.1848vw, 100vh) scale(0.5757);
  }
}
.snow:nth-child(6) {
  opacity: 0.8856;
  transform: translate(35.9753vw, -10px) scale(0.9497);
  animation: fall-6 10s -2s linear infinite;
}
@keyframes fall-6 {
  65.116% {
    transform: translate(44.4012vw, 65.116vh) scale(0.9497);
  }
  to {
    transform: translate(40.18825vw, 100vh) scale(0.9497);
  }
}
.snow:nth-child(7) {
  opacity: 0.9024;
  transform: translate(48.4872vw, -10px) scale(0.6383);
  animation: fall-7 20s -20s linear infinite;
}
@keyframes fall-7 {
  56.077% {
    transform: translate(45.5751vw, 56.077vh) scale(0.6383);
  }
  to {
    transform: translate(47.03115vw, 100vh) scale(0.6383);
  }
}
.snow:nth-child(8) {
  opacity: 0.5197;
  transform: translate(6.8896vw, -10px) scale(0.6816);
  animation: fall-8 16s -3s linear infinite;
}
@keyframes fall-8 {
  35.881% {
    transform: translate(13.1868vw, 35.881vh) scale(0.6816);
  }
  to {
    transform: translate(10.0382vw, 100vh) scale(0.6816);
  }
}
.snow:nth-child(9) {
  opacity: 0.295;
  transform: translate(34.5962vw, -10px) scale(0.2022);
  animation: fall-9 10s -24s linear infinite;
}
@keyframes fall-9 {
  42.65% {
    transform: translate(37.323vw, 42.65vh) scale(0.2022);
  }
  to {
    transform: translate(35.9596vw, 100vh) scale(0.2022);
  }
}
.snow:nth-child(10) {
  opacity: 0.5746;
  transform: translate(61.522vw, -10px) scale(0.3017);
  animation: fall-10 22s -11s linear infinite;
}
@keyframes fall-10 {
  66.156% {
    transform: translate(60.023vw, 66.156vh) scale(0.3017);
  }
  to {
    transform: translate(60.7725vw, 100vh) scale(0.3017);
  }
}
.snow:nth-child(11) {
  opacity: 0.7137;
  transform: translate(87.4012vw, -10px) scale(0.0264);
  animation: fall-11 24s -8s linear infinite;
}
@keyframes fall-11 {
  62.887% {
    transform: translate(87.9124vw, 62.887vh) scale(0.0264);
  }
  to {
    transform: translate(87.6568vw, 100vh) scale(0.0264);
  }
}
.snow:nth-child(12) {
  opacity: 0.1207;
  transform: translate(45.8403vw, -10px) scale(0.88);
  animation: fall-12 14s -21s linear infinite;
}
@keyframes fall-12 {
  78.743% {
    transform: translate(48.3249vw, 78.743vh) scale(0.88);
  }
  to {
    transform: translate(47.0826vw, 100vh) scale(0.88);
  }
}
.snow:nth-child(13) {
  opacity: 0.0425;
  transform: translate(25.323vw, -10px) scale(0.3585);
  animation: fall-13 26s -7s linear infinite;
}
@keyframes fall-13 {
  60.709% {
    transform: translate(21.5935vw, 60.709vh) scale(0.3585);
  }
  to {
    transform: translate(23.45825vw, 100vh) scale(0.3585);
  }
}
.snow:nth-child(14) {
  opacity: 0.1267;
  transform: translate(17.2162vw, -10px) scale(0.1957);
  animation: fall-14 30s -8s linear infinite;
}
@keyframes fall-14 {
  73.867% {
    transform: translate(14.4463vw, 73.867vh) scale(0.1957);
  }
  to {
    transform: translate(15.83125vw, 100vh) scale(0.1957);
  }
}
.snow:nth-child(15) {
  opacity: 0.6459;
  transform: translate(27.1272vw, -10px) scale(0.891);
  animation: fall-15 27s -3s linear infinite;
}
@keyframes fall-15 {
  71.317% {
    transform: translate(35.1013vw, 71.317vh) scale(0.891);
  }
  to {
    transform: translate(31.11425vw, 100vh) scale(0.891);
  }
}
.snow:nth-child(16) {
  opacity: 0.0266;
  transform: translate(78.0654vw, -10px) scale(0.0829);
  animation: fall-16 23s -10s linear infinite;
}
@keyframes fall-16 {
  71.091% {
    transform: translate(76.7818vw, 71.091vh) scale(0.0829);
  }
  to {
    transform: translate(77.4236vw, 100vh) scale(0.0829);
  }
}
.snow:nth-child(17) {
  opacity: 0.6902;
  transform: translate(21.1576vw, -10px) scale(0.5071);
  animation: fall-17 15s -21s linear infinite;
}
@keyframes fall-17 {
  40.428% {
    transform: translate(29.5813vw, 40.428vh) scale(0.5071);
  }
  to {
    transform: translate(25.36945vw, 100vh) scale(0.5071);
  }
}
.snow:nth-child(18) {
  opacity: 0.6327;
  transform: translate(57.9729vw, -10px) scale(0.0152);
  animation: fall-18 11s -24s linear infinite;
}
@keyframes fall-18 {
  45.417% {
    transform: translate(55.6905vw, 45.417vh) scale(0.0152);
  }
  to {
    transform: translate(56.8317vw, 100vh) scale(0.0152);
  }
}
.snow:nth-child(19) {
  opacity: 0.4217;
  transform: translate(15.7589vw, -10px) scale(0.7177);
  animation: fall-19 23s -16s linear infinite;
}
@keyframes fall-19 {
  33.396% {
    transform: translate(8.2839vw, 33.396vh) scale(0.7177);
  }
  to {
    transform: translate(12.0214vw, 100vh) scale(0.7177);
  }
}
.snow:nth-child(20) {
  opacity: 0.3043;
  transform: translate(20.0102vw, -10px) scale(0.4394);
  animation: fall-20 23s -5s linear infinite;
}
@keyframes fall-20 {
  50.699% {
    transform: translate(16.9816vw, 50.699vh) scale(0.4394);
  }
  to {
    transform: translate(18.4959vw, 100vh) scale(0.4394);
  }
}
.snow:nth-child(21) {
  opacity: 0.3883;
  transform: translate(13.7096vw, -10px) scale(0.4498);
  animation: fall-21 10s -13s linear infinite;
}
@keyframes fall-21 {
  42.78% {
    transform: translate(5.0177vw, 42.78vh) scale(0.4498);
  }
  to {
    transform: translate(9.36365vw, 100vh) scale(0.4498);
  }
}
.snow:nth-child(22) {
  opacity: 0.9223;
  transform: translate(76.2525vw, -10px) scale(0.4152);
  animation: fall-22 24s -28s linear infinite;
}
@keyframes fall-22 {
  60.239% {
    transform: translate(76.5717vw, 60.239vh) scale(0.4152);
  }
  to {
    transform: translate(76.4121vw, 100vh) scale(0.4152);
  }
}
.snow:nth-child(23) {
  opacity: 0.5711;
  transform: translate(0.947vw, -10px) scale(0.429);
  animation: fall-23 17s -13s linear infinite;
}
@keyframes fall-23 {
  44.731% {
    transform: translate(7.9572vw, 44.731vh) scale(0.429);
  }
  to {
    transform: translate(4.4521vw, 100vh) scale(0.429);
  }
}
.snow:nth-child(24) {
  opacity: 0.0637;
  transform: translate(51.7883vw, -10px) scale(0.8026);
  animation: fall-24 26s -13s linear infinite;
}
@keyframes fall-24 {
  34.744% {
    transform: translate(53.1314vw, 34.744vh) scale(0.8026);
  }
  to {
    transform: translate(52.45985vw, 100vh) scale(0.8026);
  }
}
.snow:nth-child(25) {
  opacity: 0.0693;
  transform: translate(30.2383vw, -10px) scale(0.865);
  animation: fall-25 13s -30s linear infinite;
}
@keyframes fall-25 {
  72.013% {
    transform: translate(29.5868vw, 72.013vh) scale(0.865);
  }
  to {
    transform: translate(29.91255vw, 100vh) scale(0.865);
  }
}
.snow:nth-child(26) {
  opacity: 0.7128;
  transform: translate(13.4961vw, -10px) scale(0.7117);
  animation: fall-26 19s -13s linear infinite;
}
@keyframes fall-26 {
  63.284% {
    transform: translate(7.6736vw, 63.284vh) scale(0.7117);
  }
  to {
    transform: translate(10.58485vw, 100vh) scale(0.7117);
  }
}
.snow:nth-child(27) {
  opacity: 0.508;
  transform: translate(18.2244vw, -10px) scale(0.5935);
  animation: fall-27 10s -18s linear infinite;
}
@keyframes fall-27 {
  33.346% {
    transform: translate(20.4215vw, 33.346vh) scale(0.5935);
  }
  to {
    transform: translate(19.32295vw, 100vh) scale(0.5935);
  }
}
.snow:nth-child(28) {
  opacity: 0.8414;
  transform: translate(49.4213vw, -10px) scale(0.8921);
  animation: fall-28 26s -24s linear infinite;
}
@keyframes fall-28 {
  58.664% {
    transform: translate(40.1709vw, 58.664vh) scale(0.8921);
  }
  to {
    transform: translate(44.7961vw, 100vh) scale(0.8921);
  }
}
.snow:nth-child(29) {
  opacity: 0.1276;
  transform: translate(72.8033vw, -10px) scale(0.2063);
  animation: fall-29 26s -2s linear infinite;
}
@keyframes fall-29 {
  56.738% {
    transform: translate(79.4119vw, 56.738vh) scale(0.2063);
  }
  to {
    transform: translate(76.1076vw, 100vh) scale(0.2063);
  }
}
.snow:nth-child(30) {
  opacity: 0.0438;
  transform: translate(6.6188vw, -10px) scale(0.8603);
  animation: fall-30 17s -9s linear infinite;
}
@keyframes fall-30 {
  55.208% {
    transform: translate(14.2229vw, 55.208vh) scale(0.8603);
  }
  to {
    transform: translate(10.42085vw, 100vh) scale(0.8603);
  }
}
.snow:nth-child(31) {
  opacity: 0.1731;
  transform: translate(92.6163vw, -10px) scale(0.8235);
  animation: fall-31 14s -13s linear infinite;
}
@keyframes fall-31 {
  76.248% {
    transform: translate(85.5446vw, 76.248vh) scale(0.8235);
  }
  to {
    transform: translate(89.08045vw, 100vh) scale(0.8235);
  }
}
.snow:nth-child(32) {
  opacity: 0.8125;
  transform: translate(97.506vw, -10px) scale(0.6234);
  animation: fall-32 11s -26s linear infinite;
}
@keyframes fall-32 {
  62.017% {
    transform: translate(100.0091vw, 62.017vh) scale(0.6234);
  }
  to {
    transform: translate(98.75755vw, 100vh) scale(0.6234);
  }
}
.snow:nth-child(33) {
  opacity: 0.304;
  transform: translate(37.3089vw, -10px) scale(0.8905);
  animation: fall-33 29s -27s linear infinite;
}
@keyframes fall-33 {
  59.165% {
    transform: translate(36.3239vw, 59.165vh) scale(0.8905);
  }
  to {
    transform: translate(36.8164vw, 100vh) scale(0.8905);
  }
}
.snow:nth-child(34) {
  opacity: 0.1047;
  transform: translate(33.9571vw, -10px) scale(0.6592);
  animation: fall-34 18s -12s linear infinite;
}
@keyframes fall-34 {
  75.687% {
    transform: translate(33.8929vw, 75.687vh) scale(0.6592);
  }
  to {
    transform: translate(33.925vw, 100vh) scale(0.6592);
  }
}
.snow:nth-child(35) {
  opacity: 0.1957;
  transform: translate(98.8992vw, -10px) scale(0.3625);
  animation: fall-35 26s -3s linear infinite;
}
@keyframes fall-35 {
  65.251% {
    transform: translate(99.7974vw, 65.251vh) scale(0.3625);
  }
  to {
    transform: translate(99.3483vw, 100vh) scale(0.3625);
  }
}
.snow:nth-child(36) {
  opacity: 0.5148;
  transform: translate(86.2766vw, -10px) scale(0.0247);
  animation: fall-36 13s -17s linear infinite;
}
@keyframes fall-36 {
  79.976% {
    transform: translate(84.2257vw, 79.976vh) scale(0.0247);
  }
  to {
    transform: translate(85.25115vw, 100vh) scale(0.0247);
  }
}
.snow:nth-child(37) {
  opacity: 0.4882;
  transform: translate(46.6823vw, -10px) scale(0.646);
  animation: fall-37 21s -22s linear infinite;
}
@keyframes fall-37 {
  69.291% {
    transform: translate(43.5275vw, 69.291vh) scale(0.646);
  }
  to {
    transform: translate(45.1049vw, 100vh) scale(0.646);
  }
}
.snow:nth-child(38) {
  opacity: 0.2738;
  transform: translate(54.8853vw, -10px) scale(0.2549);
  animation: fall-38 18s -19s linear infinite;
}
@keyframes fall-38 {
  33.377% {
    transform: translate(51.9947vw, 33.377vh) scale(0.2549);
  }
  to {
    transform: translate(53.44vw, 100vh) scale(0.2549);
  }
}
.snow:nth-child(39) {
  opacity: 0.5453;
  transform: translate(17.942vw, -10px) scale(0.325);
  animation: fall-39 11s -8s linear infinite;
}
@keyframes fall-39 {
  33.465% {
    transform: translate(25.4052vw, 33.465vh) scale(0.325);
  }
  to {
    transform: translate(21.6736vw, 100vh) scale(0.325);
  }
}
.snow:nth-child(40) {
  opacity: 0.2776;
  transform: translate(32.1042vw, -10px) scale(0.4879);
  animation: fall-40 18s -19s linear infinite;
}
@keyframes fall-40 {
  35.278% {
    transform: translate(39.1021vw, 35.278vh) scale(0.4879);
  }
  to {
    transform: translate(35.60315vw, 100vh) scale(0.4879);
  }
}
.snow:nth-child(41) {
  opacity: 0.94;
  transform: translate(54.7952vw, -10px) scale(0.8427);
  animation: fall-41 14s -22s linear infinite;
}
@keyframes fall-41 {
  38.647% {
    transform: translate(53.7854vw, 38.647vh) scale(0.8427);
  }
  to {
    transform: translate(54.2903vw, 100vh) scale(0.8427);
  }
}
.snow:nth-child(42) {
  opacity: 0.9743;
  transform: translate(71.5619vw, -10px) scale(0.8943);
  animation: fall-42 26s -9s linear infinite;
}
@keyframes fall-42 {
  55.073% {
    transform: translate(75.607vw, 55.073vh) scale(0.8943);
  }
  to {
    transform: translate(73.58445vw, 100vh) scale(0.8943);
  }
}
.snow:nth-child(43) {
  opacity: 0.811;
  transform: translate(96.9891vw, -10px) scale(0.2566);
  animation: fall-43 25s -6s linear infinite;
}
@keyframes fall-43 {
  56.657% {
    transform: translate(98.3973vw, 56.657vh) scale(0.2566);
  }
  to {
    transform: translate(97.6932vw, 100vh) scale(0.2566);
  }
}
.snow:nth-child(44) {
  opacity: 0.6306;
  transform: translate(87.1776vw, -10px) scale(0.6019);
  animation: fall-44 27s -3s linear infinite;
}
@keyframes fall-44 {
  41.557% {
    transform: translate(88.5579vw, 41.557vh) scale(0.6019);
  }
  to {
    transform: translate(87.86775vw, 100vh) scale(0.6019);
  }
}
.snow:nth-child(45) {
  opacity: 0.5526;
  transform: translate(71.4301vw, -10px) scale(0.8696);
  animation: fall-45 23s -4s linear infinite;
}
@keyframes fall-45 {
  42.094% {
    transform: translate(68.3497vw, 42.094vh) scale(0.8696);
  }
  to {
    transform: translate(69.8899vw, 100vh) scale(0.8696);
  }
}
.snow:nth-child(46) {
  opacity: 0.6996;
  transform: translate(96.8578vw, -10px) scale(0.8348);
  animation: fall-46 27s -30s linear infinite;
}
@keyframes fall-46 {
  74.619% {
    transform: translate(90.4568vw, 74.619vh) scale(0.8348);
  }
  to {
    transform: translate(93.6573vw, 100vh) scale(0.8348);
  }
}
.snow:nth-child(47) {
  opacity: 0.0307;
  transform: translate(88.3475vw, -10px) scale(0.7652);
  animation: fall-47 25s -1s linear infinite;
}
@keyframes fall-47 {
  55.715% {
    transform: translate(81.3749vw, 55.715vh) scale(0.7652);
  }
  to {
    transform: translate(84.8612vw, 100vh) scale(0.7652);
  }
}
.snow:nth-child(48) {
  opacity: 0.0581;
  transform: translate(88.107vw, -10px) scale(0.6571);
  animation: fall-48 16s -22s linear infinite;
}
@keyframes fall-48 {
  58.988% {
    transform: translate(92.5945vw, 58.988vh) scale(0.6571);
  }
  to {
    transform: translate(90.35075vw, 100vh) scale(0.6571);
  }
}
.snow:nth-child(49) {
  opacity: 0.0073;
  transform: translate(92.1642vw, -10px) scale(0.5989);
  animation: fall-49 22s -5s linear infinite;
}
@keyframes fall-49 {
  71.867% {
    transform: translate(95.3376vw, 71.867vh) scale(0.5989);
  }
  to {
    transform: translate(93.7509vw, 100vh) scale(0.5989);
  }
}
.snow:nth-child(50) {
  opacity: 0.1636;
  transform: translate(6.2847vw, -10px) scale(0.1065);
  animation: fall-50 13s -18s linear infinite;
}
@keyframes fall-50 {
  35.503% {
    transform: translate(2.9839vw, 35.503vh) scale(0.1065);
  }
  to {
    transform: translate(4.6343vw, 100vh) scale(0.1065);
  }
}
.snow:nth-child(51) {
  opacity: 0.8972;
  transform: translate(9.7325vw, -10px) scale(0.3777);
  animation: fall-51 21s -26s linear infinite;
}
@keyframes fall-51 {
  37.257% {
    transform: translate(9.0411vw, 37.257vh) scale(0.3777);
  }
  to {
    transform: translate(9.3868vw, 100vh) scale(0.3777);
  }
}
.snow:nth-child(52) {
  opacity: 0.9856;
  transform: translate(13.6375vw, -10px) scale(0.3058);
  animation: fall-52 30s -9s linear infinite;
}
@keyframes fall-52 {
  36.996% {
    transform: translate(4.3463vw, 36.996vh) scale(0.3058);
  }
  to {
    transform: translate(8.9919vw, 100vh) scale(0.3058);
  }
}
.snow:nth-child(53) {
  opacity: 0.0192;
  transform: translate(98.2703vw, -10px) scale(0.2661);
  animation: fall-53 16s -23s linear infinite;
}
@keyframes fall-53 {
  63.362% {
    transform: translate(90.8416vw, 63.362vh) scale(0.2661);
  }
  to {
    transform: translate(94.55595vw, 100vh) scale(0.2661);
  }
}
.snow:nth-child(54) {
  opacity: 0.9254;
  transform: translate(28.7366vw, -10px) scale(0.4772);
  animation: fall-54 15s -3s linear infinite;
}
@keyframes fall-54 {
  35.203% {
    transform: translate(27.0675vw, 35.203vh) scale(0.4772);
  }
  to {
    transform: translate(27.90205vw, 100vh) scale(0.4772);
  }
}
.snow:nth-child(55) {
  opacity: 0.8767;
  transform: translate(24.4267vw, -10px) scale(0.4292);
  animation: fall-55 16s -21s linear infinite;
}
@keyframes fall-55 {
  77.343% {
    transform: translate(33.5824vw, 77.343vh) scale(0.4292);
  }
  to {
    transform: translate(29.00455vw, 100vh) scale(0.4292);
  }
}
.snow:nth-child(56) {
  opacity: 0.575;
  transform: translate(29.0424vw, -10px) scale(0.1705);
  animation: fall-56 28s -4s linear infinite;
}
@keyframes fall-56 {
  64.8% {
    transform: translate(25.2245vw, 64.8vh) scale(0.1705);
  }
  to {
    transform: translate(27.13345vw, 100vh) scale(0.1705);
  }
}
.snow:nth-child(57) {
  opacity: 0.0977;
  transform: translate(56.6214vw, -10px) scale(0.9477);
  animation: fall-57 27s -4s linear infinite;
}
@keyframes fall-57 {
  57.94% {
    transform: translate(57.9875vw, 57.94vh) scale(0.9477);
  }
  to {
    transform: translate(57.30445vw, 100vh) scale(0.9477);
  }
}
.snow:nth-child(58) {
  opacity: 0.8201;
  transform: translate(73.6923vw, -10px) scale(0.9013);
  animation: fall-58 14s -16s linear infinite;
}
@keyframes fall-58 {
  38.014% {
    transform: translate(64.3712vw, 38.014vh) scale(0.9013);
  }
  to {
    transform: translate(69.03175vw, 100vh) scale(0.9013);
  }
}
.snow:nth-child(59) {
  opacity: 0.4938;
  transform: translate(92.7646vw, -10px) scale(0.3223);
  animation: fall-59 26s -10s linear infinite;
}
@keyframes fall-59 {
  51.083% {
    transform: translate(86.4526vw, 51.083vh) scale(0.3223);
  }
  to {
    transform: translate(89.6086vw, 100vh) scale(0.3223);
  }
}
.snow:nth-child(60) {
  opacity: 0.4182;
  transform: translate(57.6997vw, -10px) scale(0.4921);
  animation: fall-60 17s -3s linear infinite;
}
@keyframes fall-60 {
  33.44% {
    transform: translate(64.7848vw, 33.44vh) scale(0.4921);
  }
  to {
    transform: translate(61.24225vw, 100vh) scale(0.4921);
  }
}
.snow:nth-child(61) {
  opacity: 0.5847;
  transform: translate(72.3575vw, -10px) scale(0.6646);
  animation: fall-61 25s -12s linear infinite;
}
@keyframes fall-61 {
  79.637% {
    transform: translate(77.6554vw, 79.637vh) scale(0.6646);
  }
  to {
    transform: translate(75.00645vw, 100vh) scale(0.6646);
  }
}
.snow:nth-child(62) {
  opacity: 0.9714;
  transform: translate(87.196vw, -10px) scale(0.1289);
  animation: fall-62 16s -14s linear infinite;
}
@keyframes fall-62 {
  59.674% {
    transform: translate(78.068vw, 59.674vh) scale(0.1289);
  }
  to {
    transform: translate(82.632vw, 100vh) scale(0.1289);
  }
}
.snow:nth-child(63) {
  opacity: 0.7173;
  transform: translate(49.8499vw, -10px) scale(0.1822);
  animation: fall-63 10s -1s linear infinite;
}
@keyframes fall-63 {
  31.773% {
    transform: translate(58.7313vw, 31.773vh) scale(0.1822);
  }
  to {
    transform: translate(54.2906vw, 100vh) scale(0.1822);
  }
}
.snow:nth-child(64) {
  opacity: 0.9032;
  transform: translate(47.5975vw, -10px) scale(0.0275);
  animation: fall-64 21s -23s linear infinite;
}
@keyframes fall-64 {
  51.381% {
    transform: translate(41.2461vw, 51.381vh) scale(0.0275);
  }
  to {
    transform: translate(44.4218vw, 100vh) scale(0.0275);
  }
}
.snow:nth-child(65) {
  opacity: 0.9212;
  transform: translate(52.616vw, -10px) scale(0.5657);
  animation: fall-65 26s -13s linear infinite;
}
@keyframes fall-65 {
  49.095% {
    transform: translate(45.0885vw, 49.095vh) scale(0.5657);
  }
  to {
    transform: translate(48.85225vw, 100vh) scale(0.5657);
  }
}
.snow:nth-child(66) {
  opacity: 0.8019;
  transform: translate(98.5032vw, -10px) scale(0.2556);
  animation: fall-66 19s -7s linear infinite;
}
@keyframes fall-66 {
  34.255% {
    transform: translate(106.9661vw, 34.255vh) scale(0.2556);
  }
  to {
    transform: translate(102.73465vw, 100vh) scale(0.2556);
  }
}
.snow:nth-child(67) {
  opacity: 0.1969;
  transform: translate(11.677vw, -10px) scale(0.0019);
  animation: fall-67 12s -15s linear infinite;
}
@keyframes fall-67 {
  47.369% {
    transform: translate(14.8729vw, 47.369vh) scale(0.0019);
  }
  to {
    transform: translate(13.27495vw, 100vh) scale(0.0019);
  }
}
.snow:nth-child(68) {
  opacity: 0.6737;
  transform: translate(76.5243vw, -10px) scale(0.9035);
  animation: fall-68 18s -23s linear infinite;
}
@keyframes fall-68 {
  56.61% {
    transform: translate(79.4208vw, 56.61vh) scale(0.9035);
  }
  to {
    transform: translate(77.97255vw, 100vh) scale(0.9035);
  }
}
.snow:nth-child(69) {
  opacity: 0.6643;
  transform: translate(21.6145vw, -10px) scale(0.8461);
  animation: fall-69 12s -3s linear infinite;
}
@keyframes fall-69 {
  78.775% {
    transform: translate(31.3554vw, 78.775vh) scale(0.8461);
  }
  to {
    transform: translate(26.48495vw, 100vh) scale(0.8461);
  }
}
.snow:nth-child(70) {
  opacity: 0.9801;
  transform: translate(32.4011vw, -10px) scale(0.8341);
  animation: fall-70 20s -6s linear infinite;
}
@keyframes fall-70 {
  38.154% {
    transform: translate(36.3171vw, 38.154vh) scale(0.8341);
  }
  to {
    transform: translate(34.3591vw, 100vh) scale(0.8341);
  }
}
.snow:nth-child(71) {
  opacity: 0.2633;
  transform: translate(5.4846vw, -10px) scale(0.0274);
  animation: fall-71 14s -28s linear infinite;
}
@keyframes fall-71 {
  69.466% {
    transform: translate(1.9754vw, 69.466vh) scale(0.0274);
  }
  to {
    transform: translate(3.73vw, 100vh) scale(0.0274);
  }
}
.snow:nth-child(72) {
  opacity: 0.1903;
  transform: translate(64.0493vw, -10px) scale(0.2251);
  animation: fall-72 13s -17s linear infinite;
}
@keyframes fall-72 {
  77.481% {
    transform: translate(64.7489vw, 77.481vh) scale(0.2251);
  }
  to {
    transform: translate(64.3991vw, 100vh) scale(0.2251);
  }
}
.snow:nth-child(73) {
  opacity: 0.963;
  transform: translate(99.4776vw, -10px) scale(0.8364);
  animation: fall-73 15s -15s linear infinite;
}
@keyframes fall-73 {
  40.565% {
    transform: translate(103.5396vw, 40.565vh) scale(0.8364);
  }
  to {
    transform: translate(101.5086vw, 100vh) scale(0.8364);
  }
}
.snow:nth-child(74) {
  opacity: 0.6111;
  transform: translate(88.0529vw, -10px) scale(0.8567);
  animation: fall-74 25s -28s linear infinite;
}
@keyframes fall-74 {
  49.354% {
    transform: translate(79.1467vw, 49.354vh) scale(0.8567);
  }
  to {
    transform: translate(83.5998vw, 100vh) scale(0.8567);
  }
}
.snow:nth-child(75) {
  opacity: 0.1317;
  transform: translate(50.4557vw, -10px) scale(0.2358);
  animation: fall-75 15s -10s linear infinite;
}
@keyframes fall-75 {
  37.839% {
    transform: translate(56.6666vw, 37.839vh) scale(0.2358);
  }
  to {
    transform: translate(53.56115vw, 100vh) scale(0.2358);
  }
}
.snow:nth-child(76) {
  opacity: 0.2306;
  transform: translate(63.326vw, -10px) scale(0.9255);
  animation: fall-76 26s -11s linear infinite;
}
@keyframes fall-76 {
  55.409% {
    transform: translate(57.6229vw, 55.409vh) scale(0.9255);
  }
  to {
    transform: translate(60.47445vw, 100vh) scale(0.9255);
  }
}
.snow:nth-child(77) {
  opacity: 0.4734;
  transform: translate(72.4965vw, -10px) scale(0.9599);
  animation: fall-77 26s -29s linear infinite;
}
@keyframes fall-77 {
  62.529% {
    transform: translate(74.9173vw, 62.529vh) scale(0.9599);
  }
  to {
    transform: translate(73.7069vw, 100vh) scale(0.9599);
  }
}
.snow:nth-child(78) {
  opacity: 0.7868;
  transform: translate(92.6778vw, -10px) scale(0.7689);
  animation: fall-78 13s -14s linear infinite;
}
@keyframes fall-78 {
  64.849% {
    transform: translate(100.0615vw, 64.849vh) scale(0.7689);
  }
  to {
    transform: translate(96.36965vw, 100vh) scale(0.7689);
  }
}
.snow:nth-child(79) {
  opacity: 0.077;
  transform: translate(96.778vw, -10px) scale(0.711);
  animation: fall-79 15s -10s linear infinite;
}
@keyframes fall-79 {
  42.082% {
    transform: translate(99.1097vw, 42.082vh) scale(0.711);
  }
  to {
    transform: translate(97.94385vw, 100vh) scale(0.711);
  }
}
.snow:nth-child(80) {
  opacity: 0.9097;
  transform: translate(6.5451vw, -10px) scale(0.0623);
  animation: fall-80 10s -3s linear infinite;
}
@keyframes fall-80 {
  32.866% {
    transform: translate(0.1496vw, 32.866vh) scale(0.0623);
  }
  to {
    transform: translate(3.34735vw, 100vh) scale(0.0623);
  }
}
.snow:nth-child(81) {
  opacity: 0.8356;
  transform: translate(92.9126vw, -10px) scale(0.1963);
  animation: fall-81 18s -24s linear infinite;
}
@keyframes fall-81 {
  76.661% {
    transform: translate(97.4639vw, 76.661vh) scale(0.1963);
  }
  to {
    transform: translate(95.18825vw, 100vh) scale(0.1963);
  }
}
.snow:nth-child(82) {
  opacity: 0.5548;
  transform: translate(57.5992vw, -10px) scale(0.3412);
  animation: fall-82 28s -5s linear infinite;
}
@keyframes fall-82 {
  51.451% {
    transform: translate(65.6992vw, 51.451vh) scale(0.3412);
  }
  to {
    transform: translate(61.6492vw, 100vh) scale(0.3412);
  }
}
.snow:nth-child(83) {
  opacity: 0.5248;
  transform: translate(17.907vw, -10px) scale(0.6886);
  animation: fall-83 20s -25s linear infinite;
}
@keyframes fall-83 {
  68.948% {
    transform: translate(25.8499vw, 68.948vh) scale(0.6886);
  }
  to {
    transform: translate(21.87845vw, 100vh) scale(0.6886);
  }
}
.snow:nth-child(84) {
  opacity: 0.6137;
  transform: translate(14.7925vw, -10px) scale(0.9054);
  animation: fall-84 11s -11s linear infinite;
}
@keyframes fall-84 {
  59.681% {
    transform: translate(8.6981vw, 59.681vh) scale(0.9054);
  }
  to {
    transform: translate(11.7453vw, 100vh) scale(0.9054);
  }
}
.snow:nth-child(85) {
  opacity: 0.9703;
  transform: translate(66.1281vw, -10px) scale(0.4557);
  animation: fall-85 11s -29s linear infinite;
}
@keyframes fall-85 {
  48.111% {
    transform: translate(60.9005vw, 48.111vh) scale(0.4557);
  }
  to {
    transform: translate(63.5143vw, 100vh) scale(0.4557);
  }
}
.snow:nth-child(86) {
  opacity: 0.2123;
  transform: translate(53.4345vw, -10px) scale(0.9407);
  animation: fall-86 28s -17s linear infinite;
}
@keyframes fall-86 {
  47.532% {
    transform: translate(53.8079vw, 47.532vh) scale(0.9407);
  }
  to {
    transform: translate(53.6212vw, 100vh) scale(0.9407);
  }
}
.snow:nth-child(87) {
  opacity: 0.8385;
  transform: translate(62.5837vw, -10px) scale(0.3947);
  animation: fall-87 25s -29s linear infinite;
}
@keyframes fall-87 {
  39.734% {
    transform: translate(69.902vw, 39.734vh) scale(0.3947);
  }
  to {
    transform: translate(66.24285vw, 100vh) scale(0.3947);
  }
}
.snow:nth-child(88) {
  opacity: 0.3131;
  transform: translate(29.9857vw, -10px) scale(0.7231);
  animation: fall-88 17s -9s linear infinite;
}
@keyframes fall-88 {
  73.682% {
    transform: translate(37.9885vw, 73.682vh) scale(0.7231);
  }
  to {
    transform: translate(33.9871vw, 100vh) scale(0.7231);
  }
}
.snow:nth-child(89) {
  opacity: 0.0795;
  transform: translate(91.0149vw, -10px) scale(0.954);
  animation: fall-89 29s -28s linear infinite;
}
@keyframes fall-89 {
  35.289% {
    transform: translate(81.4136vw, 35.289vh) scale(0.954);
  }
  to {
    transform: translate(86.21425vw, 100vh) scale(0.954);
  }
}
.snow:nth-child(90) {
  opacity: 0.262;
  transform: translate(64.4vw, -10px) scale(0.8879);
  animation: fall-90 24s -5s linear infinite;
}
@keyframes fall-90 {
  47.251% {
    transform: translate(72.9289vw, 47.251vh) scale(0.8879);
  }
  to {
    transform: translate(68.66445vw, 100vh) scale(0.8879);
  }
}
.snow:nth-child(91) {
  opacity: 0.8436;
  transform: translate(55.4469vw, -10px) scale(0.2231);
  animation: fall-91 21s -30s linear infinite;
}
@keyframes fall-91 {
  38.422% {
    transform: translate(62.3851vw, 38.422vh) scale(0.2231);
  }
  to {
    transform: translate(58.916vw, 100vh) scale(0.2231);
  }
}
.snow:nth-child(92) {
  opacity: 0.8796;
  transform: translate(47.7785vw, -10px) scale(0.632);
  animation: fall-92 21s -14s linear infinite;
}
@keyframes fall-92 {
  36.066% {
    transform: translate(47.9987vw, 36.066vh) scale(0.632);
  }
  to {
    transform: translate(47.8886vw, 100vh) scale(0.632);
  }
}
.snow:nth-child(93) {
  opacity: 0.8777;
  transform: translate(28.7814vw, -10px) scale(0.4764);
  animation: fall-93 15s -16s linear infinite;
}
@keyframes fall-93 {
  52.622% {
    transform: translate(20.4213vw, 52.622vh) scale(0.4764);
  }
  to {
    transform: translate(24.60135vw, 100vh) scale(0.4764);
  }
}
.snow:nth-child(94) {
  opacity: 0.6948;
  transform: translate(17.4588vw, -10px) scale(0.8524);
  animation: fall-94 15s -2s linear infinite;
}
@keyframes fall-94 {
  31.569% {
    transform: translate(17.3509vw, 31.569vh) scale(0.8524);
  }
  to {
    transform: translate(17.40485vw, 100vh) scale(0.8524);
  }
}
.snow:nth-child(95) {
  opacity: 0.9713;
  transform: translate(52.1854vw, -10px) scale(0.7432);
  animation: fall-95 23s -1s linear infinite;
}
@keyframes fall-95 {
  65.881% {
    transform: translate(60.0106vw, 65.881vh) scale(0.7432);
  }
  to {
    transform: translate(56.098vw, 100vh) scale(0.7432);
  }
}
.snow:nth-child(96) {
  opacity: 0.4574;
  transform: translate(4.3588vw, -10px) scale(0.2544);
  animation: fall-96 17s -18s linear infinite;
}
@keyframes fall-96 {
  39.589% {
    transform: translate(10.4981vw, 39.589vh) scale(0.2544);
  }
  to {
    transform: translate(7.42845vw, 100vh) scale(0.2544);
  }
}
.snow:nth-child(97) {
  opacity: 0.9399;
  transform: translate(39.6713vw, -10px) scale(0.9936);
  animation: fall-97 26s -8s linear infinite;
}
@keyframes fall-97 {
  65.871% {
    transform: translate(39.2876vw, 65.871vh) scale(0.9936);
  }
  to {
    transform: translate(39.47945vw, 100vh) scale(0.9936);
  }
}
.snow:nth-child(98) {
  opacity: 0.2723;
  transform: translate(24.5546vw, -10px) scale(0.9189);
  animation: fall-98 24s -16s linear infinite;
}
@keyframes fall-98 {
  41.955% {
    transform: translate(17.957vw, 41.955vh) scale(0.9189);
  }
  to {
    transform: translate(21.2558vw, 100vh) scale(0.9189);
  }
}
.snow:nth-child(99) {
  opacity: 0.1406;
  transform: translate(41.658vw, -10px) scale(0.235);
  animation: fall-99 13s -28s linear infinite;
}
@keyframes fall-99 {
  66.502% {
    transform: translate(45.9113vw, 66.502vh) scale(0.235);
  }
  to {
    transform: translate(43.78465vw, 100vh) scale(0.235);
  }
}
.snow:nth-child(100) {
  opacity: 0.1539;
  transform: translate(62.1175vw, -10px) scale(0.6909);
  animation: fall-100 29s -9s linear infinite;
}
@keyframes fall-100 {
  42.907% {
    transform: translate(58.7447vw, 42.907vh) scale(0.6909);
  }
  to {
    transform: translate(60.4311vw, 100vh) scale(0.6909);
  }
}
.snow:nth-child(101) {
  opacity: 0.8033;
  transform: translate(26.3331vw, -10px) scale(0.8738);
  animation: fall-101 14s -7s linear infinite;
}
@keyframes fall-101 {
  73.882% {
    transform: translate(29.947vw, 73.882vh) scale(0.8738);
  }
  to {
    transform: translate(28.14005vw, 100vh) scale(0.8738);
  }
}
.snow:nth-child(102) {
  opacity: 0.5158;
  transform: translate(3.6273vw, -10px) scale(0.2433);
  animation: fall-102 11s -25s linear infinite;
}
@keyframes fall-102 {
  38.095% {
    transform: translate(13.3998vw, 38.095vh) scale(0.2433);
  }
  to {
    transform: translate(8.51355vw, 100vh) scale(0.2433);
  }
}
.snow:nth-child(103) {
  opacity: 0.9581;
  transform: translate(61.7785vw, -10px) scale(0.5994);
  animation: fall-103 19s -8s linear infinite;
}
@keyframes fall-103 {
  30.682% {
    transform: translate(70.4982vw, 30.682vh) scale(0.5994);
  }
  to {
    transform: translate(66.13835vw, 100vh) scale(0.5994);
  }
}
.snow:nth-child(104) {
  opacity: 0.5221;
  transform: translate(81.0546vw, -10px) scale(0.3012);
  animation: fall-104 19s -18s linear infinite;
}
@keyframes fall-104 {
  73.296% {
    transform: translate(81.8502vw, 73.296vh) scale(0.3012);
  }
  to {
    transform: translate(81.4524vw, 100vh) scale(0.3012);
  }
}
.snow:nth-child(105) {
  opacity: 0.897;
  transform: translate(90.1961vw, -10px) scale(0.9304);
  animation: fall-105 30s -22s linear infinite;
}
@keyframes fall-105 {
  68.639% {
    transform: translate(81.2454vw, 68.639vh) scale(0.9304);
  }
  to {
    transform: translate(85.72075vw, 100vh) scale(0.9304);
  }
}
.snow:nth-child(106) {
  opacity: 0.0308;
  transform: translate(18.6107vw, -10px) scale(0.9307);
  animation: fall-106 22s -2s linear infinite;
}
@keyframes fall-106 {
  74.022% {
    transform: translate(24.7468vw, 74.022vh) scale(0.9307);
  }
  to {
    transform: translate(21.67875vw, 100vh) scale(0.9307);
  }
}
.snow:nth-child(107) {
  opacity: 0.8889;
  transform: translate(66.7065vw, -10px) scale(0.1346);
  animation: fall-107 28s -26s linear infinite;
}
@keyframes fall-107 {
  48.355% {
    transform: translate(57.983vw, 48.355vh) scale(0.1346);
  }
  to {
    transform: translate(62.34475vw, 100vh) scale(0.1346);
  }
}
.snow:nth-child(108) {
  opacity: 0.2896;
  transform: translate(65.6152vw, -10px) scale(0.0957);
  animation: fall-108 30s -8s linear infinite;
}
@keyframes fall-108 {
  30.779% {
    transform: translate(71.3606vw, 30.779vh) scale(0.0957);
  }
  to {
    transform: translate(68.4879vw, 100vh) scale(0.0957);
  }
}
.snow:nth-child(109) {
  opacity: 0.7773;
  transform: translate(42.5616vw, -10px) scale(0.1873);
  animation: fall-109 28s -27s linear infinite;
}
@keyframes fall-109 {
  71.945% {
    transform: translate(42.7799vw, 71.945vh) scale(0.1873);
  }
  to {
    transform: translate(42.67075vw, 100vh) scale(0.1873);
  }
}
.snow:nth-child(110) {
  opacity: 0.3869;
  transform: translate(60.6924vw, -10px) scale(0.2808);
  animation: fall-110 22s -12s linear infinite;
}
@keyframes fall-110 {
  34.86% {
    transform: translate(63.5964vw, 34.86vh) scale(0.2808);
  }
  to {
    transform: translate(62.1444vw, 100vh) scale(0.2808);
  }
}
.snow:nth-child(111) {
  opacity: 0.477;
  transform: translate(17.7535vw, -10px) scale(0.0535);
  animation: fall-111 11s -28s linear infinite;
}
@keyframes fall-111 {
  75.423% {
    transform: translate(14.3322vw, 75.423vh) scale(0.0535);
  }
  to {
    transform: translate(16.04285vw, 100vh) scale(0.0535);
  }
}
.snow:nth-child(112) {
  opacity: 0.091;
  transform: translate(44.9599vw, -10px) scale(0.0235);
  animation: fall-112 11s -16s linear infinite;
}
@keyframes fall-112 {
  31.278% {
    transform: translate(41.1134vw, 31.278vh) scale(0.0235);
  }
  to {
    transform: translate(43.03665vw, 100vh) scale(0.0235);
  }
}
.snow:nth-child(113) {
  opacity: 0.8979;
  transform: translate(29.6711vw, -10px) scale(0.7713);
  animation: fall-113 21s -20s linear infinite;
}
@keyframes fall-113 {
  60.664% {
    transform: translate(28.8104vw, 60.664vh) scale(0.7713);
  }
  to {
    transform: translate(29.24075vw, 100vh) scale(0.7713);
  }
}
.snow:nth-child(114) {
  opacity: 0.9076;
  transform: translate(98.7007vw, -10px) scale(0.9526);
  animation: fall-114 16s -16s linear infinite;
}
@keyframes fall-114 {
  41.713% {
    transform: translate(98.097vw, 41.713vh) scale(0.9526);
  }
  to {
    transform: translate(98.39885vw, 100vh) scale(0.9526);
  }
}
.snow:nth-child(115) {
  opacity: 0.5182;
  transform: translate(36.9969vw, -10px) scale(0.0329);
  animation: fall-115 28s -26s linear infinite;
}
@keyframes fall-115 {
  37.074% {
    transform: translate(35.1663vw, 37.074vh) scale(0.0329);
  }
  to {
    transform: translate(36.0816vw, 100vh) scale(0.0329);
  }
}
.snow:nth-child(116) {
  opacity: 0.7004;
  transform: translate(84.4332vw, -10px) scale(0.7404);
  animation: fall-116 17s -20s linear infinite;
}
@keyframes fall-116 {
  75.252% {
    transform: translate(89.7644vw, 75.252vh) scale(0.7404);
  }
  to {
    transform: translate(87.0988vw, 100vh) scale(0.7404);
  }
}
.snow:nth-child(117) {
  opacity: 0.1297;
  transform: translate(58.4008vw, -10px) scale(0.6273);
  animation: fall-117 18s -15s linear infinite;
}
@keyframes fall-117 {
  34.204% {
    transform: translate(58.7819vw, 34.204vh) scale(0.6273);
  }
  to {
    transform: translate(58.59135vw, 100vh) scale(0.6273);
  }
}
.snow:nth-child(118) {
  opacity: 0.5915;
  transform: translate(51.6401vw, -10px) scale(0.8531);
  animation: fall-118 26s -6s linear infinite;
}
@keyframes fall-118 {
  38.049% {
    transform: translate(57.7573vw, 38.049vh) scale(0.8531);
  }
  to {
    transform: translate(54.6987vw, 100vh) scale(0.8531);
  }
}
.snow:nth-child(119) {
  opacity: 0.4831;
  transform: translate(96.2439vw, -10px) scale(0.7121);
  animation: fall-119 10s -9s linear infinite;
}
@keyframes fall-119 {
  68.038% {
    transform: translate(96.3389vw, 68.038vh) scale(0.7121);
  }
  to {
    transform: translate(96.2914vw, 100vh) scale(0.7121);
  }
}
.snow:nth-child(120) {
  opacity: 0.3958;
  transform: translate(37.8958vw, -10px) scale(0.2717);
  animation: fall-120 19s -28s linear infinite;
}
@keyframes fall-120 {
  32.581% {
    transform: translate(31.4745vw, 32.581vh) scale(0.2717);
  }
  to {
    transform: translate(34.68515vw, 100vh) scale(0.2717);
  }
}
.snow:nth-child(121) {
  opacity: 0.5091;
  transform: translate(0.9844vw, -10px) scale(0.5413);
  animation: fall-121 24s -7s linear infinite;
}
@keyframes fall-121 {
  67.89% {
    transform: translate(-6.6723vw, 67.89vh) scale(0.5413);
  }
  to {
    transform: translate(-2.84395vw, 100vh) scale(0.5413);
  }
}
.snow:nth-child(122) {
  opacity: 0.251;
  transform: translate(1.3714vw, -10px) scale(0.4791);
  animation: fall-122 10s -22s linear infinite;
}
@keyframes fall-122 {
  33.778% {
    transform: translate(-1.3315vw, 33.778vh) scale(0.4791);
  }
  to {
    transform: translate(0.01995vw, 100vh) scale(0.4791);
  }
}
.snow:nth-child(123) {
  opacity: 0.5749;
  transform: translate(93.892vw, -10px) scale(0.8846);
  animation: fall-123 20s -5s linear infinite;
}
@keyframes fall-123 {
  67.802% {
    transform: translate(102.2994vw, 67.802vh) scale(0.8846);
  }
  to {
    transform: translate(98.0957vw, 100vh) scale(0.8846);
  }
}
.snow:nth-child(124) {
  opacity: 0.8269;
  transform: translate(17.7142vw, -10px) scale(0.342);
  animation: fall-124 20s -5s linear infinite;
}
@keyframes fall-124 {
  60.042% {
    transform: translate(11.9898vw, 60.042vh) scale(0.342);
  }
  to {
    transform: translate(14.852vw, 100vh) scale(0.342);
  }
}
.snow:nth-child(125) {
  opacity: 0.3528;
  transform: translate(30.727vw, -10px) scale(0.7819);
  animation: fall-125 10s -9s linear infinite;
}
@keyframes fall-125 {
  33.445% {
    transform: translate(35.1867vw, 33.445vh) scale(0.7819);
  }
  to {
    transform: translate(32.95685vw, 100vh) scale(0.7819);
  }
}
.snow:nth-child(126) {
  opacity: 0.4082;
  transform: translate(96.9872vw, -10px) scale(0.3117);
  animation: fall-126 30s -5s linear infinite;
}
@keyframes fall-126 {
  64.757% {
    transform: translate(92.5108vw, 64.757vh) scale(0.3117);
  }
  to {
    transform: translate(94.749vw, 100vh) scale(0.3117);
  }
}
.snow:nth-child(127) {
  opacity: 0.703;
  transform: translate(59.5953vw, -10px) scale(0.8794);
  animation: fall-127 17s -6s linear infinite;
}
@keyframes fall-127 {
  72.444% {
    transform: translate(69.569vw, 72.444vh) scale(0.8794);
  }
  to {
    transform: translate(64.58215vw, 100vh) scale(0.8794);
  }
}
.snow:nth-child(128) {
  opacity: 0.3523;
  transform: translate(7.0101vw, -10px) scale(0.8026);
  animation: fall-128 10s -2s linear infinite;
}
@keyframes fall-128 {
  49.18% {
    transform: translate(-1.5095vw, 49.18vh) scale(0.8026);
  }
  to {
    transform: translate(2.7503vw, 100vh) scale(0.8026);
  }
}
.snow:nth-child(129) {
  opacity: 0.2523;
  transform: translate(94.8249vw, -10px) scale(0.6863);
  animation: fall-129 10s -14s linear infinite;
}
@keyframes fall-129 {
  59.641% {
    transform: translate(96.0793vw, 59.641vh) scale(0.6863);
  }
  to {
    transform: translate(95.4521vw, 100vh) scale(0.6863);
  }
}
.snow:nth-child(130) {
  opacity: 0.9238;
  transform: translate(63.0979vw, -10px) scale(0.8488);
  animation: fall-130 27s -21s linear infinite;
}
@keyframes fall-130 {
  56.378% {
    transform: translate(58.9763vw, 56.378vh) scale(0.8488);
  }
  to {
    transform: translate(61.0371vw, 100vh) scale(0.8488);
  }
}
.snow:nth-child(131) {
  opacity: 0.6231;
  transform: translate(22.1392vw, -10px) scale(0.5465);
  animation: fall-131 16s -12s linear infinite;
}
@keyframes fall-131 {
  64.119% {
    transform: translate(30.8616vw, 64.119vh) scale(0.5465);
  }
  to {
    transform: translate(26.5004vw, 100vh) scale(0.5465);
  }
}
.snow:nth-child(132) {
  opacity: 0.2822;
  transform: translate(64.077vw, -10px) scale(0.6088);
  animation: fall-132 16s -26s linear infinite;
}
@keyframes fall-132 {
  52.689% {
    transform: translate(66.8872vw, 52.689vh) scale(0.6088);
  }
  to {
    transform: translate(65.4821vw, 100vh) scale(0.6088);
  }
}
.snow:nth-child(133) {
  opacity: 0.9481;
  transform: translate(19.5375vw, -10px) scale(0.4631);
  animation: fall-133 26s -6s linear infinite;
}
@keyframes fall-133 {
  32.805% {
    transform: translate(22.8715vw, 32.805vh) scale(0.4631);
  }
  to {
    transform: translate(21.2045vw, 100vh) scale(0.4631);
  }
}
.snow:nth-child(134) {
  opacity: 0.9795;
  transform: translate(8.7324vw, -10px) scale(0.8207);
  animation: fall-134 29s -21s linear infinite;
}
@keyframes fall-134 {
  34.842% {
    transform: translate(10.7026vw, 34.842vh) scale(0.8207);
  }
  to {
    transform: translate(9.7175vw, 100vh) scale(0.8207);
  }
}
.snow:nth-child(135) {
  opacity: 0.0948;
  transform: translate(89.3557vw, -10px) scale(0.3731);
  animation: fall-135 14s -23s linear infinite;
}
@keyframes fall-135 {
  72.755% {
    transform: translate(85.7832vw, 72.755vh) scale(0.3731);
  }
  to {
    transform: translate(87.56945vw, 100vh) scale(0.3731);
  }
}
.snow:nth-child(136) {
  opacity: 0.0886;
  transform: translate(16.0637vw, -10px) scale(0.2721);
  animation: fall-136 12s -5s linear infinite;
}
@keyframes fall-136 {
  36.964% {
    transform: translate(11.955vw, 36.964vh) scale(0.2721);
  }
  to {
    transform: translate(14.00935vw, 100vh) scale(0.2721);
  }
}
.snow:nth-child(137) {
  opacity: 0.3808;
  transform: translate(18.3034vw, -10px) scale(0.6784);
  animation: fall-137 29s -27s linear infinite;
}
@keyframes fall-137 {
  69.675% {
    transform: translate(15.9018vw, 69.675vh) scale(0.6784);
  }
  to {
    transform: translate(17.1026vw, 100vh) scale(0.6784);
  }
}
.snow:nth-child(138) {
  opacity: 0.9341;
  transform: translate(78.7587vw, -10px) scale(0.8);
  animation: fall-138 26s -11s linear infinite;
}
@keyframes fall-138 {
  60.331% {
    transform: translate(74.5664vw, 60.331vh) scale(0.8);
  }
  to {
    transform: translate(76.66255vw, 100vh) scale(0.8);
  }
}
.snow:nth-child(139) {
  opacity: 0.2627;
  transform: translate(24.323vw, -10px) scale(0.4899);
  animation: fall-139 13s -25s linear infinite;
}
@keyframes fall-139 {
  42.136% {
    transform: translate(28.4002vw, 42.136vh) scale(0.4899);
  }
  to {
    transform: translate(26.3616vw, 100vh) scale(0.4899);
  }
}
.snow:nth-child(140) {
  opacity: 0.1899;
  transform: translate(8.0026vw, -10px) scale(0.1531);
  animation: fall-140 13s -6s linear infinite;
}
@keyframes fall-140 {
  52.768% {
    transform: translate(13.6409vw, 52.768vh) scale(0.1531);
  }
  to {
    transform: translate(10.82175vw, 100vh) scale(0.1531);
  }
}
.snow:nth-child(141) {
  opacity: 0.9433;
  transform: translate(71.2571vw, -10px) scale(0.1362);
  animation: fall-141 23s -8s linear infinite;
}
@keyframes fall-141 {
  68.545% {
    transform: translate(65.173vw, 68.545vh) scale(0.1362);
  }
  to {
    transform: translate(68.21505vw, 100vh) scale(0.1362);
  }
}
.snow:nth-child(142) {
  opacity: 0.5428;
  transform: translate(46.8758vw, -10px) scale(0.4331);
  animation: fall-142 20s -21s linear infinite;
}
@keyframes fall-142 {
  31.536% {
    transform: translate(48.7925vw, 31.536vh) scale(0.4331);
  }
  to {
    transform: translate(47.83415vw, 100vh) scale(0.4331);
  }
}
.snow:nth-child(143) {
  opacity: 0.1675;
  transform: translate(77.6954vw, -10px) scale(0.8309);
  animation: fall-143 22s -26s linear infinite;
}
@keyframes fall-143 {
  69.097% {
    transform: translate(84.7494vw, 69.097vh) scale(0.8309);
  }
  to {
    transform: translate(81.2224vw, 100vh) scale(0.8309);
  }
}
.snow:nth-child(144) {
  opacity: 0.5921;
  transform: translate(27.42vw, -10px) scale(0.705);
  animation: fall-144 20s -25s linear infinite;
}
@keyframes fall-144 {
  40.449% {
    transform: translate(28.7136vw, 40.449vh) scale(0.705);
  }
  to {
    transform: translate(28.0668vw, 100vh) scale(0.705);
  }
}
.snow:nth-child(145) {
  opacity: 0.7822;
  transform: translate(68.5136vw, -10px) scale(0.3107);
  animation: fall-145 17s -25s linear infinite;
}
@keyframes fall-145 {
  59.868% {
    transform: translate(71.2253vw, 59.868vh) scale(0.3107);
  }
  to {
    transform: translate(69.86945vw, 100vh) scale(0.3107);
  }
}
.snow:nth-child(146) {
  opacity: 0.1013;
  transform: translate(45.5071vw, -10px) scale(0.3777);
  animation: fall-146 10s -18s linear infinite;
}
@keyframes fall-146 {
  76.025% {
    transform: translate(42.4331vw, 76.025vh) scale(0.3777);
  }
  to {
    transform: translate(43.9701vw, 100vh) scale(0.3777);
  }
}
.snow:nth-child(147) {
  opacity: 0.6978;
  transform: translate(44.0367vw, -10px) scale(0.6283);
  animation: fall-147 18s -21s linear infinite;
}
@keyframes fall-147 {
  32.243% {
    transform: translate(40.9479vw, 32.243vh) scale(0.6283);
  }
  to {
    transform: translate(42.4923vw, 100vh) scale(0.6283);
  }
}
.snow:nth-child(148) {
  opacity: 0.3318;
  transform: translate(57.1843vw, -10px) scale(0.4355);
  animation: fall-148 16s -13s linear infinite;
}
@keyframes fall-148 {
  43.361% {
    transform: translate(65.568vw, 43.361vh) scale(0.4355);
  }
  to {
    transform: translate(61.37615vw, 100vh) scale(0.4355);
  }
}
.snow:nth-child(149) {
  opacity: 0.5121;
  transform: translate(52.6141vw, -10px) scale(0.3321);
  animation: fall-149 16s -17s linear infinite;
}
@keyframes fall-149 {
  49.764% {
    transform: translate(54.1693vw, 49.764vh) scale(0.3321);
  }
  to {
    transform: translate(53.3917vw, 100vh) scale(0.3321);
  }
}
.snow:nth-child(150) {
  opacity: 0.1745;
  transform: translate(47.5377vw, -10px) scale(0.7031);
  animation: fall-150 17s -6s linear infinite;
}
@keyframes fall-150 {
  52.42% {
    transform: translate(49.4988vw, 52.42vh) scale(0.7031);
  }
  to {
    transform: translate(48.51825vw, 100vh) scale(0.7031);
  }
}
.snow:nth-child(151) {
  opacity: 0.5603;
  transform: translate(47.5232vw, -10px) scale(0.1675);
  animation: fall-151 16s -9s linear infinite;
}
@keyframes fall-151 {
  71.69% {
    transform: translate(49.698vw, 71.69vh) scale(0.1675);
  }
  to {
    transform: translate(48.6106vw, 100vh) scale(0.1675);
  }
}
.snow:nth-child(152) {
  opacity: 0.8247;
  transform: translate(69.1351vw, -10px) scale(0.6651);
  animation: fall-152 22s -18s linear infinite;
}
@keyframes fall-152 {
  69.337% {
    transform: translate(59.4345vw, 69.337vh) scale(0.6651);
  }
  to {
    transform: translate(64.2848vw, 100vh) scale(0.6651);
  }
}
.snow:nth-child(153) {
  opacity: 0.3777;
  transform: translate(61.9391vw, -10px) scale(0.1594);
  animation: fall-153 26s -20s linear infinite;
}
@keyframes fall-153 {
  31.973% {
    transform: translate(61.6278vw, 31.973vh) scale(0.1594);
  }
  to {
    transform: translate(61.78345vw, 100vh) scale(0.1594);
  }
}
.snow:nth-child(154) {
  opacity: 0.1899;
  transform: translate(53.6709vw, -10px) scale(0.2624);
  animation: fall-154 21s -21s linear infinite;
}
@keyframes fall-154 {
  40.222% {
    transform: translate(58.9126vw, 40.222vh) scale(0.2624);
  }
  to {
    transform: translate(56.29175vw, 100vh) scale(0.2624);
  }
}
.snow:nth-child(155) {
  opacity: 0.4258;
  transform: translate(83.6835vw, -10px) scale(0.578);
  animation: fall-155 25s -26s linear infinite;
}
@keyframes fall-155 {
  34.602% {
    transform: translate(86.399vw, 34.602vh) scale(0.578);
  }
  to {
    transform: translate(85.04125vw, 100vh) scale(0.578);
  }
}
.snow:nth-child(156) {
  opacity: 0.0523;
  transform: translate(84.6024vw, -10px) scale(0.2475);
  animation: fall-156 17s -25s linear infinite;
}
@keyframes fall-156 {
  32.945% {
    transform: translate(80.4483vw, 32.945vh) scale(0.2475);
  }
  to {
    transform: translate(82.52535vw, 100vh) scale(0.2475);
  }
}
.snow:nth-child(157) {
  opacity: 0.8474;
  transform: translate(36.9843vw, -10px) scale(0.9512);
  animation: fall-157 16s -10s linear infinite;
}
@keyframes fall-157 {
  62.196% {
    transform: translate(46.3998vw, 62.196vh) scale(0.9512);
  }
  to {
    transform: translate(41.69205vw, 100vh) scale(0.9512);
  }
}
.snow:nth-child(158) {
  opacity: 0.023;
  transform: translate(94.8478vw, -10px) scale(0.8885);
  animation: fall-158 16s -6s linear infinite;
}
@keyframes fall-158 {
  41.711% {
    transform: translate(99.7886vw, 41.711vh) scale(0.8885);
  }
  to {
    transform: translate(97.3182vw, 100vh) scale(0.8885);
  }
}
.snow:nth-child(159) {
  opacity: 0.2747;
  transform: translate(21.1058vw, -10px) scale(0.0847);
  animation: fall-159 22s -24s linear infinite;
}
@keyframes fall-159 {
  58.439% {
    transform: translate(12.2748vw, 58.439vh) scale(0.0847);
  }
  to {
    transform: translate(16.6903vw, 100vh) scale(0.0847);
  }
}
.snow:nth-child(160) {
  opacity: 0.4569;
  transform: translate(95.2863vw, -10px) scale(0.4085);
  animation: fall-160 30s -2s linear infinite;
}
@keyframes fall-160 {
  69.486% {
    transform: translate(103.3826vw, 69.486vh) scale(0.4085);
  }
  to {
    transform: translate(99.33445vw, 100vh) scale(0.4085);
  }
}
.snow:nth-child(161) {
  opacity: 0.251;
  transform: translate(61.6846vw, -10px) scale(0.9749);
  animation: fall-161 11s -12s linear infinite;
}
@keyframes fall-161 {
  78.575% {
    transform: translate(64.1193vw, 78.575vh) scale(0.9749);
  }
  to {
    transform: translate(62.90195vw, 100vh) scale(0.9749);
  }
}
.snow:nth-child(162) {
  opacity: 0.4831;
  transform: translate(24.836vw, -10px) scale(0.9828);
  animation: fall-162 28s -16s linear infinite;
}
@keyframes fall-162 {
  77.704% {
    transform: translate(26.1701vw, 77.704vh) scale(0.9828);
  }
  to {
    transform: translate(25.50305vw, 100vh) scale(0.9828);
  }
}
.snow:nth-child(163) {
  opacity: 0.8075;
  transform: translate(17.2054vw, -10px) scale(0.765);
  animation: fall-163 26s -3s linear infinite;
}
@keyframes fall-163 {
  51.081% {
    transform: translate(13.451vw, 51.081vh) scale(0.765);
  }
  to {
    transform: translate(15.3282vw, 100vh) scale(0.765);
  }
}
.snow:nth-child(164) {
  opacity: 0.8721;
  transform: translate(17.8662vw, -10px) scale(0.3766);
  animation: fall-164 18s -3s linear infinite;
}
@keyframes fall-164 {
  34.731% {
    transform: translate(13.5232vw, 34.731vh) scale(0.3766);
  }
  to {
    transform: translate(15.6947vw, 100vh) scale(0.3766);
  }
}
.snow:nth-child(165) {
  opacity: 0.0921;
  transform: translate(26.9758vw, -10px) scale(0.7742);
  animation: fall-165 25s -14s linear infinite;
}
@keyframes fall-165 {
  68.689% {
    transform: translate(19.5733vw, 68.689vh) scale(0.7742);
  }
  to {
    transform: translate(23.27455vw, 100vh) scale(0.7742);
  }
}
.snow:nth-child(166) {
  opacity: 0.5847;
  transform: translate(48.7269vw, -10px) scale(0.1472);
  animation: fall-166 30s -15s linear infinite;
}
@keyframes fall-166 {
  61.172% {
    transform: translate(45.7368vw, 61.172vh) scale(0.1472);
  }
  to {
    transform: translate(47.23185vw, 100vh) scale(0.1472);
  }
}
.snow:nth-child(167) {
  opacity: 0.7433;
  transform: translate(31.5619vw, -10px) scale(0.8124);
  animation: fall-167 22s -25s linear infinite;
}
@keyframes fall-167 {
  69.031% {
    transform: translate(39.6909vw, 69.031vh) scale(0.8124);
  }
  to {
    transform: translate(35.6264vw, 100vh) scale(0.8124);
  }
}
.snow:nth-child(168) {
  opacity: 0.625;
  transform: translate(75.8603vw, -10px) scale(0.3449);
  animation: fall-168 21s -30s linear infinite;
}
@keyframes fall-168 {
  59.084% {
    transform: translate(80.09vw, 59.084vh) scale(0.3449);
  }
  to {
    transform: translate(77.97515vw, 100vh) scale(0.3449);
  }
}
.snow:nth-child(169) {
  opacity: 0.0293;
  transform: translate(37.2985vw, -10px) scale(0.4062);
  animation: fall-169 27s -6s linear infinite;
}
@keyframes fall-169 {
  33.957% {
    transform: translate(35.8682vw, 33.957vh) scale(0.4062);
  }
  to {
    transform: translate(36.58335vw, 100vh) scale(0.4062);
  }
}
.snow:nth-child(170) {
  opacity: 0.2537;
  transform: translate(12.8419vw, -10px) scale(0.3327);
  animation: fall-170 20s -28s linear infinite;
}
@keyframes fall-170 {
  60.016% {
    transform: translate(12.9753vw, 60.016vh) scale(0.3327);
  }
  to {
    transform: translate(12.9086vw, 100vh) scale(0.3327);
  }
}
.snow:nth-child(171) {
  opacity: 0.6639;
  transform: translate(39.9433vw, -10px) scale(0.0731);
  animation: fall-171 24s -11s linear infinite;
}
@keyframes fall-171 {
  69.166% {
    transform: translate(36.5346vw, 69.166vh) scale(0.0731);
  }
  to {
    transform: translate(38.23895vw, 100vh) scale(0.0731);
  }
}
.snow:nth-child(172) {
  opacity: 0.9536;
  transform: translate(85.6478vw, -10px) scale(0.2315);
  animation: fall-172 13s -4s linear infinite;
}
@keyframes fall-172 {
  67.01% {
    transform: translate(88.4874vw, 67.01vh) scale(0.2315);
  }
  to {
    transform: translate(87.0676vw, 100vh) scale(0.2315);
  }
}
.snow:nth-child(173) {
  opacity: 0.7031;
  transform: translate(42.722vw, -10px) scale(0.2329);
  animation: fall-173 19s -15s linear infinite;
}
@keyframes fall-173 {
  40.556% {
    transform: translate(39.454vw, 40.556vh) scale(0.2329);
  }
  to {
    transform: translate(41.088vw, 100vh) scale(0.2329);
  }
}
.snow:nth-child(174) {
  opacity: 0.3821;
  transform: translate(66.8918vw, -10px) scale(0.575);
  animation: fall-174 22s -15s linear infinite;
}
@keyframes fall-174 {
  71.821% {
    transform: translate(62.8383vw, 71.821vh) scale(0.575);
  }
  to {
    transform: translate(64.86505vw, 100vh) scale(0.575);
  }
}
.snow:nth-child(175) {
  opacity: 0.2857;
  transform: translate(76.503vw, -10px) scale(0.1474);
  animation: fall-175 23s -12s linear infinite;
}
@keyframes fall-175 {
  71.126% {
    transform: translate(70.1646vw, 71.126vh) scale(0.1474);
  }
  to {
    transform: translate(73.3338vw, 100vh) scale(0.1474);
  }
}
.snow:nth-child(176) {
  opacity: 0.6489;
  transform: translate(32.9633vw, -10px) scale(0.8087);
  animation: fall-176 16s -20s linear infinite;
}
@keyframes fall-176 {
  65.664% {
    transform: translate(34.5094vw, 65.664vh) scale(0.8087);
  }
  to {
    transform: translate(33.73635vw, 100vh) scale(0.8087);
  }
}
.snow:nth-child(177) {
  opacity: 0.0602;
  transform: translate(16.2vw, -10px) scale(0.9357);
  animation: fall-177 10s -8s linear infinite;
}
@keyframes fall-177 {
  54.714% {
    transform: translate(21.2528vw, 54.714vh) scale(0.9357);
  }
  to {
    transform: translate(18.7264vw, 100vh) scale(0.9357);
  }
}
.snow:nth-child(178) {
  opacity: 0.1416;
  transform: translate(93.1856vw, -10px) scale(0.1655);
  animation: fall-178 21s -19s linear infinite;
}
@keyframes fall-178 {
  61.591% {
    transform: translate(100.8394vw, 61.591vh) scale(0.1655);
  }
  to {
    transform: translate(97.0125vw, 100vh) scale(0.1655);
  }
}
.snow:nth-child(179) {
  opacity: 0.4227;
  transform: translate(60.6146vw, -10px) scale(0.8822);
  animation: fall-179 19s -4s linear infinite;
}
@keyframes fall-179 {
  33.986% {
    transform: translate(68.4371vw, 33.986vh) scale(0.8822);
  }
  to {
    transform: translate(64.52585vw, 100vh) scale(0.8822);
  }
}
.snow:nth-child(180) {
  opacity: 0.4859;
  transform: translate(39.4443vw, -10px) scale(0.3019);
  animation: fall-180 29s -14s linear infinite;
}
@keyframes fall-180 {
  58.006% {
    transform: translate(33.4726vw, 58.006vh) scale(0.3019);
  }
  to {
    transform: translate(36.45845vw, 100vh) scale(0.3019);
  }
}
.snow:nth-child(181) {
  opacity: 0.9029;
  transform: translate(72.8721vw, -10px) scale(0.9725);
  animation: fall-181 14s -17s linear infinite;
}
@keyframes fall-181 {
  43.207% {
    transform: translate(68.2497vw, 43.207vh) scale(0.9725);
  }
  to {
    transform: translate(70.5609vw, 100vh) scale(0.9725);
  }
}
.snow:nth-child(182) {
  opacity: 0.335;
  transform: translate(23.3253vw, -10px) scale(0.9066);
  animation: fall-182 10s -28s linear infinite;
}
@keyframes fall-182 {
  36.031% {
    transform: translate(33.0561vw, 36.031vh) scale(0.9066);
  }
  to {
    transform: translate(28.1907vw, 100vh) scale(0.9066);
  }
}
.snow:nth-child(183) {
  opacity: 0.9988;
  transform: translate(25.3573vw, -10px) scale(0.1104);
  animation: fall-183 18s -9s linear infinite;
}
@keyframes fall-183 {
  73.754% {
    transform: translate(23.6754vw, 73.754vh) scale(0.1104);
  }
  to {
    transform: translate(24.51635vw, 100vh) scale(0.1104);
  }
}
.snow:nth-child(184) {
  opacity: 0.7307;
  transform: translate(74.8568vw, -10px) scale(0.6101);
  animation: fall-184 27s -17s linear infinite;
}
@keyframes fall-184 {
  68.923% {
    transform: translate(78.9513vw, 68.923vh) scale(0.6101);
  }
  to {
    transform: translate(76.90405vw, 100vh) scale(0.6101);
  }
}
.snow:nth-child(185) {
  opacity: 0.8909;
  transform: translate(85.4258vw, -10px) scale(0.2047);
  animation: fall-185 15s -8s linear infinite;
}
@keyframes fall-185 {
  46.839% {
    transform: translate(83.8984vw, 46.839vh) scale(0.2047);
  }
  to {
    transform: translate(84.6621vw, 100vh) scale(0.2047);
  }
}
.snow:nth-child(186) {
  opacity: 0.8657;
  transform: translate(14.4159vw, -10px) scale(0.969);
  animation: fall-186 24s -19s linear infinite;
}
@keyframes fall-186 {
  32.337% {
    transform: translate(16.1102vw, 32.337vh) scale(0.969);
  }
  to {
    transform: translate(15.26305vw, 100vh) scale(0.969);
  }
}
.snow:nth-child(187) {
  opacity: 0.7988;
  transform: translate(28.7583vw, -10px) scale(0.9567);
  animation: fall-187 19s -13s linear infinite;
}
@keyframes fall-187 {
  75.263% {
    transform: translate(34.3275vw, 75.263vh) scale(0.9567);
  }
  to {
    transform: translate(31.5429vw, 100vh) scale(0.9567);
  }
}
.snow:nth-child(188) {
  opacity: 0.4229;
  transform: translate(34.5721vw, -10px) scale(0.6302);
  animation: fall-188 17s -17s linear infinite;
}
@keyframes fall-188 {
  32.278% {
    transform: translate(28.3184vw, 32.278vh) scale(0.6302);
  }
  to {
    transform: translate(31.44525vw, 100vh) scale(0.6302);
  }
}
.snow:nth-child(189) {
  opacity: 0.8451;
  transform: translate(72.7172vw, -10px) scale(0.9884);
  animation: fall-189 22s -4s linear infinite;
}
@keyframes fall-189 {
  31.604% {
    transform: translate(75.0776vw, 31.604vh) scale(0.9884);
  }
  to {
    transform: translate(73.8974vw, 100vh) scale(0.9884);
  }
}
.snow:nth-child(190) {
  opacity: 0.9049;
  transform: translate(48.2647vw, -10px) scale(0.6576);
  animation: fall-190 14s -11s linear infinite;
}
@keyframes fall-190 {
  51.419% {
    transform: translate(43.4917vw, 51.419vh) scale(0.6576);
  }
  to {
    transform: translate(45.8782vw, 100vh) scale(0.6576);
  }
}
.snow:nth-child(191) {
  opacity: 0.8792;
  transform: translate(5.9025vw, -10px) scale(0.2794);
  animation: fall-191 29s -13s linear infinite;
}
@keyframes fall-191 {
  59.779% {
    transform: translate(-0.9502vw, 59.779vh) scale(0.2794);
  }
  to {
    transform: translate(2.47615vw, 100vh) scale(0.2794);
  }
}
.snow:nth-child(192) {
  opacity: 0.4457;
  transform: translate(11.5862vw, -10px) scale(0.7594);
  animation: fall-192 16s -25s linear infinite;
}
@keyframes fall-192 {
  67.584% {
    transform: translate(18.518vw, 67.584vh) scale(0.7594);
  }
  to {
    transform: translate(15.0521vw, 100vh) scale(0.7594);
  }
}
.snow:nth-child(193) {
  opacity: 0.9581;
  transform: translate(13.868vw, -10px) scale(0.2424);
  animation: fall-193 29s -1s linear infinite;
}
@keyframes fall-193 {
  57.306% {
    transform: translate(16.8717vw, 57.306vh) scale(0.2424);
  }
  to {
    transform: translate(15.36985vw, 100vh) scale(0.2424);
  }
}
.snow:nth-child(194) {
  opacity: 0.9511;
  transform: translate(81.9687vw, -10px) scale(0.8378);
  animation: fall-194 14s -4s linear infinite;
}
@keyframes fall-194 {
  68.561% {
    transform: translate(88.9731vw, 68.561vh) scale(0.8378);
  }
  to {
    transform: translate(85.4709vw, 100vh) scale(0.8378);
  }
}
.snow:nth-child(195) {
  opacity: 0.7471;
  transform: translate(1.5136vw, -10px) scale(0.9606);
  animation: fall-195 20s -19s linear infinite;
}
@keyframes fall-195 {
  44.826% {
    transform: translate(7.8862vw, 44.826vh) scale(0.9606);
  }
  to {
    transform: translate(4.6999vw, 100vh) scale(0.9606);
  }
}
.snow:nth-child(196) {
  opacity: 0.8955;
  transform: translate(38.5218vw, -10px) scale(0.5558);
  animation: fall-196 15s -4s linear infinite;
}
@keyframes fall-196 {
  42.229% {
    transform: translate(41.0187vw, 42.229vh) scale(0.5558);
  }
  to {
    transform: translate(39.77025vw, 100vh) scale(0.5558);
  }
}
.snow:nth-child(197) {
  opacity: 0.8072;
  transform: translate(94.5808vw, -10px) scale(0.1067);
  animation: fall-197 12s -13s linear infinite;
}
@keyframes fall-197 {
  77.019% {
    transform: translate(98.2vw, 77.019vh) scale(0.1067);
  }
  to {
    transform: translate(96.3904vw, 100vh) scale(0.1067);
  }
}
.snow:nth-child(198) {
  opacity: 0.6205;
  transform: translate(35.4506vw, -10px) scale(0.057);
  animation: fall-198 23s -16s linear infinite;
}
@keyframes fall-198 {
  59.97% {
    transform: translate(34.8194vw, 59.97vh) scale(0.057);
  }
  to {
    transform: translate(35.135vw, 100vh) scale(0.057);
  }
}
.snow:nth-child(199) {
  opacity: 0.093;
  transform: translate(5.0658vw, -10px) scale(0.6032);
  animation: fall-199 13s -9s linear infinite;
}
@keyframes fall-199 {
  54.733% {
    transform: translate(5.3216vw, 54.733vh) scale(0.6032);
  }
  to {
    transform: translate(5.1937vw, 100vh) scale(0.6032);
  }
}
.snow:nth-child(200) {
  opacity: 0.1628;
  transform: translate(59.314vw, -10px) scale(0.0007);
  animation: fall-200 27s -10s linear infinite;
}
@keyframes fall-200 {
  63.058% {
    transform: translate(56.4278vw, 63.058vh) scale(0.0007);
  }
  to {
    transform: translate(57.8709vw, 100vh) scale(0.0007);
  }
}

/* progress bar */

/* ================================================== */

.de-progress {
  width: 100%;
  height: 8px;
  background: #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  overflow: hidden;
}

.de-progress .progress-bar {
  background: #333;
  height: 8px;
  box-shadow: none;
}

.de-progress {
  background: #f5f5f5;
}

.skill-bar {
  margin-bottom: 40px;
}

.skill-bar.style-2 .de-progress {
  background: rgba(0, 0, 0, 0.1);
}

.skill-bar .value {
  color: #ccc;
  font-size: 12px;
  font-weight: bold;
  float: right;
  margin-top: -40px;
}

.rtl .skill-bar .value {
  float: left;
}

.skill-bar h4 {
  font-size: 16px;
}
.home-banner-image {
  text-align: right;
}
/* Dopamine New Section */
.homeMainBanner {
  min-height: 100vh;
  margin-top: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.homeMainBanner::before {
  content: "";
  background: url(../images/globe_01.png) no-repeat scroll 0 0;
  display: inline-block;
  width: 131px;
  height: 158px;
  position: absolute;
  top: 0;
  left: 0;
}
.homeMainBanner::after {
  content: "";
  background: url(../images/globe-right-bootom.png) no-repeat scroll right
    bottom;
  display: inline-block;
  width: 73px;
  height: 176px;
  position: absolute;
  right: 0;
  bottom: 100px;
}
.homeBannerContent h1 {
  font-family: "Bebas Neue", cursive;
  font-size: 62px;
  color: #ffffff;
}

.homeBannerContent h6 {
  font-size: 24px;
  margin-bottom: 45px;
  font-weight: 400;
}
.home-banner-left-content {
  position: relative;
}
.home-banner-left-content::after {
  content: "";
  background: url(../images/box_01.png) no-repeat scroll right bottom;
  display: inline-block;
  width: 321px;
  height: 131px;
  position: absolute;
  right: 0px;
  bottom: -100px;
}
.home-banner-left-content::before {
  content: "";
  background: url(../images/box_03.png) no-repeat scroll right bottom;
  display: inline-block;
  width: 174px;
  height: 86px;
  position: absolute;
  right: -150px;
  top: -100px;
}
.btnLarge {
  background: #fc943e;
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  padding: 25px 30px 20px;
  display: inline-block;
  font-weight: 600;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 20%,
    100% 61%,
    86% 100%,
    0 99%,
    0% 80%,
    0% 20%
  );
}
.btnLarge:hover {
  color: #000000;
  background: #ffffff;
}

.homeFanBanner {
  background-color: #180d55;
  -webkit-box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.6);
  width: 1127px;
  max-width: 100%;
  background-size: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.homeFanContent {
  padding-right: 75px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}
.homeFanContent p {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 30px;
}

.collageContent {
  background-color: #180d55;
  -webkit-box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.6);
  width: 1140px;
  max-width: 100%;
  background-size: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-top: 45px;
}
.homeVisionMisson p {
  color: #ffffff;
  font-size: 20px;
}
.mvHomeBg {
  background-color: #180d55;
  -webkit-box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.6);
  width: 1143px;
  max-width: 100%;
  margin: 0 auto;
  padding: 45px;
  position: relative;
  z-index: 1;
}
.mvHomeBg::before {
  content: "";
  background: url(../images/mv.png) no-repeat scroll 0 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 382px;
  height: 447px;
}
.mvHomeBg::after {
  content: "";
  background: url(../images/vison-vector1.png) no-repeat scroll 0 0;
  position: absolute;
  right: 60%;
  top: 40%;
  width: 131px;
  height: 100px;
  z-index: 1;
}

.mvHomeBg .row::before {
  content: "";
  background: url(../images/vison-vector2.png) no-repeat scroll 0 0;
  position: absolute;
  right: 20%;
  bottom: 0px;
  width: 313px;
  height: 153px;
  z-index: 1;
}
.homeVisionMisson {
  position: relative;
}
.homeVisionMisson::before {
  content: "";
  background: url(../images/vison-vector3.png) no-repeat scroll 0 0;
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 195px;
  height: 144px;
  z-index: 1;
}
.homeVisionMisson::after {
  content: "";
  background: url(../images/vison-vector4.png) no-repeat scroll 0 0;
  position: absolute;
  right: 15px;
  top: 0px;
  width: 334px;
  height: 260px;
  z-index: 0;
}
/* social-icons */

/* ================================================== */

.social-icons {
  display: inline-block;
}
.subfooter .social-icons {
  display: inline-flex;
  align-items: center;
}

.social-icons i {
  text-shadow: none;
  color: #fff;
  padding: 12px 10px 8px 10px;
  width: 34px;
  height: 34px;
  text-align: center;
  font-size: 20px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin: 0 3px 0 3px;
  line-height: 12px;
}

.social-icons i.fa-google-plus {
  padding: 12px 14px 10px 6px;
}

.social-icons i:hover {
  color: #eb7d4a;
}

.social-icons.big i {
  font-size: 26px;
  width: auto;
  height: auto;
  margin: 0 15px 0 15px;
}

.social-icons.big i:hover {
  background: none;
}

/*
.social-icons .fa-facebook{background:#3e5a9b;}
.social-icons .fa-twitter{background:#55aded;}
.social-icons .fa-google-plus{background:#df4f37;}
.social-icons .fa-linkedin{background:#0075ad;}
.social-icons .fa-rss{background:#fb6506;}
.social-icons .fa-skype{background:#28A8EA;}
*/

.social-icons.big i {
  margin: 0 15px 0 15px;
}

.social-icons.big i:hover {
  background: none;
}

.social-icons.s1 i {
  border: none;
  font-size: 16px;
  margin: 3px;
}

.social-icons.s1:hover i {
  background: none;
}

.social-icons.s1 {
  line-height: 0.5;
  text-align: center;
}

.social-icons.s1 .inner {
  display: inline-block;
  position: relative;
}

.social-icons.s1 .inner:before,
.social-icons.s1 .inner:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  top: 0;
  width: 100%;
}

.social-icons.s1 .inner:before {
  right: 100%;
  margin-right: 15px;
  margin-top: 20px;
}

.social-icons.s1 .inner:after {
  left: 100%;
  margin-left: 15px;
  margin-top: 20px;
}

.col-right {
  display: inline-block;
  float: right;
  margin-top: 22px;
}

/*
    .social-icons .fa-facebook{ background:#305fb3; }
    .social-icons .fa-twitter{  background:#00d7d5; }
    .social-icons .fa-rss{ background:#fca600;  }
    .social-icons .fa-google-plus{ background:#d7482a;  }
    .social-icons .fa-skype{ background:#12c7ef;    }
    .social-icons .fa-dribbble{ background:#f06eaa; }
    */

/* tabs */

/* ================================================== */

/* accordion
------------------------------ */

.accordion {
  overflow: hidden;
}

.accordion-section-title {
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  cursor: poInter;
}

.accordion-section-title {
  font-family: var(--body-font);
  color: #111111;
  width: 100%;
  padding: 15px;
  display: inline-block;
  font-size: 16px;
  transition: all linear 0.5s;
  text-decoration: none;
  margin-bottom: 10px;
  border: solid 1px #efefef;
  font-weight: 600;
}

.accordion-section-title:before {
  font-family: "FontAwesome";
  content: "\f107";
  float: right;
  color: #fff;
  text-align: center;
  padding: 0 8px 0 8px;
  font-size: 15px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.accordion-section-title.active:before {
  content: "\f106";
  float: right;
}

.accordion-section-title.active {
  margin-bottom: 0px;
  text-decoration: none;
  color: #fff !important;
}

.accordion-section-title:hover {
  text-decoration: none;
}

.accordion-section-content {
  padding: 15px;
  padding-bottom: 5px;
  display: none;
  margin-bottom: 10px;
}

/* bs */

.accordion-item h2 {
  line-height: 24px;
  display: block;
}

.expand-list .expand-custom:nth-child(even) {
  background: #ffffff;
}

.expand-custom {
  background: #ffffff;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 3px;
  -webkit-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
}

.expand-custom h4 {
  margin: 0;
  margin-bottom: 5px;
}

.expand-custom p {
  margin: 0;
}

.expand-custom .table {
  display: table;
}

.expand-custom .toggle {
  display: block;
  margin-top: 10px;
  float: right;
  cursor: poInter;
}

.rtl .expand-custom .toggle {
  float: left;
}

.expand-custom .toggle:before {
  font-family: "FontAwesome";
  content: "\f107";
  font-size: 32px;
}

.expand-custom .toggle.clicked:before {
  content: "\f106";
}

.expand-custom .c1,
.expand-custom .c2,
.expand-custom .c3 {
  display: table-cell;
  vertical-align: middle;
}

.expand-custom .c1,
.expand-custom .c2 {
  padding-right: 30px;
}

.expand-custom .c1 img {
  width: 80px;
}

.expand-custom .c1 {
  width: 10%;
}

.expand-custom .c2 {
  width: 80%;
}

.expand-custom .c3 {
  width: 10%;
}

.expand-custom .details {
  display: none;
  margin-top: 30px;
}

.expand-custom .box-custom {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.de_tab .de_nav {
  overflow: hidden;
  padding-left: 0;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.de_tab .de_nav li {
  float: left;
  list-style: none;
  background: #eee;
  margin-right: 10px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.d_coll .de_tab .de_nav {
  text-align: center;
}

.d_coll .de_tab .de_nav li {
  float: none;
  display: inline-block;
  margin-right: 5px;
}

.de_tab .de_nav li span {
  border: solid 1px rgba(0, 0, 0, 0.2);
  display: block;
  text-decoration: none;
  color: #646464;
  font-size: 15px;
  cursor: poInter;
}
.de_nav.nav-tabs {
  border-bottom: 0;
}
.de_nav.nav-tabs .nav-link {
  color: rgba(255, 255, 255, 0.5);
  background: #111a6e;
  border: solid 0px rgba(255, 255, 255, 0.1);
  border-radius: 5px !important;
  margin-right: 10px;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}
.de_nav.nav-tabs .nav-link span {
  line-height: inherit;
}
.createNavTab a.nav-link {
  color: rgba(255, 255, 255, 0.3);
  background: #150523;
  border: solid 1px rgba(255, 255, 255, 0.1);
  margin-right: 10px;
  margin-bottom: 0 !important;
  padding: 0;
}
.createNavTab a.nav-link.active {
  border: solid 0px #ef780a !important;
}
.de_nav.nav-tabs .nav-link.active i {
  color: #ffffff;
}
.createNavTab a.nav-link span {
  color: rgba(255, 255, 255, 0.6);
}
.createNavTab a.nav-link.active span {
  color: #ffffff;
}
.de_tab.tab_methods .de_nav li {
  background: none;
  display: inline-block;
  float: none;
  margin: 0 10px 0 0;
}

.de_tab.tab_methods .de_nav li span {
  padding: 20px;
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--body-font);
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  border-radius: 12px;
  min-width: 150px;
}

.createNavTab a span {
  padding: 20px;
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--body-font);
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  border-radius: 12px;
  min-width: 150px;
}
.dark-scheme .de_tab.tab_methods .de_nav li span {
  color: rgba(255, 255, 255, 0.6);
}

.dark-scheme .de_tab.tab_methods .de_nav li.active span {
  color: #ffffff;
}

.de_tab.tab_methods .de_nav li span i {
  display: block;
  font-size: 30px;
  margin-bottom: 10px;
}

.createNavTab a i {
  display: block;
  font-size: 30px;
  margin-bottom: 10px;
}

.de_tab.tab_methods.style-2 .de_nav li span {
  background: none;
  border-radius: 0px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  font-size: 16px;
  color: #fff;
}

.de_tab.tab_methods.style-2.light .de_nav li span {
  background: #eee;
  color: #707070;
}

.de_tab.tab_methods.style-2 .de_nav li.active span {
  border: solid 2px rgba(255, 255, 255, 0.8);
  color: #222;
}

.de_tab.tab_methods .de_nav li .v-border {
  height: 60px;
  border-left: solid 1px rgba(255, 255, 255, 0.3);
  display: inline-block;
  margin-bottom: -5px;
}

.de_tab.tab_methods.dark .de_nav li .v-border {
  border-left: solid 1px rgba(0, 0, 0, 0.3);
}

.de_tab .de_nav li span {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.de_tab .de_tab_content {
  padding-top: 30px;
}

.de_tab.tab_methods .de_tab_content {
  background: 0;
  padding: 0;
  border: none;
  padding-top: 20px;
}

.de_tab.tab_methods.dark .de_tab_content {
  border-top: solid 1px rgba(0, 0, 0, 0.4);
}

.de_tab .de_nav li.active span {
  background: #fff;
  color: #222;
}

.de_tab .de_nav li.active span .v-border {
  display: none;
}

.de_tab .de_nav li.active span {
  background: #27282b;
}

.de_tab.tab_methods .de_nav li.active span {
  background: none;
}

.de_tab.tab_methods.dark .de_nav li.active span {
  background: none;
  color: #333;
  border: solid 1px rgba(0, 0, 0, 0.8);
}

/* new added */

.de_tab.tab_methods.tab_6 .de_nav li span {
  padding: 20px 0 20px 0;
  font-size: 14px;
  line-height: 70px;
  width: 120px;
  height: 120px;
}

.de_tab.tab_methods.style-2 .de_nav li .arrow {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-top: 0px solid transparent;
  margin: 0 auto;
  margin-bottom: 20px;
}

.de_tab.tab_methods.style-2 .de_nav li.active .arrow {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid;
  margin: 0 auto;
}

.de_tab.tab_methods.style-2 .de_nav li {
  margin: 5px;
  display: inline-block;
}

.de_tab.tab_methods.style-2 .de_nav li span {
  font-family: var(--body-font);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0px;
  font-size: 16px;
  font-weight: lighter;
  color: #fff;
  opacity: 0.8;
  width: 120px;
  height: 100px;
  border: none;
  padding: 20px;
  border-radius: 300px;
  -moz-border-radius: 300px;
  -webkit-border-radius: 300px;
  -webkit-border-radius: 300px;
}

.de_tab.tab_methods.style-2 .de_nav li i {
  display: block;
  font-size: 36px;
  margin-bottom: 10px;
}

.de_tab.tab_methods.style-2.sub-style-2 .de_nav li span {
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  opacity: 0.8;
}

.de_tab.tab_methods.style-2 .de_nav li.active span {
  color: #222;
  opacity: 1;
  border: none;
}

.de_tab.tab_methods.style-2 .de_nav li span:hover {
  border: none;
}

.de_tab.tab_methods.style-2 .de_tab_content {
  border: none;
}

.de_tab.tab_simple .de_nav li {
  min-width: 80px;
  text-align: center;
}

.de_tab.tab_simple .de_nav li span {
  padding: 8px 5px;
  border: none;
}

.de_nav.nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background: #150523;
  border: solid 0px rgba(255, 255, 255, 0.1);
}
.de_nav.nav-tabs .nav-link.active {
  background: #fc943e;
  color: #ffffff;
}
.detailBg {
  background: #302089;
  padding: 25px;
  border-radius: 10px;
  border: 1px solid #302089;
}
/* tab de_light */

.de_light .de_tab.tab_style_2 .de_tab_content {
  border: none;
  background: none;
  padding: 40px 0 0 0;
  border-top: solid 1px #ddd;
}

.text-light .de_tab.tab_style_2 .de_tab_content {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}

.de_light .de_tab.tab_style_2 .de_nav {
}

.de_light .de_tab.tab_style_2 .de_nav li {
  float: none;
  list-style: none;
  background: none;
  margin: 0 5px 0 5px;
  text-align: center;
  display: inline-block;
}

.de_light .de_tab.tab_style_2 .de_nav li img {
  width: auto;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.7;
}

.de_light .de_tab.tab_style_2 .de_nav li.active img {
  width: auto;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.de_light .de_tab.tab_style_2 .de_nav li span {
  background: none;
  display: block;
  text-decoration: none;
  font-size: 14px;
  cursor: poInter;
  border: none;
}

.de_light .de_tab.tab_style_2 .de_nav li.active span {
  border-bottom: solid 6px;
  color: #333;
  background: none;
}

.text-light .de_tab.tab_style_2 .de_nav li.active span {
  color: #fff;
}

/* tab de_light */

.de_light .de_tab.tab_style_3 .de_tab_content {
  border: none;
  background: none;
  padding: 40px 0 0 0;
  border-top: solid 1px #ddd;
}

.text-light .de_tab.tab_style_3 .de_tab_content {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}

.de_light .de_tab.tab_style_3 .de_nav {
  text-align: center;
  background: #eee;
  display: inline-block;
  border-radius: 60px;
  margin-bottom: 30px;
  padding: 0;
}

.de_light .de_tab.tab_style_3 .de_nav li {
  float: none;
  list-style: none;
  background: none;
  text-align: center;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.de_light .de_tab.tab_style_3 .de_nav li img {
  width: auto;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.7;
}

.de_light .de_tab.tab_style_3 .de_nav li.active img {
  width: auto;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.de_light .de_tab.tab_style_3 .de_nav li span {
  background: none;
  display: block;
  text-decoration: none;
  font-size: 14px;
  cursor: poInter;
  border: none;
  padding: 5px 30px 5px 30px;
  min-width: 150px;
}

.de_light .de_tab.tab_style_3 .de_nav li:last-child span {
  border-radius: 0 60px 60px 0;
}

.de_light .de_tab.tab_style_3 .de_nav li:first-child span {
  border-radius: 60px 0 0 60px;
}

.de_light .de_tab.tab_style_3 .de_nav li span:hover {
  background: #ddd;
}

.de_light .de_tab.tab_style_3 .de_nav li.active span {
  background: #eee;
  color: #fff;
}

.text-light .de_tab.tab_style_3 .de_nav li.active span {
  color: #fff;
}

/* tab de_light */

.de_tab.tab_style_4 .de_tab_content {
  border: none;
  background: none;
  padding: 0;
  border-top: none;
}

.text-light .de_tab.tab_style_4 .de_tab_content {
  border-top: none;
}

.de_tab.tab_style_4 .de_nav {
  text-align: center;
  background: #eee;
  display: inline-block;
  border-radius: 60px;
  margin-bottom: 30px;
  padding: 0;
  border: solid 2px #333;
}

.de_tab.tab_style_4 .de_nav li img {
  width: auto;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.7;
}

.de_tab.tab_style_4 .de_nav li.active img {
  width: auto;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.de_tab.tab_style_4 .de_nav li {
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  cursor: poInter;
  border: none;
  padding: 15px 30px 15px 30px;
  min-width: 150px;
  margin: 0;
  border-right: solid 2px #333;
}

.de_tab.tab_style_4 .de_nav li span {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  width: auto;
  display: inline-block;
}

.de_tab.tab_style_4 .de_nav li:last-child {
  border-radius: 0 60px 60px 0;
  border-right: none;
}

.de_tab.tab_style_4 .de_nav li:first-child {
  border-radius: 60px 0 0 60px;
}

.de_tab.tab_style_4 .de_nav li:hover {
  background: #ddd;
}

.de_tab.tab_style_4 .de_nav li.active,
.de_tab.tab_style_4 .de_nav li.active span {
  background: #333;
  color: #fff;
}

.text-light .de_tab.tab_style_4 .de_nav li.active {
  color: #fff;
}

.de_tab.tab_style_4 .de_nav li.active h4,
.de_tab.tab_style_4 .de_nav li.active h4,
.de_tab.tab_style_4 .de_nav li.active h4 span {
  color: #fff;
}

.tab_style_4 h4,
.de_tab.tab_style_4 .de_nav li span {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}

.tab_style_4 h4 {
  margin: 0;
  font-size: 12px;
}

.de_tab .de_tab_content.tc_style-1 {
  background: none;
  padding: 0;
  padding-top: 30px;
}

.tab-small-post ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tab-small-post ul li {
  min-height: 60px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: solid 1px #eeeeee;
}

.tab-small-post ul li {
  border-bottom: solid 1px #202220;
}

.tab-small-post ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.tab-small-post ul li img {
  position: absolute;
}

.tab-small-post ul li span {
  display: block;
  padding-left: 64px;
}

.tab-small-post ul li span.post-date {
  font-size: 11px;
}

.de_tab.timeline.nav_4 li {
  display: inline-block;
  text-align: center;
  background: none;
  width: 24%;
}

.de_tab.timeline.nav_4 li span {
  background: none;
  border: none;
  margin-top: 20px;
  font-family: var(--body-font);
  font-size: 14px;

  letter-spacing: 2px;
}

.de_tab.timeline.nav_4 .dot {
  display: block;
  width: 8px;
  height: 8px;
  background: #fff;
  opacity: 1;
  margin: 0 auto;
  border-radius: 8px;
  margin-top: 1px;
}

.de_tab.timeline.nav_4 .dot:before,
.de_tab.timeline.nav_4 .dot:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  top: 0;
  width: 24%;
}

.de_tab.timeline.nav_4 li:last-child .dot:before,
.de_tab.timeline.nav_4 li:last-child .dot:after {
  border-bottom: none;
}

.de_tab.timeline li .num {
  font-size: 20px;
}

.de_tab.timeline .de_tab_content {
  background: none;
  border: none;
  padding-top: 30px;
}

.de_tab .navigation_arrow {
  text-align: center;
}

.btn-left,
.btn-right {
  background: none;
  border: solid 1px rgba(255, 255, 255, 0.2);
  padding: 10px 20px 10px 20px;
  margin: 5px;
  font-family: var(--body-font);

  letter-spacing: 5px;
}

.btn-right:after {
  font-family: FontAwesome;
  content: "\f054";
  padding-left: 10px;
}

.btn-left:before {
  font-family: FontAwesome;
  content: "\f053";
  padding-right: 10px;
}

a.btn-left,
a.btn-right {
  text-decoration: none;
}

/* team
    /* ================================================== */

.team {
  margin-bottom: 30px;
  text-align: center;
}

.team h5 {
  margin-bottom: 0;
}

.team_photo {
  margin-bottom: 10px;
}

.team img.team-pic {
  width: 100%;
  height: auto;
}

.pic-team img {
  width: 100%;
  height: auto;
  width: 150px;
  height: 150px;
  position: absolute;
}

.pic-team img.pic-hover {
  z-index: 100;
  display: none;
}

.team .team_desc {
  font-style: italic;
  margin-bottom: 10px;
}

.team .sb-icons {
  padding: 10px 0 0 0;
}

.team .sb-icons img {
  margin-right: 5px;
}

.team .columns {
  margin-bottom: 30px;
}

.team-list {
  min-height: 180px;
  margin-bottom: 40px;
}

.team-list .team-pic {
  width: 150px;
  height: 150px;
  position: absolute;
}

.team-list h5 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 14px;
}

.team-list.team_desc {
  font-style: italic;
  margin-bottom: 10px;
}

.team-list .small-border {
  float: none;
  margin: 15px 0 15px 0;
  background: #ddd;
  width: 50px;
}

.team-list .social {
  width: 150px;
  margin-top: 170px;
  text-align: center;
  position: absolute;
}

.team-list .social a {
  display: inline-block;
}

.team-list .info {
  padding-left: 170px;
}

.de-team-list {
  overflow: hidden;
}

.de-team-list .team-desc {
  padding: 20px;
  padding-bottom: 0;
  position: absolute;
  z-index: 100;
  top: 0;
  background: #111;
  color: #ccc;
}

.de-team-list .team-desc h4 {
  color: #fff;
}

.de-team-list .social {
}

.de-team-list .social a {
  display: inline-block;
  padding: 0 5px 0 5px;
}

.de-team-list p.lead {
  font-size: 14px;
}

.de-team-list .small-border {
  float: none;
  margin: 15px 0 15px 0;
  background: #ddd;
  width: 50px;
}

.de-team-list .social a:hover {
  color: #fff;
}

/* testimonial
    /* ================================================== */

.de_testi {
  display: block;
  margin-bottom: 10px;
}

.de_testi blockquote {
  margin: 0;
  border: none;
  padding: 20px 30px 30px 80px;
  background: rgba(var(--secondary-color-rgb), 0.1);
  font-style: normal;
  line-height: 1.6em;
  font-weight: 500;
}

.de_testi blockquote p {
  margin-top: 10px;
}

.de_testi blockquote:before {
  font-family: FontAwesome;
  content: "\f10d";
  padding-bottom: 10px;
  font-size: 20px;
  display: inline-block;
  padding: 10px 20px 10px 20px;
  font-style: normal;
  background: #000;
  position: absolute;
  left: 15px;
  font-weight: normal;
}

.de_testi blockquote {
  color: #eceff3;
}

.de_testi blockquote,
.de_testi blockquote p {
  font-size: 20px;
  line-height: 1.7em;
  font-weight: normal;
}

.de_testi_by {
  font-style: normal;
  font-size: 12px;
  display: flex;
  margin-top: -20px;
}

.de_testi_by span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.de_testi_by img {
  width: 50px !important;
  height: auto;
  margin-right: 10px;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
}

.de_testi_pic {
  float: left;
  padding-right: 15px;
}

.de_testi_company {
  padding-top: 20px;
}

.de_testi.opt-2 blockquote {
  display: block;
  font-family: var(--body-font);
  border: none;
  color: #606060;
  font-weight: 300;
  padding: 30px 40px 30px 40px;
  background: rgba(var(--secondary-color-rgb), 0.1);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.de_testi.opt-2.no-bg blockquote {
  background: none;
}

.de_testi.opt-2 blockquote:before {
  font-family: "FontAwesome";
  content: "\f10d";
  display: block;
  color: rgba(0, 0, 0, 0.1);
  z-index: 100;
  font-size: 20px;
  background: none;
  left: 20px;
}

.de_testi.opt-2 i {
  font-size: 36px;
}

.de_testi.opt-2.review blockquote {
  padding: 50px;
}

.de_testi.opt-2.review blockquote:before {
  display: none;
}

.de_testi.opt-2.review .p-rating {
  font-size: 14px;
}

.de_testi.opt-2.review p {
  padding-top: 0;
}

.de_testi.opt-2.review h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

.text-light .de_testi.opt-2 blockquote {
  color: #fff;
  background: rgba(20, 20, 20, 0.5);
}

.de_testi.opt-2 blockquote p {
  font-family: var(--body-font);
  font-size: 16px;
  padding: 40px 0 0 0;
  font-weight: 400;
  line-height: 1.7em;
}

.de_testi.opt-2 .de_testi_by {
  font-size: 14px;
  font-weight: normal;
}

.de_testi.opt-3 blockquote {
  background: none;
  color: #555;
  padding-top: 0;
  font-weight: 400;
}

.de_testi.opt-3 blockquote p {
  font-size: 14px;
}

.de_testi.opt-3 blockquote:before {
  color: #fff;
}

.de_testi.opt-3 .de_testi_by {
  font-size: 14px;
  color: #000;
}

.de_testi.de-border blockquote {
  border: solid 5px rgba(0, 0, 0, 0.05);
}

#testimonial-masonry .item {
  margin-bottom: 30px;
}

.testimonial-list p {
  font-style: italic;
}

.testimonial-list,
.testimonial-list li {
  list-style: none;
  padding: 0px;
  font-size: 16px;
  line-height: 1.7em;
  padding-left: 30px;
}

.testimonial-list span {
  font-weight: bold;
  display: block;
  margin-top: 20px;
  font-size: 14px;
}

.testimonial-list.big-font li {
  font-size: 28px;
}

.testimonial-list:before {
  font-family: FontAwesome;
  content: "\f10d";
  font-size: 40px;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  margin-left: -30px;
}

.testimonial-list.style-2,
.testimonial-list.style-2 li {
  padding-left: 0px;
}

.testimonial-list.style-2 {
  text-align: center;
}

.testimonial-list.style-2:before {
  margin: 0;
  position: static;
}

.testimonial-list.style-2 span {
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
}

/* timeline */

/* timeline */

.timeline {
  position: relative;
  padding: 10px;
}

.timeline:before {
  content: "";
  position: absolute;
  top: 0;
  left: 127px;
  height: 100%;
  width: 1px;
  background: #eee;
}

.text-light .timeline:before {
  background: rgba(255, 255, 255, 0.1);
}

.timeline .tl-block {
  position: relative;
  margin-top: 10px;
}

.timeline .tl-block:after {
  content: " ";
  clear: both;
  display: block;
}

.timeline .tl-block .tl-time {
  float: left;
  padding: 10px 20px 0px 20px;
  margin-top: -15px;
  border: solid 1px #ddd;
}

.timeline .tl-block .tl-time:after {
  width: 50px;
  height: 1px;
  background: #ddd;
}

.timeline .tl-block .tl-bar {
  float: left;
  width: 5%;
  position: absolute;
  margin-left: 114px;
}

.timeline .tl-block .tl-line {
  background: #333;
  margin-left: 10px;
  border-radius: 10px;
  width: 8px;
  height: 8px;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
}

.timeline .tl-message {
  border-radius: 3px;
  float: left;
  margin-left: 150px;
  top: 0;
  margin-top: -50px;
  margin-bottom: 30px;
}

.timeline .tl-block .tl-message:after {
  display: block;
  content: " ";
  clear: both;
}

.timeline .tl-block .tl-message .tl-main {
  float: left;
}

.timeline .tl-block .tl-message .tl-main .tl-title {
}

.timeline .tl-block .tl-message .tl-main .tl-content {
}

.timeline .tl-block .tl-message .tl-main .tl-content label {
  font-weight: bold;
  display: inline-block;
  width: 80px;
}

.timeline .tl-block .tl-message .tl-main .tl-content span {
}

.timeline .tl-block .tl-message h4 {
  text-transform: none;
}

.timeline .tl-block .tl-time h4 {
  margin: 0 0 10px 0;
}

.timeline .tl-block .tl-main h4 {
  margin-top: -24px;
}

.timeline.exp:before {
  left: 214px;
}

.timeline.exp .tl-message {
  margin-left: 250px;
}

.timeline.exp .tl-block .tl-bar {
  margin-left: 200px;
}

/* timeline */

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 1px;
  background-color: #ddd;
  left: 50%;
}

.timeline > li {
  position: relative;
  margin-top: -50px;
}

.timeline > li:first-child {
  position: relative;
  margin-top: 0px;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  /* border: 1px solid #d4d4d4; */
  border-radius: 2px;
  position: relative;
  background: rgba(var(--secondary-color-rgb), 0.1);
  overflow: hidden;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -14px;
  display: inline-block;
  border-top: 15px solid transparent;
  /* border-left: 15px solid #ccc;
  border-right: 0 solid #ccc; */
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 43%;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #f5f5f5;
  border-right: 0 solid #f5f5f5;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 16px;
  height: 16px;
  line-height: 40px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  margin-left: -7px;
  background-color: #fff;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 15px;
  left: -14px;
  right: auto;
}

.timeline-badge.primary {
  background-color: #2e6da4 !important;
}

.timeline-badge.success {
  background-color: #3f903f !important;
}

.timeline-badge.warning {
  background-color: #f0ad4e !important;
}

.timeline-badge.danger {
  background-color: #d9534f !important;
}

.timeline-badge.info {
  background-color: #5bc0de !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.timeline-heading img {
  float: right;
  position: absolute;
  top: 30px;
  right: 30px;
}

.timeline .timeline-story {
  position: relative;
  padding: 40px;
}

.timeline-inverted .timeline-story {
  position: relative;
  padding: 40px;
  padding-left: 50px;
}

.timeline .wm {
  font-size: 200px;
  position: absolute;
  bottom: 20px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.timeline h5 {
  background: #bbb;
  color: #ffffff;
  padding: 5px 20px 3px 20px;
  display: inline-block;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  margin-bottom: 15px;
  float: right;
}

.timeline-inverted h5 {
  float: left;
}

/* Timeline */
.container-timeline {
  position: relative;
}

.container-timeline ul {
  margin: 0;
  padding: 0;
  padding-left: 20px;
  display: inline-block;
  counter-reset: wa-process-counter;
}

.container-timeline ul li {
  list-style: none;
  margin: 0;
  border-left: 1px solid green;
  padding: 0 0 30px 40px;
  position: relative;
  counter-increment: wa-process-counter;
}

.container-timeline ul li:last-child {
  border: 0;
}

.container-timeline ul li::before {
  position: absolute;
  left: -21px;
  top: 0;
  content: counter(wa-process-counter);
  border: 1px solid green;
  border-radius: 5px;
  background: white;
  height: 41px;
  width: 41px;
  text-align: center;
  line-height: 41px;
}

.timelines h2 {
  text-align: center;
  color: #fff;
  font-weight: 600;
  margin-bottom: 40px;
  font-size: 32px;
}

.d-flex-2 {
  display: flex;
  align-items: center;
}

.timeline-area {
  padding: 80px 0;
}

.all-timelines {
  position: relative;
}

.timelines h2 {
  text-align: center;
  color: #fff;
  font-weight: 600;
  margin-bottom: 40px;
}

.all-timelines::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 2px;
  background: #efa22f;
  top: 20px;
}

.single-timeline {
  margin-bottom: 22px;
}

.timeline-blank {
  width: 50%;
}

.timeline-text {
  width: 50%;
  padding-left: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.timeline-text h6 {
  color: #f0f1f2;
  font-weight: 900;
  display: inline-block;
  font-size: 1rem;
}

.timeline-text span {
  color: #f0f1f2;
  display: block;
  width: 100%;
}

.single-timeline:nth-child(even) .timeline-text span {
  text-align: right;
}

.t-circle {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: -6px;
  background: #efa22f;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.single-timeline:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.single-timeline:nth-child(even) .t-circle {
  right: -6px;
  left: unset;
}

.single-timeline:nth-child(even) .timeline-text {
  padding-left: 0;
  padding-right: 30px;
  text-align: right;
}

@media all and (max-width: 768px) {
  .all-timelines::before {
    right: unset;
    top: 0;
  }
  .subfooter {
    text-align: center;
  }
  .footerAbout {
    text-align: center;
  }
  .mainFooter .widget {
    text-align: center;
  }
  .mainFooter .widget h5 {
    text-align: center;
  }
  .mainFooter .widget ul li a {
    text-align: center;
    display: block;
  }
  .single-timeline:nth-child(2n) .timeline-text {
    padding-left: 30px;
    padding-right: 0;
    text-align: left;
  }

  .single-timeline:nth-child(2n) .t-circle {
    left: -6px;
    right: unset;
  }

  .timeline-blank {
    display: none;
  }

  .timeline-text {
    width: 100%;
  }

  .single-timeline:nth-child(even) .timeline-text span {
    text-align: left !important;
  }
}

@media all and (max-width: 360px) {
  .all-timelines::before {
    top: 32px;
  }
}

/* css attributes */

/* margin top */

.mt0 {
  margin-top: 0;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mt80 {
  margin-top: 80px;
}

.mt90 {
  margin-top: 90px;
}

.mt100 {
  margin-top: 100px;
}

/* margin bottom */

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.ml0 {
  margin-left: 0;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.ml60 {
  margin-left: 60px;
}

.ml70 {
  margin-left: 70px;
}

.ml80 {
  margin-left: 80px;
}

.ml90 {
  margin-left: 90px;
}

.ml100 {
  margin-left: 100px;
}

.mr0 {
  margin-right: 0;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

.mr60 {
  margin-right: 60px;
}

.mr70 {
  margin-right: 70px;
}

.mr80 {
  margin-right: 80px;
}

.mr90 {
  margin-right: 90px;
}

.mr100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: -100px;
}

.padding10 {
  padding: 10px;
}

.padding20 {
  padding: 20px;
}

.padding30 {
  padding: 30px;
}

.padding40 {
  padding: 40px;
}

.padding50 {
  padding: 50px;
}

.padding60 {
  padding: 60px;
}

.padding70 {
  padding: 70px;
}

.padding80 {
  padding: 80px;
}

.padding100 {
  padding: 100px;
}

.pt0 {
  padding-top: 0;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pt30 {
  padding-top: 30px;
}

.pt40 {
  padding-top: 40px;
}

.pt50 {
  padding-top: 50px;
}

.pt60 {
  padding-top: 60px;
}

.pt70 {
  padding-top: 70px;
}

.pt80 {
  padding-top: 80px;
}

.pb0 {
  padding-bottom: 0;
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb80 {
  padding-bottom: 80px;
}

.pl130 {
  padding-left: 130px;
}

.pr20 {
  padding-right: 20px;
}

/* margin top */

.mt-10 {
  margin-top: -10px;
}

.mt-20 {
  margin-top: -20px;
}

.mt-30 {
  margin-top: -30px;
}

.mt-40 {
  margin-top: -40px;
}

.mt-50 {
  margin-top: -50px;
}

.mt-60 {
  margin-top: -60px;
}

.mt-70 {
  margin-top: -70px;
}

.mt-80 {
  margin-top: -80px;
}

.mt-90 {
  margin-top: -90px;
}

.mt-100 {
  margin-top: -100px;
}

.absolute {
  position: absolute;
  z-index: 1;
}

.relative {
  position: relative;
  z-index: 1;
}

.z1000 {
  z-index: 1000;
}

.overflow-hidden {
  overflow: hidden;
}

.width100 {
  width: 100%;
}

.border1 {
  border-width: 1px;
}

.bordertop {
  border-top: solid 1px #eee;
}

.borderbottom {
  border-bottom: solid 1px #eee;
}

.pos-top {
  position: relative;
  z-index: 1000;
}

/* ================================================== */

/* anim */

/* ================================================== */

header,
de_tab .de_nav li span,
.feature-box-small-icon i,
a.btn-line:after,
.de_count,
.social-icons a i,
.de_tab.tab_methods .de_nav li span,
.de_tab.tab_methods .de_nav li span:hover,
.de-gallery .overlay .icon i,
.de-gallery .overlay .icon i:hover,
.product img,
.product img:hover,
.mfp-close,
.mfp-close:hover,
.pic-hover .bg-overlay,
.pic-hover:hover > .bg-overlay,
i.btn-action-hide,
.pic-hover.hover-scale img,
.text-rotate-wrap,
.text-rotate-wrap .text-item,
.overlay-v,
.carousel-item .pf_text .title,
.carousel-item:hover .pf_text .title,
.carousel-item .pf_text .title .type,
.carousel-item:hover .pf_text .title .type,
.owl-slider-nav .next,
.owl-slider-nav .prev,
#back-to-top.show,
#back-to-top.hide,
.social-icons-sm i,
.social-icons-sm i:hover,
.overlay-v i,
.overlay-v i:hover,
.de_tab .de_nav li span,
#popup-box.popup-show,
#popup-box.popup-hide,
.form-underline input[type="text"],
.form-underline textarea,
.form-underline input[type="email"],
.form-underline select,
.btn-rsvp,
.d-btn-close,
.btn-main,
.overlay-v span,
.de_tab.tab_style_4 .de_nav li,
.form-subscribe input[type="text"],
.feature-box .inner,
.feature-box:hover .inner,
.feature-box .inner i,
.feature-box:hover .inner i,
.error_input,
.de_tab.tab_methods.style-2 .de_nav li.active .arrow,
.owl-item.active.center div blockquote,
.mask *,
.mask .cover,
.mask:hover .cover,
.mask img,
.mask:hover img,
.accordion .content,
a.btn-border,
.f_box span,
.f_box img,
.f_box.s2:hover img,
.mask .cover p,
.feature-box-type-1:hover i,
.feature-box-type-2 i,
#contact_form input,
#form_subscribe input[type="text"],
.table-pricing tbody tr,
#selector .dark-mode,
#selector .dark-mode:hover,
.feature-box.f-boxed,
.feature-box.f-boxed:hover,
.fp-wrap,
.fp-wrap div,
.fp-wrap img,
.feature-box-type-2,
.feature-box.style-3 i,
.feature-box.style-3 h4,
.logo-carousel img,
.f-box,
.f-box *,
.fp-wrap *,
.d-gallery-item *,
.de-box-image *,
.de-image-hover *,
#selector *,
a.a-underline *,
.nft_coll *,
.nft__item,
.activity-filter *,
.dropdown *,
.icon-box.style-2 *,
.nft__item img,
#quick_search,
.form-border *,
.nft_pic *,
.d-carousel * {
  -o-transition: 0.7s;
  -ms-transition: 0.7s;
  -moz-transition: 0.7s;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  outline: none;
}

a.btn-fx:after,
a.btn-fx:hover:after,
a.btn-fx:before,
a.btn-fx:hover:before,
#mainmenu li,
#mainmenu li ul,
.widget-post li .post-date,
.author_list_pp img,
.items_filter *,
.owl-prev,
.owl-next {
  -o-transition: 0.2s ease;
  -ms-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  outline: none;
}
.author_list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.author_list .author_list_pp img {
  height: 50px !important;
  background: transparent;
  box-shadow: none;
}
.item_author .author_list_pp img {
  height: 50px !important;
  background: transparent;
  box-shadow: none;
}
.nft__item .author_list_pp img {
  width: 50px !important;
  height: 50px !important;
  background: transparent;
  box-shadow: none;
}
.nft_coll_pp img {
  width: 60px !important;
  height: 60px !important;
  background: transparent;
  box-shadow: none;
}
.feature-box:hover i.wm {
  -o-transition: 2s ease;
  -ms-transition: 2s ease;
  -moz-transition: 2s ease;
  -webkit-transition: 2s ease;
  transition: 2s ease;
  outline: none;
}

header.header-mobile #mainmenu > li > span {
  -o-transition: none;
  -ms-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
  outline: none;
}

.nft_pic:hover * {
  -o-transition: 3s;
  -ms-transition: 3s;
  -moz-transition: 3s;
  -webkit-transition: 3s;
  transition: 3s;
  outline: none;
}

@-webkit-keyframes scaleIn {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

@keyframes scaleIn {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

.scaleIn {
  -webkit-animation-name: scaleIn;
  animation-name: scaleIn;
}

@-webkit-keyframes scaleInFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

@keyframes scaleInFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

.scaleInFade {
  -webkit-animation-name: scaleInFade;
  animation-name: scaleInFade;
}

@-webkit-keyframes scaleOutFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

@keyframes scaleOutFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

.scaleOutFade {
  -webkit-animation-name: scaleOutFade;
  animation-name: scaleOutFade;
}

@-webkit-keyframes widthInFade {
  from {
    opacity: 0;
    width: 1px;
  }

  100% {
    opacity: 1;
    width: 100px;
  }
}

@keyframes widthInFade {
  from {
    opacity: 0;
    width: 1px;
  }

  100% {
    opacity: 1;
    width: 100px;
  }
}

.widthInFade {
  -webkit-animation-name: widthInFade;
  animation-name: widthInFade;
}

/* ================================================== */

/* video */

/* ================================================== */

video,
object {
  display: inline-block;
  vertical-align: baseline;
  min-width: 13%;
  min-height: 100%;
}

.de-video-container {
  top: 0%;
  left: 0%;
  height: 500px;
  width: 100%;
  overflow: hidden;
}

.de-video-content {
  width: 100%;
  position: absolute;
  z-index: 10;
}

.de-video-overlay {
  position: absolute;
  width: 100%;
  min-height: 100%;
  background: rgba(20, 20, 20, 0.5);
}

.de-video-overlay.dotted {
  /*background: url(../images_02/dotted.png);*/
}

.video-fixed {
  width: 100%;
  position: fixed;
  top: 0;
}

/* youtube, vimeo */

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.de-video-container .btn-line:hover:after,
.de-video-container a.btn-line:hover:after,
.de-video-container a.btn-line.hover:after {
  color: #eceff3;
}

/* ================================================== */

/* map */

/* ================================================== */

/* --------------- map --------------- */

#map {
  width: 100%;
  height: 500px;
}

.map iframe {
  width: 100%;
  border: solid 1px #ccc;
  padding: 2px;
  background: #fff;
}

.map-container {
  width: 100%;
  height: 380px;
  overflow: hidden;
}

.map-container iframe {
  width: 100%;
}

/* ================================================== */

/* 1A1C26 to action */

/* ================================================== */

.call-to-action-box {
  background: #f6f6f6;
  border: solid 1px #ddd;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(242, 242, 242, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(242, 242, 242, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(242, 242, 242, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(242, 242, 242, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(242, 242, 242, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(242, 242, 242, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0);
}

.call-to-action-box {
  background: #27282b;
  border: solid 1px #202220;
}

.call-to-action-box .inner {
  padding: 20px 20px 20px 20px;
}

.call-to-action-box .text {
  margin-right: 20px;
  float: left;
}

.call-to-action-box .act {
  float: right;
}

.call-to-action-box h4 {
  margin: 0;
  margin-top: 10px;
}

.call-to-action-box .btn {
  color: #eceff3;
}

.call-to-action-box {
  background: #202220;
  color: #eceff3;
}

.call-to-action-boxh4 {
  color: #eceff3;
}

/* ================================================== */

/* overide bootstrap */

/* ================================================== */

.dark-scheme .modal-header,
.nav-tabs {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.dark-scheme .modal-content {
  background: #21273e;
}

.dark-scheme .modal-footer {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.dark-scheme .accordion-body {
  background: #21273e;
  border-color: #292f45;
}

.nav-tabs .nav-link {
  font-weight: 600;
}

.tab-default .tab-content {
  margin-top: 20px;
}

.container-fluid {
  padding: 0px;
  margin: 0px;
}

.btn-primary {
  text-shadow: none;
  border: none;
}

.btn.btn-primary:hover {
  background: #555;
}

.img-responsive {
  display: inline-block;
}

.img-shadow {
  -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
}

img.img-auto {
  width: auto;
  height: auto;
}

img.img-rounded {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.box-rounded {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.img-profile {
  border: solid 3px #fff;
}

.label {
  font-family: "Inter", Arial, Helvetica, sans-serif;
}

table thead {
  font-weight: bold;
}

p.lead {
  font-size: 18px;
  line-height: 1.7em;
  margin-top: 0;
  font-weight: 400;
}

p.lead.big {
  font-size: 18px;
}

p.sub {
  font-size: 14px;
}

.panel-group {
  opacity: 0.8;
}

.panel-heading,
.panel-body {
  background: #27282b;
  border: solid 1px #202220;
  border-radius: 0;
}

.panel-body {
  border-top: none;
}

.progress {
  background: #27282b;
}

.panel-heading h4 a {
  display: block;
}

.panel-heading h4 a:hover {
  color: #eceff3;
}

.panel-default,
.panel-collapse,
.panel-collapse,
.panel-group .panel {
  border: none;
  border-radius: 0;
}

.panel-heading {
  background: #222;
}

.table-bordered,
.table-bordered td {
  border: solid 1px #202220;
}

blockquote {
  font-family: var(--body-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;
  border-left: solid 3px #333333;
  padding-left: 30px;
  color: #606060;
}

.dark-scheme blockquote {
  color: #ffffff;
}

blockquote span {
  font-family: var(--body-font);
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  margin-top: 20px;
}

blockquote.s2 {
  border: none;
  text-align: left;
  background: #f2f2f2;
  padding: 30px 30px 30px 60px;
  margin-top: 20px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

blockquote.s2:before {
  font-family: "FontAwesome";
  content: "\f10e";
  font-style: normal;
  position: absolute;
  margin-left: -30px;
}

blockquote.s1 {
  padding: 0px;
}

blockquote.s1:before {
  font-family: "FontAwesome";
  content: "\f10d";
  display: block;
  color: rgba(0, 0, 0, 0.1);
  z-index: -1;
  font-size: 40px;
  top: 0;
  left: 0;
}

.collapsible-link::before {
  content: "";
  width: 14px;
  height: 2px;
  background: #333;
  position: absolute;
  top: calc(50% - 1px);
  right: 1rem;
  display: block;
  transition: all 0.3s;
}

/* Vertical line */
.collapsible-link::after {
  content: "";
  width: 2px;
  height: 14px;
  background: #333;
  position: absolute;
  top: calc(50% - 7px);
  right: calc(1rem + 6px);
  display: block;
  transition: all 0.3s;
}

.text-light .collapsible-link:before,
.text-light .collapsible-link:after {
  background: #ffffff;
}

.rtl .collapsible-link::before {
  right: auto;
  left: 0px;
}

.rtl .collapsible-link::after {
  right: auto;
  left: 6px;
}

.rtl .rtl-show {
  display: block;
}

.rtl .rtl-hide {
  display: none;
}

.collapsible-link[aria-expanded="true"]::after {
  transform: rotate(90deg) translateX(-1px);
}

.collapsible-link[aria-expanded="true"]::before {
  transform: rotate(180deg);
}

.collapsible-link.text-white {
  color: #ffffff;
}

.collapsible-link.text-white::after,
.collapsible-link.text-white::before {
  background: #ffffff;
}

.card h6 {
  font-weight: 500;
}

.card-header.text-light h6 {
  color: #ffffff;
}

.accordion-style-1 {
  overflow: visible;
}

.accordion-style-1 .card {
  margin-bottom: 20px;
  border: none;
  -webkit-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  overflow: hidden;
}

.phone-num-big i {
  font-size: 48px;
  display: block;
}

.pnb-text {
  display: block;
  font-size: 12px;
  font-weight: bold;

  letter-spacing: 5px;
}

.pnb-num {
  display: block;
  font-size: 36px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}

/* ======================================== */

/* toggle */

/* ======================================== */

.toggle-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.toggle-list li {
  margin-bottom: 10px;
  background: #fff;
  border: solid 1px #ddd;
  border-bottom: solid 3px #ddd;
}

.toggle-list li h2 {
  -moz-user-select: none;
  -html-user-select: none;
  user-select: none;
  color: #555;
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", Arial, Helvetica, sans-serifr;
  padding: 10px 20px 0px 60px;
  font-weight: 700;
  display: block;
  letter-spacing: normal;
}

.toggle-list li .acc_active:hover {
  color: #777;
  /*background: url(../images_02/icon-slide-plus.png) 20px center no-repeat;*/
  padding-left: 80px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.toggle-list li .acc_noactive:hover {
  color: #777;
  /*background: url(../images_02/icon-slide-min.png) 20px center no-repeat;*/
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.toggle-list .ac-content {
  display: none;
  padding: 20px;
  border-top: solid 1px #e5e5e5;
}

.acc_active {
  /*background: url(../images_02/icon-slide-plus.png) 20px center no-repeat;*/
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: 32px;
}

.acc_noactive {
  /*background: url(../images_02/icon-slide-min.png) 20px center no-repeat;*/
}

/* ================================================== */

/* image position */

/* ================================================== */

.img-left {
  float: left;
  margin: 5px 15px 15px 0;
}

.img-right {
  float: right;
  margin: 5px 0 15px 15px;
}

/* ================================================== */

/* flickr */

/* ================================================== */

#flickr-photo-stream img {
  width: 48px;
  height: auto;
  margin: 0 12px 12px 0;
  float: left;
}

#flickr-photo-stream a img {
  border: solid 2px #fff;
}

#flickr-photo-stream a:hover img {
  border: solid 2px #777777;
}

hr {
  border-top: solid 1px #555;
}

hr.blank {
  border: none;
  margin: 20px 0 20px 0;
}

/* ================================================== */

/* font awesome icons */

/* ================================================== */

i {
  display: inline-block;
}

i.large {
  font-size: 72px;
  text-align: center;
  display: inline-block;
  float: none;
}

i.medium {
  font-size: 20px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  float: none;
}

i.small {
  font-size: 12px;
  padding: 4px;
  text-align: center;
  display: inline-block;
  float: none;
}

i.circle-icon {
  border-radius: 75px;
  -moz-border-radius: 75px;
  -webkit-border-radius: 75px;
}

.fa-android {
  color: #469159;
}

.fa-apple {
  color: #0ec3f7;
}

/* extra ^ */

.de_center p {
  width: 100%;
}

.de_center .de_icon {
  float: none;
  margin: 0 auto;
}

/* ================================================== */

/* transition */

/* ================================================== */

a,
a:hover,
.test-column,
.test-column:hover {
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.de_contact_info {
  font-size: 40px;
  font-weight: bold;
}

.de_contact_info i {
  float: none;
  margin-right: 20px;
}

.center-div {
  margin: 0 auto;
}

/* ================================================== */

/* gallery */

/* ================================================== */

#gallery-carousel .item {
  text-align: center;
  font-size: 12px;
  width: 100%;
  cursor: e-resize;
}

.de_carousel .item {
  width: 100%;
  display: block;
}

#gallery-carousel .item img {
  width: 100%;
  height: auto;
}

#gallery-carousel .item h4 {
  margin-bottom: 0;
  font-size: 14px;
}

.carousel-item {
  width: 100%;
}

.carousel-item img {
  width: 100%;
  height: auto;
}

.overlay-v {
  text-align: center;
  width: 100px;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.overlay-v i {
  background: #333;
  padding: 10px;
  width: 48px;
  height: 48px;
  font-weight: normal;
  font-size: 32px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  margin-top: 100%;
  color: #222;
  opacity: 0;
}

.overlay-v:hover i {
  margin-top: 25%;
  opacity: 1;
}

.overlay-v span {
  background: #1a1c26;
  padding: 40px;
  width: 100%;
  height: 100%;
  font-weight: normal;
  margin-top: 100%;
  color: #222;
  opacity: 0;
  position: absolute;
  left: 0;
}

.overlay-v:hover span {
  margin-top: 0px;
  opacity: 0.9;
}

.carousel-item .pf_text .title {
  display: block;
  position: absolute;
  width: 100%;
  top: 80%;
  font-size: 20px;
  font-weight: 700;
  height: 0;
  color: rgba(255, 255, 255, 0);
  letter-spacing: 0;
}

.overlay-v:hover {
  background: rgba(30, 30, 30, 0.5);
}

.carousel-item:hover .pf_text .title {
  color: rgba(255, 255, 255, 1);
  top: 75%;
}

.carousel-item .pf_text .title .type {
  display: block;
  color: rgba(255, 255, 255, 0);
  font-size: 12px;
  font-weight: 400;
}

.carousel-item:hover .pf_text .title .type {
  color: rgba(255, 255, 255, 1);
}

.pf_text {
  color: #eceff3;
  letter-spacing: 5px;
}

.pf_text h4 {
  color: #eceff3;
  margin-bottom: 0;
  font-size: 14px;
}

.project-info {
  color: #ccc;
  margin-left: 20px;
}

.de_light .project-info {
  color: #606060;
}

.project-info h2 {
  margin-top: 0;
  font-size: 18px;
  letter-spacing: 3px;
}

.project-info h4 {
  font-weight: bold;
}

.project-info .details {
  padding: 20px;
  border: solid 1px rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}

.de_light .project-info .details {
  background: #eee;
  border-color: #ddd;
}

.project-infoproject-info span {
  display: block;
}

.project-info span.title {
  display: inline-block;
  min-width: 80px;
}

.project-info span.val {
  margin-bottom: 15px;
  font-weight: bold;
}

.project-view {
  padding: 120px 0 80px 0;
}

.project-images img {
  width: 100%;
}

.picframe {
  position: relative;
  overflow: hidden;
}

.picframe img {
  width: 100%;
}

#d-btn-close-x {
  width: 100px;
  height: 100px;
  display: block;
  /*background: url(../images_02/close.png) center no-repeat;*/
  margin: 0 auto;
  margin-bottom: 80px;
  cursor: poInter;
  padding: 20px;
}

#d-btn-close-x {
  /*background: url(../images_02/close-2.png) center no-repeat;*/
}

.project-name {
  font-size: 12px;
  font-weight: bold;
  display: block;
}

.info-details .info-text {
  margin-bottom: 20px;
}

.info-details .title {
  display: block;
  font-weight: bold;
}

span.overlay {
  font-family: var(--body-font);
  text-align: center;
  display: block;
  width: 100px;
  background: rgba(var(--primary-color-rgb), 0.8);
  position: absolute;
  opacity: 0.75;
  z-index: 500;
}

span.icon {
  cursor: poInter;
}

.f_box {
  display: block;
  position: relative;
  float: left;
  font-size: 16px;
  overflow: hidden;
  z-index: 1;
}

.f_box:hover .f_bg {
  opacity: 0.3;
}

.grid-item .f_box img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.8;
}

.f_box:hover img {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
  transform: scale(1.2);
  opacity: 1;
}

.f_box .f_bg {
  background: #111111;
  display: block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.f_box .f_bg.s1 {
  opacity: 0;
}

.f_box:hover .f_bg.s1 {
  opacity: 0.2;
}

.f_box .f_cap_wrap {
  display: table;
  width: 100%;
  height: 100%;
  position: absolute;
  color: #fff;
  z-index: 2;
  text-align: center;
  font-weight: bold;
}

.f_box .f_cap_wrap .f_cap {
  vertical-align: middle;
  display: table-cell;
}

.f_box:hover .f_cap {
  transform: scale(1.05);
}

.f_box .f_text {
  height: 0;
  overflow: hidden;
  font-family: Helvetica, Arial;
  font-weight: normal;
  display: inline-block;
  width: 70%;
  font-size: 14px;
}

.f_box:hover .f_text {
  height: auto;
}

.f_box img {
  width: 100%;
}

.f_box.s2:hover .f_bg {
  opacity: 0.4;
}

.f_box.s2 .f_bg {
  opacity: 0;
}

.f_box.s2:hover img {
  transform: scale(1.2);
}

.f_box.s2 {
  position: static;
}

.f_box.s2 .f_cap_wrap {
  opacity: 0;
}

.f_box.s2:hover .f_cap_wrap {
  opacity: 1;
  margin-top: 0;
}

.f_box .f_cap h1,
.f_box .f_cap h4 {
  color: #ffffff;
}

.f_box.s2 .f_cap {
  font-size: 20px;
  transform: scale(1.2);
}

.f_box.s2:hover .f_cap {
  transform: scale(1);
}

h2 {
  display: inline-block;
  overflow: hidden;
  position: relative;
  left: 0;
  right: 0;
  letter-spacing: -1px;
}

h2 span.underline {
  display: block;
  width: 0;
  height: 2px;
  background: #ffffff;
  position: relative;
  left: 0;
}

.f_box:hover h2 span.underline {
  width: 100%;
}

.overlay .info-area {
  display: block;
  margin-top: 10%;
}

.overlay .sb-icons {
  display: block;
  margin-top: 200px;
}

.de-gallery .overlay {
  text-align: center;
}

.de-gallery .overlay .pf_text {
  display: inline-block;
  opacity: 0;
}

.de-gallery .project-name {
  font-size: 16px;
  letter-spacing: 0;
}

.de-gallery .overlay .icon {
  display: inline-block;
  margin-top: 200px;
  text-align: center;
}

.de-gallery .overlay .icon i {
  text-shadow: none;
  color: #fff;
  border: solid 1px rgba(255, 255, 255, 0.1);
  font-size: 22px;
  padding: 20px;
  width: 60px;
  height: 60px;
  text-align: center;
  margin-left: 10px;
}

.de-gallery .overlay .icon i.icon-info {
  margin-left: 0px;
}

.de-gallery .overlay .icon i:hover {
  color: #222;
  background: #fff;
}

.grid_gallery .item {
  margin-bottom: 30px;
}

a.img-icon-url,
a.img-icon-zoom {
  display: inline-block;
  width: 36px;
  height: 36px;
  /*background: url(../images_02/icon-url.png) center no-repeat;*/
  margin: 0 auto;
  margin: 0 2px 0 2px;
}

a.img-icon-zoom {
  /*background: url(../images_02/icon-zoom.png) center no-repeat;*/
}

.pf_full_width {
  width: 100%;
}

.pf_full_width .item {
  float: left;
  width: 24.99%;
}

.pf_4_cols {
  width: 101%;
}

.pf_full_width.pf_3_cols .item {
  width: 33.3%;
}

.pf_full_width.pf_2_cols .item {
  width: 49.9%;
}

.gallery_border {
}

.pf_full_width.gallery_border .item,
.pf_full_width.gallery_border.pf_2_cols .item,
.pf_full_width.gallery_border.pf_3_cols .item {
  float: left;
  width: 23.9%;
  margin-right: 1%;
  margin-bottom: 1%;
}

.pf_full_width.gallery_border.pf_3_cols .item {
  width: 32.3%;
}

.pf_full_width.gallery_border.pf_2_cols .item {
  width: 48.9%;
}

.pf_full_width img {
  float: left;
  width: 100%;
}

.pf_full_width.grid {
  padding: 0.75%;
  padding-bottom: 0;
}

.pf_full_width.grid .item {
  margin: 0 0.75% 0.75% 0;
  width: 32%;
}

.pf_full_width.grid.pf_4_cols .item {
  margin: 0 0.75% 0.75% 0;
  width: 23.9%;
}

.pf_full_width.grid.pf_2_cols .item {
  margin: 0 0.75% 0.75% 0;
  width: 48.5%;
}

.gallery-cols .item {
  margin-bottom: 30px;
}

.pic-grey {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

/* ---- grid ---- */

.grid {
  width: 100%;
}

/*margin:5px;
}*/

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .element-item ---- */

/* 5 columns, percentage width */

.grid-sizer {
  width: 5%;
}

.grid-item {
  position: relative;
  float: left;
  border: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* ================================================== */

/* widget */

/* ================================================== */

#sidebar {
  padding-left: 30px;
}

#sidebar.post-left {
  padding-left: 0px;
  padding-right: 30px;
}

.widget {
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.widget h4 {
  font-size: 18px;
  margin-top: 0;
  letter-spacing: normal;
}

.widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget ul li {
  margin: 5px 0;
}

.widget .small-border {
  height: 2px;
  width: 30px;
  background: #fff;
  margin: 0px 0 20px 0;
  display: block;
}

.widget_category li {
  padding: 8px 20px 8px 20px;
  margin-bottom: 8px;
  background: #111;
}

.widget_category li:before {
  font-family: "FontAwesome";
  content: "\f07b";
  margin-right: 10px;
}

.de_light .widget_category li {
  background: none;
  border: solid 1px #eee;
}

.widget_category li a {
  color: #606060;
}

.widget_recent_post li {
  padding-left: 20px;
  /*background: url(../images_02/list-arrow.png) left 2px no-repeat;*/
  border-bottom: solid 1px #222;
  padding-bottom: 7px;
  margin-bottom: 5px;
}

.widget_recent_post li a {
  color: #555;
}

.widget-post li {
  border-bottom: solid 1px #f2f2f2;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.widget-post li a {
  padding-left: 60px;
  display: block;
  text-decoration: none;
  color: #606060;
}

.dark-scheme .widget-post li {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-scheme .widget-post li a {
  color: #ffffff;
}

.widget-post .post-date {
  position: absolute;
  color: #222;
  margin-right: 10px;
  text-align: center;
  width: 50px;
  font-size: 10px;
}

.widget-post li:hover > .post-date {
  background: #222;
  color: #fff;
}

.widget-post .date {
  background: #333;
  color: #fff;
  display: inline-block;
  padding: 0px 5px 0px 5px;
  float: left;
  font-size: 12px;
}

.widget .comments li {
  padding-left: 20px;
  /*background: url(../images_02/list-arrow.png) left 2px no-repeat;*/
  border-bottom: solid 1px #eee;
  padding-bottom: 7px;
  margin-bottom: 5px;
}

.widget .tiny-border {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
}

.footer-light .widget .tiny-border {
  background: rgba(0, 0, 0, 0.1);
}

.widget_tags li {
  text-shadow: none;
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 16px;
}

.widget_tags li a {
  font-size: 12px;
  text-decoration: none;
  margin-bottom: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  color: #fff !important;
  padding: 7px 10px 7px 10px;
}

.de_light .widget_tags li a {
  color: #606060;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.widget_tags li a:hover {
  color: #555;
  border-color: #555;
}

.de_light .widget_tags li a:hover {
  color: #111;
  border-color: #111;
}

.widget_top_rated_product ul {
  padding: 0;
}

.widget_top_rated_product li {
  min-height: 80px;
}

.widget_top_rated_product li .text {
  padding-top: 5px;
  padding-left: 75px;
}

.widget_top_rated_product li img {
  position: absolute;
  width: 60px;
  height: 60px;
}

.widget_tags li a {
  border-color: #555;
}

.widget_tags li a:hover {
  border-color: #eceff3;
}

footer .widget {
  border: none;
  margin-bottom: 0;
}

.widget_tags ul {
  margin-top: 30px;
}

footer .widget.widget_tags li a {
  border-color: #222;
}

footer .widget_recent_post li {
  padding: 0 0 10px 0;
  margin: 0;
  margin-bottom: 10px;
  background: none;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

footer .widget_recent_post li a {
  color: #ccc;
}

footer .widget h5 {
  margin-bottom: 20px;
}

footer .widget_list li {
  padding: 0 0 10px 0;
  margin: 0;
  margin-bottom: 10px;
  background: none;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

footer .widget_list li a {
  color: #ccc;
}

footer .tiny-border span {
  background: #ffffff;
  display: block;
  height: 1px;
  width: 30px;
}

.side-layout header {
  width: 20%;
  height: 100%;
  background: #222;
  position: fixed;
  padding: 30px;
  top: 0;
}

.side-layout header.header-light {
  background: #fff;
  border-right: solid 1px #eee;
}

.side-layout #content {
  width: 80%;
  float: right;
}

.side-layout footer {
  width: 80%;
  float: right;
}

.side-layout .container {
  width: 90%;
}

.side-layout section {
  width: 100%;
  float: right;
}

.side-layout header .info {
  display: none;
}

.side-layout #mainmenu {
  padding: 0;
  margin-top: 40px;
}

.side-layout #mainmenu > li {
  display: block;
  width: 100%;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.side-layout header.smaller #mainmenu > li {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.side-layout header.smaller {
  border-right: solid 1px #f6f6f6;
}

.side-layout header.header-dark.smaller {
  border-right: none;
}

.side-layout #mainmenu > li:last-child {
  border-bottom: none;
}

.side-layout #mainmenu > li > a {
  padding: 0;
  margin: 0;
}

.side-layout #mainmenu > li > a span {
  display: none;
}

.side-layout #mainmenu > li > ul {
  top: 0;
  margin-left: 120%;
}

.side-layout #mainmenu li ul {
  border: solid 1px #888;
}

.side-layout header div#logo {
  display: block;
  float: none;
  text-align: center;
}

.side-layout header div#logo img {
  width: 100%;
}

.side-layout #subheader {
  width: 80%;
  float: right;
  text-align: left;
}

.side-layout #subheader h1 {
  margin-top: 0;
  font-size: 24px;
}

.side-layout #subheader .small-border-deco span {
  margin: 0;
}

.side-layout.side-custom-bg header {
  /* background: url(../images/background/bg-header.jpg) top left;*/
  background-size: cover;
}

.bg-color-secondary h1,
.bg-color-secondary h2,
.bg-color-secondary h3,
.bg-color-secondary h4,
.bg-color-secondary h5,
.bg-color-secondary h6,
.p-title.invert,
.p-tagline,
i.bg-color-secondary {
  color: #ffffff;
}

/* ================================================== */

/* misc */

/* ================================================== */

/* box-url */

.box-url {
  position: relative;
  padding: 30px;
  background: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.2);
  display: block;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1);
}
.dark-scheme .box-url {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.1);
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3);
}
.box-url p {
  color: #727272;
  margin-bottom: 0;
}
.dark-scheme .box-url p {
  color: #9fa4dd;
  margin-bottom: 0;
}
.box-url:hover {
  background: rgba(0, 0, 0, 0.05);
}
.box-url-label {
  font-weight: bold;
  position: absolute;
  right: 30px;
  color: #0d0c22;
  padding: 2px 10px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
.dark-scheme .box-url-label {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}
.box-url.style-2 {
  text-align: center;
}
.box-url.style-2 h4 {
  font-size: 16px;
  margin-bottom: 0;
}
.soft-shadow {
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 5%), 0 1.5rem 2.2rem rgb(0 0 0 / 10%);
}

#item-carousel-big {
  padding: 0 25px;
}

.de-flex {
  display: flex;
  justify-content: space-between;
}

.de-flex > .de-flex-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

#loadmore {
  display: inline-block;
  margin-top: 10px;
}

.d-item {
  display: none;
}

.v-center {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.f-logo {
  margin-right: 30px;
}

.text-light .card {
  color: #ffffff !important;
  background: rgba(255, 255, 255, 0.05);
}

.filter__l,
.filter__r {
  font-family: var(--title-font);
}
.filter__l {
  font-weight: bold;
}
.filter__r {
  float: right;
  cursor: pointer;
}

/*Cookie Consent Begin*/
#cookieConsent {
  background-color: rgba(255, 255, 255, 0.8);
  min-height: 26px;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  padding: 10px 30px 10px 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 9999;
}

#cookieConsent a {
  text-decoration: none;
}

#closeCookieConsent {
  float: right;
  display: inline-block;
  cursor: poInter;
  height: 20px;
  width: 20px;
  margin: -15px 0 0 0;
  font-weight: bold;
}

#closeCookieConsent:hover {
  color: #fff;
}

#cookieConsent a.cookieConsentOK {
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  padding: 0 20px;
  cursor: poInter;
}

#cookieConsent a.cookieConsentOK:hover {
  opacity: 0.8;
}

/*Cookie Consent End*/

.vertical-center {
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
  /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

#purchase-now {
  position: fixed;
  bottom: 80px;
  left: 20px;
  z-index: 10000;
}

#purchase-now a {
  color: #ffffff;
  z-index: 1;
  position: absolute;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  background: #78a938;
  display: block;
  font-size: 24px;
  font-weight: bold;
  width: 60px;
  height: 60px;
  padding: 17px;
  text-decoration: none;
  animation: shadow-pulse 1s infinite;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(120, 169, 56, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(120, 169, 56, 0);
  }
}

#purchase-now a span {
  font-size: 14px;
  font-weight: 500;
  left: 8px;
  position: absolute;
  top: 18px;
  width: 100px;
}

#purchase-now .pn-hover {
  position: absolute;
  font-weight: bold;
  font-size: 16px;
  background: #ffffff;
  color: #78a938;
  width: 120px;
  padding: 10px;
  padding-left: 20px;
  text-align: center;
  top: 8px;
  border-radius: 5px;
  left: 30px;
  opacity: 0;
}

#purchase-now:hover .pn-hover {
  opacity: 1;
  left: 40px;
}

.de-images {
  position: relative;
  width: 100%;
}

.de-images img {
  border-radius: 3px;
  -webkit-box-shadow: 2px 20px 30px 0px rgba(20, 20, 20, 0.3);
  -moz-box-shadow: 2px 20px 30px 0px rgba(20, 20, 20, 0.3);
  box-shadow: 2px 20px 30px 0px rgba(20, 20, 20, 0.3);
}

.di-small {
  position: absolute;
  width: 40%;
  z-index: 1;
}

.di-small-2 {
  position: absolute;
  width: 40%;
  right: -0;
  bottom: -15%;
  z-index: 1;
}

.di-big {
  width: 80%;
  height: 80%;
  margin-top: 15%;
  margin-left: 10%;
}

div.round,
.rounded {
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
}

.opt-1 {
  display: inline-block;
}

.size96 {
  width: 96px;
  height: auto;
}

.domain-ext {
  margin-top: 15px;
}

.ext {
  border: solid 1px rgba(255, 255, 255, 0.2);
  display: inline-block;
  padding: 8px 10px 0px 10px;
  font-size: 12px;
  border-radius: 3px;
  margin: 0 5px 10px 5px;
}

.ext h4 {
  font-size: 18px;
  line-height: 10px;
  margin-bottom: 0;
}

.border {
  border-color: #efefef !important;
}

.tparrows {
  background: none !important;
}

.fc-header-toolbar {
  font-size: 14px;
}

.fc-content {
  color: #ffffff;
  padding: 3px;
}

.fc-widget-header {
}

.fc-view {
  background: #ffffff;
}

.fc-day-number {
  padding: 4px 8px !important;
}

.f-profile {
  margin-bottom: 0px;
}

.f-profile h4 {
  margin-top: 20px;
  margin-bottom: 0px;
}

.fp-wrap {
  position: relative;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  overflow: hidden;
}

.fpw-overlay {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.fpw-overlay-btm {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.f-invert .fpw-overlay-btm {
  opacity: 0;
}

.fpwo-wrap {
  width: 100%;
  position: absolute;
  bottom: 30px;
  text-align: center;
}

.fpwow-icons {
  display: inline-block;
  background: #ffffff;
  padding: 5px 10px 7px 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.fpwow-icons a {
  color: #ffffff;
  padding: 0 5px;
}

.fpwow-icons i {
  font-size: 15px;
}

.fp-icon {
  margin-top: 20%;
  z-index: 2;
  opacity: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  position: absolute;
  display: table;
  text-align: center;
}

.fp-icon i {
  display: none;
  vertical-align: middle;
  display: table-cell;
  color: #fff;
  font-size: 36px;
  color: #ffffff;
  padding: 13px 0;
  background: rgba(var(--primary-color-rgb), 0.8);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gray img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

.demo-icon-wrap {
  margin-bottom: 30px;
}

.demo-icon-wrap i {
  text-align: center;
  font-size: 20px;
  margin-right: 20px;
  position: relative;
  background: rgba(var(--secondary-color-rgb), 0.1);
  width: 48px;
  height: 48px;
  border-radius: 5px;
  padding: 15px 0;
}

.rtl .demo-icon-wrap i {
  margin-right: 0;
  margin-left: 20px;
}

.demo-icon-wrap-s2 {
  margin-bottom: 30px;
}

.demo-icon-wrap-s2 span {
  display: inline-block;
  text-align: center;
  font-size: 20px;
  margin-right: 20px;
  position: relative;
  background: rgba(var(--secondary-color-rgb), 0.1);
  width: 48px;
  height: 48px;
  border-radius: 5px;
  padding: 15px 0;
}

.rtl .demo-icon-wrap-s2 span {
  margin-right: 0px;
  margin-left: 20px;
}

.demo-icon-wrap .text-grey {
  display: none;
}

.fp-wrap:hover {
  -webkit-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.1);
  -moz-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.1);
  box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.1);
}

.fp-wrap:hover img {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);

  transform: scale(1.05);
}

.fp-wrap:hover .fpw-overlay-btm {
  opacity: 0;
}

.fp-wrap:hover .fpwow-icons a i {
  color: #fff;
}

.fp-wrap:hover .fp-icon {
  opacity: 1;
  margin-top: 0;
  margin-right: 0;
}

.fp-wrap:hover .fp-icon i {
  transform: scale(1.5);
}

i.big {
  display: inline-block;
  font-size: 48px;
}

.text-light .border {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.container-full {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
}

.one-fourth {
  width: 24.9%;
  display: table-cell;
  vertical-align: middle;
}

.one-third {
  width: 33.3%;
  float: left;
}

.three-fourth {
  position: relative;
  width: 74.9%;
  height: 100%;
  display: table-cell;
}

.owl-carousel {
  position: relative;
  margin-top: -5px;
  bottom: -5px;
  margin-bottom: -15px;
}

.owl-stage-outer .feature-box-type-2 {
  overflow: none;
  margin: 30px 0 30px 0;
}

.owl-item.active.center .feature-box-type-2 {
  transform: scale(1.05);
}

.owl-item.active.center .feature-box-type-2,
.owl-item.active.center .feature-box-type-2 h4,
.owl-item.active.center .feature-box-type-2 i {
  color: #ffffff;
}

.schedule-item {
  padding: 40px 0 30px 0;
  border-bottom: solid 1px #ddd;
}

.schedule-listing:nth-child(even) {
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(229, 229, 229, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(229, 229, 229, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(229, 229, 229, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
}

.schedule-item > div {
  float: left;
}

.schedule-item .sc-time {
  width: 160px;
  padding-top: 25px;
  color: #333;
}

.schedule-item .sc-pic {
  width: 120px;
}

.schedule-item .sc-pic img {
  width: 80px;
  height: auto;
}

.schedule-item .sc-name {
  font-weight: normal;
  font-size: 14px;
  width: 160px;
  padding-top: 15px;
}

.schedule-item .sc-name h4 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  line-height: 1.6em;
}

.schedule-item .sc-name span {
  font-weight: normal;
  font-size: 12px;
}

.schedule-item .sc-info {
  float: none;
  display: inline-block;
  width: 60%;
}

.schedule-item .sc-info h4 {
  margin-bottom: 10px;
}

.de_tab.tab_style_4 .de_nav {
  -webkit-touch-1a1c26out: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

strong {
  font-weight: bold;
}

.text-light strong {
  color: #fff;
}

.bg-white {
  background: #ffffff;
}

.bg-white-10 {
  background: rgba(255, 255, 255, 0.1);
}

.wh80 {
  width: 80%;
  height: 80%;
}

.border-top {
  border-top: solid 1px #e5e5e5;
}

.border-bottom {
  border-bottom: solid 1px #e5e5e5;
}

.dark-scheme header.border-bottom {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1) !important;
}

.color-overlay {
  background: rgba(157, 130, 235, 0.3);
}

section .color-overlay {
  padding: 60px 0 60px 0;
}

.color-overlay-2 {
  background: rgba(26, 37, 48, 0.85);
}

.fontsize24 {
  font-size: 24px;
}

.fontsize32 {
  font-size: 32px;
}

.fontsize36 {
  font-size: 36px;
}

.fontsize40 {
  font-size: 40px;
}

.fontsize48 {
  font-size: 48px;
}

.overlay50 {
  background: rgba(0, 0, 0, 0.5);
}

.overlay60 {
  background: rgba(0, 0, 0, 0.6);
}

.overlay70 {
  background: rgba(0, 0, 0, 0.7);
}

.overlay80 {
  background: rgba(0, 0, 0, 0.8);
}

.hidden-xy {
  overflow: hidden;
}

.border-box {
  border: solid 1px #dddddd;
}

.display-table {
  display: table;
}

.display-table [class*="col-"] {
  float: none;
  display: table-cell;
  vertical-align: top;
  margin: 0;
  padding: 0;
}

#popup-box {
  position: fixed;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: -100%;
  overflow-y: auto;
}

#popup-box.popup-show {
  top: 0;
}

#popup-box.popup-hide {
  top: -100%;
}

.overlay-s1 {
  background: rgba(217, 42, 224, 0.5);
}

.overlay-s2 {
  background: rgba(255, 135, 140, 0.5);
}

.overlay-s3 {
  background: rgba(91, 35, 108, 0.5);
}

.circle-x {
  background: #333;
  display: inline-block;
  padding: 20px;
  font-size: 40px;
  color: #fff;
  margin-top: 140px;
  width: 70px;
  height: 70px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.6);
}

#services-list {
  padding: 0;
  margin: 0;
}

#services-list li a {
  color: #fff;
}

#services-list li.active a:after {
  font-family: "FontAwesome";
  content: "\f054";
  float: right;
}

.de_light #services-list li a {
  color: #999;
}

#services-list li {
  font-weight: bold;
  list-style: none;
  margin: 0;
  background: rgba(0, 0, 0, 0.3);
  margin-bottom: 2px;
}

.de_light #services-list li {
  background: #eee;
}

#services-list li a {
  display: block;
  padding: 15px 20px 15px 20px;
  text-decoration: none;
}

#services-list li a:hover {
  color: #111;
}

.pic-services img {
  margin-bottom: 30px;
}

#services-list li.active a,
#services-list li.active a:hover {
  color: #fff;
  background: #333;
}

.project-images img {
  margin-bottom: 60px;
}

.teaser-text {
  font-family: var(--body-font);
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
  line-height: normal;
  font-weight: 400;
}

.teaser-text h2 {
}

#navigation {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  right: 20px;
  text-align: center;
  margin-bottom: 60px;
  z-index: 1000;
}

.nav-prev:before,
.nav-next:before,
.nav-exit:before {
  font-family: "FontAwesome";
  content: "\f177";
  color: #fff;
  padding: 20px;
  font-size: 24px;
  cursor: poInter;
}

.nav-exit:before {
  width: 100%;
  display: block;
}

.nav-next:before {
  content: "\f178";
  width: 100%;
  background: #555;
}

.nav-exit:before {
  content: "\f00d";
}

.de_light .nav-exit:before {
  color: #222;
}

.container-4 .de-team-list {
  width: 24.9%;
  float: left;
  position: relative;
}

.container-3 .de-team-list {
  width: 33.3%;
  float: left;
  position: relative;
}

.no-bottom {
  margin-bottom: 0;
  padding-bottom: 0;
}

p {
  margin-bottom: 20px;
}

p.intro {
  color: #555;
  font-size: 16px;
  line-height: 1.5em;
}

.bg-grey {
  background: #f6f6f6;
}

.no-bottom .col-md-12 {
}

.no-bottom {
  padding-bottom: 0 !important;
}

.no-top {
  padding-top: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.nopadding {
  padding: 0;
  margin: 0;
}

#filters i {
  margin-right: 10px;
}

.bg-side {
  position: relative;
  overflow: hidden;
}

.image-container {
  position: absolute;
  padding: 0;
  margin: 0;
  background-size: cover;
}

.image-container.pos-right {
  right: 0;
}

.rtl .image-container.pos-right {
  right: auto;
  left: 0;
}

.image-slider {
  cursor: move;
}

.inner-padding {
  padding: 90px;
}

.inner-padding.pos-left {
  padding: 90px;
  padding-left: 0;
}

.rtl .inner-padding.pos-left {
  padding-left: 90px;
  padding-right: 0;
}

.no-padding,
section.no-padding {
  padding: 0;
}

.float-left {
  float: left;
}

#loader-area {
  display: none;
  background: #fff;
}

#loader-area {
  display: none;
  background: #111;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  /*background: url('../images_02/page-loader.gif') 50% 50% no-repeat rgb(249, 249, 249);*/
}

.page-overlay {
  display: none;
  width: 120px;
  height: 120px;
  /*background: url(../images_02/page-loader.gif) center no-repeat #fff;*/
  border-radius: 10px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  margin: auto;
}

.teaser {
  font-size: 16px;
  line-height: 1.8em;
  font-weight: 400;
}

.teaser.style-2 {
  letter-spacing: 20px;
}

.teaser.style-3 {
  font-size: 14px;
}

.teaser.style-4 {
  letter-spacing: 5px;

  font-size: 14px;
}

.form-control:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.d-carousel {
  position: relative;
  display: flex;
  align-items: center;
}

.d-custom-nav {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100px;
  z-index: 100;
}

.d-arrow-left,
.d-arrow-right {
  display: none;
  cursor: pointer;
  position: absolute;
  margin-top: -10px;
  z-index: 100;
  width: 30px;
  height: 60px;
  background-color: rgba(255, 255, 255, 1);
}
.homeTopCarousel {
  padding: 0px;
}
.dark-scheme .homeTopCarousel:hover .d-arrow-left,
.dark-scheme .homeTopCarousel:hover .d-arrow-right {
  opacity: 1;
  display: block;
}

.d-arrow-left {
  left: 31px;
  border-top-right-radius: 110px;
  border-bottom-right-radius: 110px;
}

.d-arrow-right {
  right: 30px;
  border-top-left-radius: 110px;
  border-bottom-left-radius: 110px;
}
.homeTopCarousel .owl-next i {
  top: 20px;
}

.d-arrow-left i,
.d-arrow-right i {
  position: absolute;
  top: 20px;
  color: #111111;
  font-size: 20px;
  font-weight: bold;
}
.d-arrow-left i {
  left: 8px;
}

.d-arrow-right i {
  right: 8px;
}

#d-coll-carousel {
  position: relative;
  display: flex;
  align-items: center;
}

.owl-nav {
  display: flex;
}

.owl-prev,
.owl-next,
.d-nav-left,
.d-nav-right {
  cursor: pointer;
  position: absolute;
  top: 37.5%;
  z-index: 100;
  width: 45px;
  height: 45px;
  background-color: rgba(255, 255, 255, 1);
  border: solid 1px #ccc;
}

.dark-scheme .owl-prev,
.dark-scheme .owl-next {
  background: #150523 !important;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.1);
}

.dark-scheme .homeTopCarousel .owl-prev,
.dark-scheme .homeTopCarousel .owl-next {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}
.owl-prev:hover,
.owl-next:hover {
  transform: scale(1.1);
  -webkit-box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
}

.owl-prev,
.d-nav-left {
  left: -12px;
  border-radius: 60px !important;
  padding-left: 4px;
}

.owl-next,
.d-nav-right {
  right: -12px;
  border-radius: 60px !important;
  padding-right: 4px;
}

.d-nav-left i,
.d-nav-right i {
  position: absolute;
  top: 20px;
  color: #111111;
  font-size: 20px;
  font-weight: bold;
}
.owl-next i,
.d-nav-left i {
  top: 12px;
  left: 16px;
}

.d-nav-right i {
  top: 12px;
  right: 16px;
}

.owl-arrow {
  z-index: 100;
  background: #fff;
  margin-top: 48%;
  display: none;
  cursor: poInter;
}

.owl-arrow span {
  display: block;
  background: #555;
  position: absolute;
}

.owl-arrow .prev {
  left: 0;
}

.owl-arrow .next {
  right: 0;
}

.owl-arrow .prev:before,
.owl-arrow .next:before {
  font-family: "FontAwesome";
  content: "\f177";
  color: #222222;
  padding: 20px;
  font-size: 32px;
  display: block;
}

.owl-arrow .next:before {
  content: "\f178";
}

.owl-pagination {
  margin-top: 0px;
  height: 40px;
}

.owl-theme .owl-controls .owl-page span {
  display: block;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  width: 6px;
  height: 6px;
  background-color: #555;
  opacity: 1;
}

.owl-theme .owl-controls .owl-page.active span {
  background: none;
}

.owl-theme .owl-controls .owl-page.active span:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  position: relative;
  border: solid 2px #555;
  top: -2px;
  left: -2px;
}

/* new owl */

.thumbSlider .owl-theme .owl-nav [class*="owl-"] {
  margin: 0;
  padding: 0;
}
.thumbSlider .owl-prev,
.thumbSlider .owl-next,
.thumbSlider .d-nav-left,
.thumbSlider .d-nav-right {
  width: 30px;
  height: 30px;
  top: 15%;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 20px;
}

.owl-dots button {
  border: none;
  outline: none;
  padding: 0;
  width: 150px;
  height: 4px;
  margin: 0 5px;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot span {
  display: none;
}

/* new owl close */

.text-light .owl-theme .owl-controls .owl-page span {
  background-color: #fff;
}

.text-light .owl-theme .owl-controls .owl-page.active span {
  background: none;
}

.text-light .owl-theme .owl-controls .owl-page.active span:before {
  border: solid 2px #fff;
}

.text-slider {
  color: #fff;
  display: inline-block;
}

.text-slider.med-text {
  font-size: 72px;
  line-height: 84px;
  font-weight: bold;
}

.text-slider.big-text {
  font-size: 120px;
  line-height: 120px;
  font-weight: bold;
}

.border-deco .text-item {
  display: inline-block;
}

.text-slider.dark {
  color: #222;
}

.text-item i {
  font-size: 60px;
  margin-top: 5px;
}

.text-slider.big-text i {
  font-size: 90px;
  margin-top: 15px;
}

.text-slider h1 {
  font-size: 60px;
}

.text-rotate-wrap {
  display: inline-block;
}

.text-rotate-wrap .text-item {
  display: none;
}

.red {
  color: #ff0000;
}

.teal {
  color: #009999;
}

.purple {
  color: #663366;
}

.green {
  color: #009900;
}

.bg-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.center-y {
  position: relative;
}

.overlay-bg {
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(var(--primary-color-rgb), 0.9);
  top: 0;
  padding: 100px 0 100px 0;
}

.overlay-bg.p60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.overlay-gradient {
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
}

.overlay-solid {
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
}

.overlay-bg.t0 {
  background: rgba(var(--primary-color-rgb), 0);
}

.overlay-bg.t20 {
  background: rgba(var(--primary-color-rgb), 0.2);
}

.overlay-bg.t30 {
  background: rgba(var(--primary-color-rgb), 0.3);
}

.overlay-bg.t40 {
  background: rgba(var(--primary-color-rgb), 0.4);
}

.overlay-bg.t50 {
  background: rgba(var(--primary-color-rgb), 0.5);
}

.overlay-bg.t60 {
  background: rgba(var(--primary-color-rgb), 0.6);
}

.overlay-bg.t70 {
  background: rgba(var(--primary-color-rgb), 0.7);
}

.overlay-bg.t80 {
  background: rgba(var(--primary-color-rgb), 0.8);
}

.overlay-bg.t90 {
  background: rgba(var(--primary-color-rgb), 0.9);
}

.overlay-bg.c1 {
  background: rgba(var(--primary-color-rgb), 0.8);
}

.overlay-bg.dark {
  background: rgba(20, 20, 20, 0.8);
}

.overlay-bg.gradient-blue-aqua {
  background: -moz-linear-gradient(
    45deg,
    rgba(2, 68, 129, 0.9) 0%,
    rgba(52, 176, 188, 1) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(2, 68, 129, 0.9) 0%,
    rgba(52, 176, 188, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(2, 68, 129, 0.9) 0%,
    rgba(52, 176, 188, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc024481', endColorstr='#cc34b0bc', GradientType=1);
}

.full-height .overlay-bg {
  display: table;
}

.full-height .overlay-gradient {
  display: table;
}

.full-height .center-y {
  display: table-cell;
  vertical-align: middle;
}

.pic-hover {
  width: 100%;
  position: relative;
  overflow: hidden;
}

i.btn-action-hide {
  opacity: 0;
}

.pic-hover:hover .bg-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.pic-hover:hover i.btn-action-hide {
  opacity: 1;
}

.pic-hover.hover-scale:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.center-xy {
  position: absolute;
  z-index: 5;
}

.center-xy i.btn-action {
  font-size: 24px;
  border: solid 2px rgba(255, 255, 255, 0.3);
  padding: 16px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  text-align: center;
  color: #fff;
}

.center-xy i.btn-action:hover {
  border-color: rgba(255, 255, 255, 0);
  background: rgba(255, 255, 255, 0.3);
}

.center-xy i.btn-action.btn-play {
  padding-left: 20px;
}

.bg-grey,
section.bg-grey {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.wow {
  visibility: hidden;
}

#back-to-top {
  background: #ffffff;
  position: fixed;
  bottom: -40px;
  right: 10px;
  z-index: 1020;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: poInter;
  border: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  outline: none;
  opacity: 0;
}

#back-to-top:hover {
  transform: scale(1.1);
}

#back-to-top.show {
  bottom: 20px;
  opacity: 1;
}

#back-to-top.hide {
  bottom: -40px;
}

#back-to-top:before {
  font-family: "FontAwesome";
  font-size: 18px;
  content: "\f106";
  color: #fff !important;
  position: relative;
}

/* mouse scroll icon begin */

.mouse {
  position: absolute;
  width: 22px;
  height: 42px;
  bottom: 40px;
  left: 50%;
  margin-left: -12px;
  border-radius: 15px;
  border: 2px solid #fff;
  -webkit-animation: intro 1s;
  animation: intro 1s;
}

.scroll {
  display: block;
  width: 3px;
  height: 3px;
  margin: 6px auto;
  border-radius: 4px;
  background: #fff;
  -webkit-animation: finger 1s infinite;
  animation: finger 1s infinite;
}

@-webkit-keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes finger {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes finger {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

/* mouse scroll icon close */

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.arrow-anim {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  background-size: contain;
}

.arrow-anim:before {
  content: "\f107";
  font-family: "FontAwesome";
  font-size: 32px;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

a.read_more {
  display: inline-block;
  font-weight: bold;
  color: #222222;
  text-decoration: none;
}

a.read_more:hover {
  color: #707070;
}

a.read_more i {
  margin-left: 8px;
}

.text-light a.read_more {
  color: #fff;
}

.text-black {
  color: #222;
}

#de-arrow-nav-set {
  text-align: center;
}

#de-arrow-nav-set i {
  color: #222;
  font-size: 32px;
}

#de-arrow-nav-set i.icon_menu {
  display: inline-block;
}

.profile_pic h4 {
  margin-bottom: 0px;
}

.profile_pic .subtitle {
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
  color: #999;
}

.profile_pic .tiny-border {
  margin-bottom: 20px;
}

.profile_pic span {
  color: #fff;
}

.owl-custom-nav {
  position: absolute;
  z-index: 100;
  right: 4px;
  margin-top: 80px;
  width: 100%;
}

.owl-custom-nav .btn-next,
.owl-custom-nav .btn-prev {
  opacity: 0;
}

.owl-custom-nav .btn-next {
  right: -50px;
  position: absolute;
}

.owl-custom-nav .btn-prev {
  left: -50px;
  position: absolute;
}

.pf-hover:hover .btn-next {
  right: 30px;
  opacity: 1;
}

.pf-hover:hover .btn-prev {
  left: 30px;
  opacity: 1;
}

.owl-custom-nav .btn-next:before,
.owl-custom-nav .btn-prev:before {
  font-size: 18px;
}

.owl-custom-nav .btn-next:before {
  font-family: "FontAwesome";
  content: "\f178";
  color: #fff;
  padding: 12px;
}

.owl-custom-nav .btn-prev:before {
  font-family: "FontAwesome";
  content: "\f177";
  color: #fff;
  padding: 12px;
}

.owl-custom-nav a {
  text-decoration: none;
}

.owl-custom-nav .btn-prev:hover:before,
.owl-custom-nav .btn-next:hover:before {
  cursor: poInter;
}

#custom-owl-slider {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  background: #fff;
}

#custom-owl-slider .items {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 20;
  visibility: inherit;
  opacity: 1;
}

#custom-owl-slider .item img {
  display: block;
  width: 100%;
  height: 100%;
}

.owl-slider-nav {
  position: absolute;
  width: 100%;
}

.owl-slider-nav,
.owl-slider-nav div {
  position: absolute;
  z-index: 10;
  cursor: poInter;
}

.owl-slider-nav .next {
  right: -50px;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-right: 50px solid transparent;
}

.owl-slider-nav .prev {
  left: -50px;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 50px solid transparent;
}

.owl-slider-nav .next:before {
  font-family: "FontAwesome";
  font-size: 24px;
  content: "\f0da";
  color: #fff;
  position: absolute;
  right: -40px;
  top: -13px;
}

.owl-slider-nav .prev:before {
  font-family: "FontAwesome";
  font-size: 24px;
  content: "\f0d9";
  color: #fff;
  position: absolute;
  left: -40px;
  top: -13px;
}

.owl-dots {
  text-align: center;
}

.owl-dot {
  background: #ccc;
  border: none;
  margin: 5px;
  width: 6px;
  height: 6px;
  padding: 0;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
}

.dark-scheme .owl-dot {
  background: rgba(255, 255, 255, 0.3);
}

.owl-item.active div blockquote {
  background: rgba(var(--secondary-color-rgb), 0.1);
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.owl-item.active div blockquote,
.owl-item.active div blockquote .de_testi_by {
  opacity: 1;
}

.owl-item.active div blockquote:before {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  border-radius: 0 0 15px 0;
}

.owl-stage {
  overflow: none;
}

/* new table */

.de_table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  color: #1a1b1e;
  font-weight: 400;
}

.de_table .tr {
  display: table-row;
  border-collapse: collapse;
  border-bottom: solid 1px #eee;
}

.de_table:not(.no-heading) .tr:first-child {
  font-weight: 600;
  color: #111111;
}

/*.de_table .tr:nth-child(even) {
     background-color: rgba(var(--secondary-color-rgb), .1);
}*/
.de_table .tr .td {
  display: table-cell;
  padding: 16px 0;
  /*border-left: 1px solid #ddd;*/
}

.de_table .tr .td:nth-child(2) {
  font-weight: 600;
}

.de_table .tr .td:nth-child(2) span {
  display: inline-block;
  margin-left: 10px;
  color: #bbb;
  padding: 0 7px;
  border-radius: 5px;
  font-size: 14px;
}

.de_table .tr .td:nth-child(3) {
  font-size: 18px;
  font-weight: 600;
}

.de_table:not(.no-heading) .tr .td:first-child {
  border-left: 0;
}

.de_table.cols-2 .btn-main {
  padding: 5px 10px;
}

.de_table.table-style-2 {
  font-size: 15px;
}

.de_table.table-style-2 .tr .td {
  border: none;
  padding: 10px;
}

.de_table.table-style-2:not(.no-heading) .tr:first-child {
  color: #ffffff;
  border: none;
}

.de_table.cols-2 .tr .td:last-child {
  text-align: right;
}

.de_table .t-plus {
  color: #16c784;
}

.de_table .t-min {
  color: #ea3943;
}

.de_table .bc-btn {
  position: relative;
  display: inline-block;
  left: 0;
}

.de_table .tr .td:nth-child(2) img {
  width: 24px;
  height: auto;
  margin-right: 10px;
}

/* close new table */

.table {
  display: table;
  margin-bottom: 0;
}

.table [class*="col-"] {
  float: none;
  display: table-cell;
  vertical-align: top;
  padding: 0;
  height: 100%;
}

.table-custom-1 {
  position: absolute;
  height: 100%;
}

.table .table {
  background: none;
}

.table-pricing {
  font-size: 18px;
  border-radius: 3px;
  border: none;
}

.table-pricing thead {
  color: #ffffff;
  font-size: 18px;
}

.table-pricing thead tr th {
  padding: 20px 0 20px 0;
}

.table-pricing tbody tr {
  width: 100%;
  background: #f2f2f2;
  border-top: solid 8px #ffffff;
}

.table-pricing tbody tr:hover {
  background: #e2e2e2;
}

.table-pricing tbody tr td,
.table-pricing tbody tr th {
  border: none;
  padding: 20px 0 20px 0;
}

.table-pricing tbody tr th .lbl,
.table-pricing tbody tr td .lbl {
  display: none;
}

.text-middle,
.table [class*="col-"].text-middle {
  vertical-align: middle;
}

.typed,
.typed-strings {
  display: inline-block;
}

.typed-strings p {
  position: absolute;
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.mask {
  width: 100%;
  position: relative;
  overflow: hidden !important;
  display: inline-block;
}

.mask img {
  width: 100%;
  height: 100%;
}

.mask .cover {
  display: table;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 70px;
  padding: 25px;
  background: #000000;
  bottom: 0px;
  top: 100%;
  margin-top: -70px;
}

.mask .cover .c-inner {
  display: table-cell;
  vertical-align: middle;
}

.mask.light .cover {
  background: #ffffff;
}

.mask .cover h3 {
  font-size: 18px;
  color: #fff;
  line-height: 1em;
  padding-left: 30px;
}

.mask.light .cover h3 {
  color: #000000;
}

.mask .cover p {
  color: #ffffff;
  padding-top: 15px;
  padding-right: 25px;
}

.rtl .mask .cover p {
  padding-right: 0;
}

.mask.light .cover p {
  color: #777777;
}

.mask:hover .cover {
  height: 100%;
  top: 0;
  margin-top: -0px;
  background: rgba(35, 37, 42, 0.8);
}

.mask:hover .cover p {
  opacity: 1;
}

.mask:hover .cover p {
  padding-top: 0px;
}

.mask .cover h3 span {
  margin-left: 20px;
}

.rtl .mask .cover h3 span {
  margin-right: 50px;
}

.mask .cover h3 i {
  font-size: 32px;
  position: absolute;
  left: 30px;
  margin-top: -8px;
}

.rtl .mask .cover h3 i {
  left: auto;
  right: 30px;
}

.mask.s1 .cover {
  top: 0;
  padding: 30px;
  text-align: center;
  background: rgba(0, 0, 0, 0);
}

.mask.s1 .cover .desc {
  opacity: 0;
}

.mask.s1:hover .cover {
  background: rgba(0, 0, 0, 0.8);
}

.mask.s1:hover .cover .desc {
  opacity: 1;
}

.mask.s1 h3 {
  font-size: 24px;
}

.mask .cover a.btn-main {
  display: block;
  padding: 5px 10px;
  width: 100%;
  text-align: center;
}

.mask .s-gradient img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

.mask .s-gradient {
  position: relative;
}

.mask .s-gradient .gradient-fx {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: -moz-linear-gradient(
    left,
    rgba(var(--primary-color-rgb), 0.75) 0%,
    rgba(var(--primary-color-rgb), 0.2) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(var(--primary-color-rgb), 0.75) 0%,
    rgba(var(--primary-color-rgb), 0.2) 100%
  );
  background: linear-gradient(
    to right,
    rgba(var(--primary-color-rgb), 0.75) 0%,
    rgba(var(--primary-color-rgb), 0.2) 100%
  );
}

.style-2 .date-box {
  text-align: center;
  position: absolute;
  right: 0;
  padding: 15px 5px 5px 5px;
  color: #fff;
}

.style-2 .date-box .d {
  font-weight: 600;
  font-size: 38px;
  margin-bottom: 5px;
}

.style-2 .date-box .m {
  font-family: var(--body-font);
  font-size: 13px;

  letter-spacing: 8px;
  margin-left: 8px;
}

.sitemap {
  margin: 0;
  padding: 0;
}

.sitemap.s1 li {
  list-style: none;
}

.sitemap.s1 li:before {
  font-family: FontAwesome;
  content: "\f016";
  margin-right: 10px;
}

.list {
  margin: 0;
  padding: 0;
}

.list li {
  list-style: none;
}

.rtl .list.s1 li:after,
.list.s1 li:before {
  font-family: FontAwesome;
  content: "\f00c";
  margin-right: 10px;
}

.rtl .list.s1 li:after {
  margin-right: 0;
  margin-left: 10px;
}

.rtl .list.s1 li:before {
  display: none;
}

.list.s2 li:before {
  font-family: FontAwesome;
  content: "\f055";
  margin-right: 10px;
}

.list.s3 {
  display: inline;
  margin: 0;
  padding: 0;
}

.list.s3 li {
  display: inline;
  margin-right: 20px;
}

.list.s3 a {
  font-weight: bold;
}

#owl-logo img {
  opacity: 0.8;
}

#owl-logo img:hover {
  opacity: 1;
}

.switch-set {
  direction: ltr !important;
}

.dark-scheme .switch {
  background: rgba(0, 0, 0, 0.5);
}

.switch-set div {
  display: inline-block;
  padding: 0 5px;
  direction: ltr !important;
}

.switch {
  margin-bottom: -5px;
  -webkit-appearance: none;
  height: 24px;
  width: 44px;
  background-color: #eeeeee;
  border-radius: 60px;
  position: relative;
  cursor: poInter;
}

.switch::after {
  content: "";
  width: 20px;
  height: 20px;
  background-color: gray;
  position: absolute;
  border-radius: 100%;
  transition: 0.5s;
  margin: 2px 0 0 2px;
}

/*
 .switch:checked {
     background-color: blue;
}
*/
.switch:checked::after {
  transform: translateX(20px);
}

.switch:focus {
  outline-color: transparent;
}

.box-highlight {
  -webkit-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
}

.box-highlight,
.box-highlight .content {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  padding: 5px;
}

.box-highlight .heading {
  padding: 5px 10px 0 10px;
}

.box-highlight .content {
  margin: 5px;
  padding: 20px;
  background: #ffffff;
}

.box-highlight .content,
.box-highlight .content strong {
  color: #111111;
}

.box-highlight .content.v1 p {
  font-size: 20px;
}

.box-cc {
  color: #1a1b1e;
  border: solid 1px #eeeeee;
  padding: 20px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-weight: 500;
  position: relative;
  background: #ffffff;
  -webkit-box-shadow: 0 30px 30px 0 rgba(10, 10, 10, 0.1);
  -moz-box-shadow: 0 30px 30px 0 rgba(10, 10, 10, 0.1);
  box-shadow: 0 30px 30px 0 rgba(10, 10, 10, 0.1);
}

.box-cc .bc-price span {
  display: inline-block;
  margin-left: 10px;
}

.box-cc img {
  margin-bottom: 10px;
  width: auto !important;
  height: auto !important;
}

.bc-btn {
  display: block;
  position: absolute;
  right: 20px;
  background: rgba(0, 0, 0, 0.05);
  padding: 0 8px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

#selector {
  width: 500px;
  position: fixed;
  right: 0;
  top: 45%;
  padding: 5px;
  z-index: 1000;
}

#selector .opt {
  margin-top: 10px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  background: #ffffff;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  cursor: poInter;
  border: solid 1px rgba(255, 255, 255, 0.5);
}

#selector .opt:hover {
  border-color: #ffffff;
}

#selector .opt.tc1 {
  background: #ffde00;
}

#selector .opt.tc2 {
  background: #43baff;
}

#selector .opt.tc3 {
  background: #6ac70d;
}

#selector .opt.tc4 {
  background: #758fff;
}

#selector .opt.tc5 {
  background: #ff654c;
}

#selector .opt.tc6 {
  background: #ff3535;
}

#selector .opt.tc7 {
  background: #ae9749;
}

#selector .opt:after {
  position: relative;
  display: inline-block;
  content: "\f00c";
  font-family: "FontAwesome";
  top: -5px;
  opacity: 0;
  transform: scale(2);
}

#selector .opt.active:after {
  opacity: 1;
  transform: scale(1);
}

#selector #dark-mode,
#selector #related-items {
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  right: 0px;
  width: 40px;
  height: 39px;
  position: absolute;
  background: #21273e;
  padding: 8px;
  padding-top: 0px;
  cursor: poInter;
  overflow: hidden;
  border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
  color: #ffffff;
  font-size: 14px;
}

#selector #related-items {
  margin-top: 100px;
  background: #82b440;
  color: #ffffff;
}

#selector #dark-mode:hover,
#selector #related-items:hover {
  width: 200px;
  -webkit-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.25);
  -moz-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.25);
  box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.25);
}

#selector #dark-mode:before,
#selector #related-items:before {
  font-weight: normal;
  display: inline-block;
  content: "\f186";
  font-family: "FontAwesome";
  font-size: 20px;
  padding: 3px;
  padding-top: 6px;
  margin-right: 10px;
}

.dark-scheme #selector #dark-mode {
  color: #0d0c22;
  background: #ffffff;
}

.dark-scheme #selector #dark-mode:before {
  content: "\f185";
}

#selector #related-items:before {
  content: "\f05a";
}

#selector #dark-mode.active {
  background: #fdd32a;
  color: #222222;
}

.disable-dark #selector .dark-mode {
  display: none;
}

#selector .sc-opt {
  cursor: poInter;
  color: #fff;
  font-size: 14px;
  height: 38px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  max-width: 45px;
  padding-right: 20px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  right: 10px;
}

#selector .sc-opt:hover {
  max-width: 100%;
}

#selector .sc-opt .sc-icon {
  width: 45px;
  height: 38px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  padding: 12px 10px;
  position: relative;
  float: left;
}

.hide-rtl #demo-rtl {
  display: none;
}

#selector .sc-mt {
  margin-top: 45px;
}

#selector .sc-val {
  display: inline-block;
  margin-top: 5px;
}

.de-box-image {
  padding: 10px;
  text-align: center;
}

.de-box-image img {
  margin-bottom: 20px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border: solid 1px #eeeeee;
  -webkit-box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
  -moz-box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
  box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
}

.de-box-image:hover img {
  margin-top: -20px;
  -webkit-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
  -moz-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
  box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
}

.de-box-image:hover h4 {
  margin-top: 20px;
}

.de-image-hover {
  position: relative;
  overflow: hidden;
}

.dih-overlay {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #eee;
  opacity: 0;
}

.dih-title-wrap {
  z-index: 2;
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
}

.dih-title {
  opacity: 0;
  font-size: 16px;
  z-index: 2;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  color: #fff;
}

.de-image-hover:hover .dih-overlay {
  opacity: 0.8;
}

.de-image-hover:hover .dih-title {
  opacity: 1;
  transform: scale(1.2);
}

.de-image-hover:hover img {
  transform: scale(1.1);
}

.de-image-hover:hover {
  -webkit-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
}

.img-fullwidth {
  width: 100%;
  height: auto;
}

.nft_coll {
  background: #ffffff;
  padding-bottom: 10px;
  border: solid 1px #dddddd;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  margin-bottom: 10px;
}
.browseSection .nft_coll {
  margin-bottom: 30px !important;
}
.nft_coll.style-2 {
  border: none;
  background: none !important;
}
.thumbSlider .nft_coll {
  padding: 0 !important;
}
.nft_coll.style-2 .nft_wrap {
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.dark-scheme .nft_coll {
  background: #150523;
  border-color: #292f45;
}

.dark-scheme .nft_coll {
  background: #302089 !important;
  border-radius: 10px !important;
  border: 1px solid #302089;
}
.text-light .nft_coll {
  background: rgba(255, 255, 255, 0.04);
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.nft_wrap {
  position: relative;
  width: 100%;
  height: 200%;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
}

.nft_coll:hover img {
  transform: scale(1.05);
}

.nft_coll {
  text-align: center;
}

.nft_coll_info {
  padding-bottom: 10px;
}

.nft_coll h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.nft_coll span {
  font-size: 14px;
}

.nft_coll p {
  margin-top: 10px;
}

.nft_coll_by {
  font-weight: bold;
}

.nft_coll_pp {
  display: block;
  margin: 0 auto;
  margin-top: 6px;
  margin-bottom: 10px;
  position: relative;
  width: 74px;
  background: #000518;
  padding: 7px;
  border-radius: 50%;
}

.nft_coll_pp img {
  width: 60px;
  height: auto;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border: solid 5px #ffffff;
}

.nft_coll_pp i {
  font-size: 10px;
  color: #ffffff;
  background: #333;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  padding: 3px;
  position: absolute;
  bottom: 4px;
  right: 5px;
}

.author_list {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 4;
  column-gap: 20px;
}

.author_list li {
  margin-bottom: 20px;
  background-color: #180e51;
  border: 1px solid #302089;
  padding: 15px;
  border-radius: 10px;
  transition: 0.5s;
  display: flex;
  align-items: center;
}
.author_list li:hover {
  background: #302089;
}
.authorLikeTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding: 10px 10px 0;
  z-index: 9;
  width: 100%;
}
.author_list_pp {
  display: inline-block;
  height: auto;
  position: relative;
  width: 64px;
  background: #000518;
  padding: 7px;
  border-radius: 50%;
}

.author_list_pp img {
  width: 100%;
  border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
  position: relative;
  z-index: 1;
}

.author_list_pp:hover img {
  padding: 3px;
}

.author_list_pp i.fa-check,
.nft_coll_pp i,
.p_list_pp i.fa-check {
  color: #ffffff;
  font-size: 10px;
  padding: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  z-index: 2;
  background: url(../images/quality-check.png) no-repeat scroll center center !important;
  font-size: 0px;
  width: 18px;
  height: 18px;
}

.author_list_info {
  font-weight: bold;
  padding-left: 20px;
  display: inline-flex;
  flex-direction: column;
}

.author_list_info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2em;
  color: #818a97;
}

.author_list_info a {
  color: #1a1b1e;
}

.dark-scheme .author_list_info a {
  color: #ffffff;
}

.text-light .author_list_info a {
  color: #ffffff;
}

.p_list {
  margin-bottom: 30px;
}

.p_list_pp {
  position: absolute;
  display: inline-block;
  width: 64px;
  height: auto;
  margin-left: 0px;
  margin-top: -3px;
  z-index: 10;
  min-height: 50px;
}
.mar-right-15 {
  margin-right: 15px;
}
.p_list_pp img {
  width: 100%;
  border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
}

.p_list_pp i {
  color: #ffffff;
  font-size: 10px;
  padding: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

/* .p_list_info {
  font-weight: 400;
  padding-left: 70px;
} */
.p_list_info {
  font-weight: 400;
  padding-left: 79px;
  position: relative;
  top: 9px;
}

.dark-scheme .p_list_info b {
  color: #ffffff;
}

.p_list_info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2em;
}

.p_list_info a {
  color: #1a1b1e;
}

.text-light .p_list_info a {
  color: #ffffff;
}

/*
.nfts_wrap {
    display: flex;
    justify-content: space-between;
}*/

.item_info {
  padding-left: 10px;
}

.item_info_counts {
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
}

.item_info_counts > div {
  margin-right: 10px;
  font-weight: 400;
  padding: 2px 10px;
  font-size: 14px;
  text-align: left;
  min-width: 80px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.item_info_counts > div i {
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.5);
}

.dark-scheme .item_info_counts > div i {
  color: rgba(255, 255, 255, 0.7);
}

.item_info h6 {
  margin-bottom: 15px;
  font-size: 12px;
}

.item_info .de_countdown {
  position: relative;
  display: inline-block;
  margin: 0;
  right: 0;
  border: none;
  padding: 0;
  margin-bottom: 10px;
  margin-left: 10px;
}

.item_info h2 {
  display: block;
  margin-bottom: 15px;
}

.item_author .author_list_pp {
  margin-left: 0;
}

.item_author .author_list_info {
  padding-top: 10px;
  padding-left: 70px;
}

.nft__item {
  position: relative;
  padding: 0 0 20px;
  border: solid 1px #ddd;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  margin-bottom: 25px;
}

.nft__item.style-2,
.dark-scheme .nft__item.style-2 {
  border: none;
}

.dark-scheme .nft__item {
  border-radius: 0;
  border: 0;
}

.nft__item_action {
  font-size: 14px;
}

.text-light .nft__item {
  background: rgba(255, 255, 255, 0.04);
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.nft__item:hover {
  -webkit-box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  -moz-box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
}

.nft__item:hover img.nft__item_preview {
  transform: scale(1.03);
}

.nft__item_wrap {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  justify-content: center;
  background: #000000;
  height: 400px;
}

.nft__item_wrap a {
  display: block;
  width: 100%;
}

.nft__item img,
.nft__item video {
  width: 100%;
  height: auto !important;
  max-height: 400px;
}
.nft__item_preview {
  width: auto !important;
  max-width: 100%;
}
.nft__item .author_list_pp {
  margin: 0;
}
#profile_banner\ subheader\ padding-bottom-40 {
  padding-bottom: 40px;
}
.profile-bg {
  position: relative;
  min-height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.nft__item h4 {
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.profileImgPreview .nft__item img {
  width: 100px !important;
  height: 100px !important;
  display: block;
  border-radius: 50%;
}
.nft__item_info .nft__item_action a {
  font-weight: bold;
  color: #ffffff;
  background: #fc943e;
  min-width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-emphasis: center;

  margin-top: 10px;
}
.nft__item_info .nft__item_action a:hover {
  background-image: none;
  background-color: #ffffff;
  color: #000000;
}
.nft__item_info .dropdown,
.nft__item_info .dropdown a {
  font-weight: 400 !important;
  font-size: 16px;
}
.itemButtonGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  width: 100%;
}
.itemButtonGroup .btn-main {
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 1;
}
.itemButtonGroup .btn-main:first-child {
  margin-right: 15px;
}
.nft__item_price {
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.nft__item_price span {
  margin-left: 10px;
  color: #0d0c22;
  font-weight: 700;
}

.dark-scheme .nft__item_price span {
  color: #ffffff;
}

.nft__item .portrait {
  height: 100% !important;
  width: auto;
}

.nft__item_like {
  font-size: 18px;
  color: #ddd;
  font-weight: bold;
  background: #fc943e;
  padding: 4px 10px;
  border-radius: 25px;
  min-width: 70px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nft__item_like i {
  color: #fff !important;
}
.nft-item-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dark-scheme .nft__item_like {
  color: rgba(255, 255, 255, 0.2);
}

.nft__item_like span {
  color: #808080;
  margin-left: 7px;
  font-size: 15px;
}

.dark-scheme .nft__item_like span {
  color: #ffffff;
}

.nft__item_like i.active {
  color: #ec7498;
}

.nft__item_like:hover i {
  color: #ec7498;
}

.nft_pic {
  overflow: hidden;
  position: relative;
}
.nft_pic_wrap {
  border: 1px solid #302089;
  border-radius: 10px;
}
.nft_pic.style-2 {
  margin: 25px;
}

.nft_pic:hover img {
  transform: scale(1.2);
}

.nft_pic_info {
  padding: 40px;
  font-size: 24px;
  color: #ffffff;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  background-color: hsl(272, 75%, 7.8%) !important;
  border-color: hsl(272.5, 17.9%, 26.3%) !important;
  box-shadow: none !important;
}
.css-1u9des2-indicatorSeparator {
  background-color: hsl(272.5, 17.9%, 26.3%) !important;
}
.css-1dimb5e-singleValue {
  color: #fff !important;
}
.css-1nmdiq5-menu {
  background: #140b4b !important;
}
#section-hero .nft_pic_wrap {
  height: 518px;
  background: #180e51;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #302089;
  overflow: hidden;
}
#section-hero .nft_pic {
  box-shadow: none !important;
}
.nft_pic_title {
  display: block;
  font-weight: bold;
}

.nft_pic_by {
  font-size: 16px;
  color: #ffffff;
  text-overflow: ellipsis;
  display: inline-block;
  width: 200px;
  overflow: hidden;
}

.search_inp_form {
  margin-right: 20px;
}
.menu_side_area .btn-main:last-child {
  margin-left: 15px;
}
.de_countdown {
  position: absolute;
  left: 10px;
  bottom: 20px;
  background: #ffffff;
  padding: 0 0 1px 0px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border: solid 2px #dddddd;
  z-index: 100;
  color: #0d0c22;
  min-width: 155px;
  text-align: center;
}
.nft__item_wrap {
  position: relative;
  border-radius: 10px;
  border: 1px solid #302089;
}
.dark-scheme .de_countdown {
  color: #fff;
  background: none;
}

.text-light .de_countdown {
  border: solid 1px rgba(255, 255, 255, 0.2);
}

.countdown-section {
  font-size: 14px;
  margin-right: 8px;
  font-weight: bold;
}
.profileBg {
  max-height: 360px;
  width: 100%;
}
.d_profile {
  margin-bottom: 40px;
}

.profile_avatar {
  display: flex;
}

.d_coll .profile_avatar {
  display: block;
  text-align: center;
}

.profile_avatar img,
.profile_name {
  display: flex;
  align-items: center;
}

.d_coll .profile_avatar img,
.d_coll .profile_name {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.profile_avatar img {
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  width: 150px;
  height: 150px;
}

.d_coll .profile_avatar img {
  display: inline-block;
  margin-top: -100px;
  margin-bottom: 30px;
}

.profile_avatar i {
  color: #ffffff;
  margin: 110px 0 0 110px;
  display: block;
  position: absolute;
  padding: 10px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.profile_name {
  margin-left: 20px;
}

.profile_name h4 {
  font-size: 24px;
  line-height: 1.3em;
}

.profile_follower {
  margin-right: 20px;
  font-weight: 600;
}

.dark-scheme .profile_follower {
  color: #ffffff;
}

.profile_username,
.profile_wallet {
  font-size: 16px;
  font-weight: 500;
}

.profile_username {
  display: block;
}

.profile_wallet {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  float: left;
  color: #727272;
}

.dark-scheme .profile_wallet {
  color: #9fa4dd;
}

.d_coll .profile_wallet {
  display: inline-block;
  float: none;
}

#btn_copy {
  position: absolute;
  font-size: 12px;
  padding: 4px 10px;
  line-height: 1em;
  border: solid 1px #dddddd;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: none;
}

.dark-scheme #btn_copy {
  color: #ffffff;
  background: #fc943e;
  border: none;
  padding: 6px 12px;
}

#btn_copy.clicked {
  color: #ffffff;
}

.d_coll .d_profile_img {
  position: relative;
  margin: 0 auto;
}

.d_coll .d_profile_img i {
  color: #ffffff;
  margin: 10px 0 0 -40px;
  float: none;
  display: inline-block;
  background: url(../images/quality-check-big.png) no-repeat scroll center
    center;
  width: 36px;
  height: 36px;
  font-size: 0;
}

.tabs_wrapper {
  margin-top: 40px;
}

.positionSticky.fixed {
  position: fixed;
  top: 100px;
  max-width: 416px;
}
/* ================================================== */

/* Modal Popup Custom */

/* ================================================== */
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1;
  margin-left: 15px;
}
.purchase_text {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}
.purchase_desc {
  color: #a2a2a2;
  font-size: 16px;
  font-weight: 600;
}
.btn-main.btnGrey {
  background: #535659;
}
.form-border .input-group-append {
  background: #515457;
  margin-left: 0px;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
  align-items: center;
  border-radius: 0px 5px 5px 0px !important;
}
.form-border .input-group-prepend {
  background: #150523;
  margin-left: 0px;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
  align-items: center;
  border: 1px solid #4d5053;
  border-radius: 5px 0px 0px 5px !important;
}
.btn-main.btnGrey:hover {
  box-shadow: 2px 2px 20px 0px rgba(99, 103, 106, 0.5);
}
.primary_modal {
  z-index: 1150;
}
.primary_modal .modal-dialog .modal-header {
  display: block;
  background-color: #150523;
  padding: 12px;
  border-bottom: 0;
}
.primary_modal .modal-dialog .modal-body {
  background-color: #26292d;
  color: #a2a2a2;
  padding-bottom: 30px;
}
.primary_modal .modal-dialog .modal-footer {
  background-color: #fff;
}
.primary_modal .modal-header .close {
  background: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  float: none;
  position: absolute;
  color: #fff;
  top: 10px;
  right: 16px;
  font-size: 38px;
  font-weight: 100;
  opacity: 1;
  transition: 0.5s;
}
.primary_modal h5.modal-title {
  margin: 0;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
}
.primary_modal .modal-header .close:hover {
  color: #ef780a;
}
.buy_title_sm {
  color: #a2a2a2;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 400;
}
.buy_desc_sm {
  color: #a2a2a2;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0px;
  font-weight: 400;
}
.buy_desc_sm_bold {
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0px;
  font-weight: 500;
}
.buy_title_md {
  color: #ffffff;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.bor_bot_modal {
  border-bottom: 1px solid #3c3f42;
}

.approve_text {
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}
.approve_desc {
  color: #a2a2a2;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0px;
}
.approve_media .fas {
  font-size: 25px;
}
.approve_media .fas.pro_complete {
  color: #ef780a;
}
.btn-block {
  display: block;
  width: 100%;
}
.mar-top-10 {
  margin-top: 15px;
}
.transLoading img {
  position: fixed;
  z-index: 999;
  top: 50%;
  margin-top: -28px;
}
.transLoading {
  position: absolute;
  left: 50%;
  margin-left: -180px;
  z-index: 9999;
}
.transLoading::before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
  left: 0;
  top: 0;
}
/* ================================================== */

/* quick-search */

/* ================================================== */

#quick_search {
  margin-left: 30px;
  padding: 5px 11px;
  min-height: 40px;
  font-size: 15px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #150523;
  font-family: var(--title-font);
  outline: none;
  box-shadow: none !important;
  min-width: 225px;
}
.loading svg {
  fill: #ef780b !important;
}
#quick_search.style-2 {
  background: rgba(0, 0, 0, 0.05) !important;
}

.dark-scheme #quick_search.style-2 {
  background: rgba(255, 255, 255, 0.05) !important;
}

header:not(.header-light) #quick_search {
  color: #ffffff;
  position: relative;
  z-index: 9;
}

header:not(.header-light) #quick_search::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1 !important;
}

header:not(.header-light) #quick_search::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1 !important;
}

.header-light #quick_search {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.quick-search {
  display: inline-block;
  float: right;
  margin-left: 10px;
  padding: 10px 0 0 0;
}

.quick-search input {
  width: 120px;
  border-radius: 40px;
}

.side-bg {
  position: relative;
}

.image-container {
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}

.side-bg .background-image {
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  z-index: 0;
}

.side-bg.right .image-container {
  right: 0;
}

#search {
  float: left;
  width: 70%;
  margin-bottom: 0;
}

#btn-search {
  border: none;
  padding: 7px;
  background: #222;
  float: left;
  width: 30%;
  height: 42px;
}

#btn-search:before {
  font-family: FontAwesome;
  content: "\f002";
  font-size: 24px;
  color: #111;
}

#btn-search:hover {
  background: #fff;
}

/* dropdown */
/* ------------------------------ */
.dropdown {
  position: relative;
  z-index: 110;
  font-weight: 600;
  display: inline-block;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.dropdown a,
.dropdown a:hover {
  text-decoration: none;
  outline: none;
  display: inline-block;
}

.dropdown a,
.header-light .dropdown a:hover {
  color: #333;
}

.dark-scheme .dropdown a,
.dark-scheme .header-light .dropdown a:hover {
  color: #ffffff;
}

.dropdown > a {
  display: inline-block;
  padding: 7px 12px 7px 12px;
  min-width: 140px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-weight: bold;
}

.dark-scheme .dropdown > a {
  border: solid 1px rgba(255, 255, 255, 0.2);
}

.dropdown > a:after {
  font-family: "FontAwesome";
  font-size: 16px;
  content: "\f107";
  position: relative;
  float: right;
  margin-left: 10px;
}

.dropdown ul,
.dropdown li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.dropdown ul {
  position: absolute;
  min-width: 140px;
  display: none;
  height: 0;
  cursor: pointer;
}

.dropdown li span {
  background: #ffffff;
  display: block;
  padding: 5px 10px 5px 10px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-top: none;
  width: 100%;
  font-weight: 400;
}

.dark-scheme .dropdown li span {
  background: #21273e;
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.dark-scheme .dropdown li span:hover {
  background: #161d30;
  color: #fff;
}

.dropdown li span:hover {
  background: #eeeeee;
}

.dropdown li.active {
  display: none;
}

.text-light .dropdown {
  background: none;
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.text-light .dropdown a {
  color: #ffffff;
}

.items_filter {
  font-size: 14px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.items_filter .btn {
  width: 125px;
}
.items_filter .dropdown {
  margin-right: 10px;
  margin-bottom: 0;
}

/* ================================================== */

/* review */

/* ================================================== */

.de_review {
  text-align: center;
  min-height: 260px;
}

.de_review .de_nav {
  overflow: hidden;
  padding-left: 0;
  margin: 0;
  padding: 0;
  font-weight: bold;
  text-align: center;
}

.de_review .de_nav li {
  list-style: none;
  background: #eee;
  margin: 0 10px 0 10px;
  margin-bottom: 20px;
}

.de_review .de_nav li {
  display: inline-block;
}

.de_review .de_nav li img {
  width: 100px;
  height: auto;
}

.de_review .de_nav li span {
  padding: 8px 16px 8px 16px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: block;
  background: #f6f6f6;
  text-decoration: none;
  color: #646464;
  font-size: 13px;
  cursor: poInter;
}

.de_review blockquote {
  border: none;
  font-family: "Inter", Georgia, "Times New Roman", Times, serif;
}

.de_review .de_nav li span {
  border: 1px solid #202220;
  background: #222;
}

.de_review .de_nav li:first-child span {
  border-left: 1px solid #ddd;
}

.de_review .de_nav li:first-child span {
  border-left: 1px solid #202220;
}

.de_review .de_review_content {
  border: 1px solid #ddd;
  padding: 15px 20px 20px 20px;
  margin-top: -1px;
  background: #fff;
}

.de_review .de_review_content {
  border: 1px solid #202220;
  background: #27282b;
}

.de_review .de_nav li.active span {
  background: #fff;
  border-bottom: 1px solid #fff;
  color: #222;
  margin-bottom: -3px;
}

.separator {
  line-height: 0.5;
  text-align: center;
  margin: 30px 0 30px 0;
}

.separator span {
  display: inline-block;
  position: relative;
}

.separator span:before,
.separator span:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  top: 5px;
  width: 50px;
}

.separator span:before {
  right: 100%;
  margin-right: 15px;
}

.separator span:after {
  left: 100%;
  margin-left: 15px;
}

.separator span i {
  font-size: 12px;
  padding: 0;
  margin: 0;
  float: none;
}

.de_light .separator span:before,
.de_light .separator span:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.text-light .separator span:before,
.text-light .separator span:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* --------------------------------------------------
     * plugin
     * --------------------------------------------------*/

/* --------------------------------------------------
* revolution slider setting
* --------------------------------------------------*/

#revolution-slider {
  background: #222;
}

.tp-caption {
  color: #fff;
  font-weight: 400;
}

.tp-caption.teaser {
  letter-spacing: 5px;
}

.tp-caption br {
  margin-bottom: 5px;
}

.tparrows {
  top: 50%;
}

.med {
  font-size: 24px;
  letter-spacing: -0.5px;
}

.med-white {
  color: #fff;
  font-size: 24px;
  letter-spacing: -0.5px;
}

.med-green {
  color: #1a8b49;
  font-size: 24px;
  letter-spacing: -0.5px;
}

.small-white {
  color: #fff;
  line-height: 1.7em;
}

.big-black {
  font-size: 52px;
  letter-spacing: -3px;
  color: #222;
}

.big-white {
  font-size: 16px;
  color: #fff;
  line-height: normal;
  font-weight: 400;

  letter-spacing: 6px;
}

.very-big-white {
  font-family: var(--body-font);
  font-size: 64px;
  color: #fff;
  margin: 0;
  font-weight: 500;
}

.ultra-big-white {
  font-size: 120px;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

.ultra-big-black {
  font-size: 96px;
  font-weight: 400;
  color: #222;
  margin: 0;
  line-height: 72px;
}

a.btn-slider:hover {
  color: #222;
}

.tp-caption.separator {
  width: 100px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
}

/* ================================================== */

/* isotope */

/* ================================================== */

.isotope,
.isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -ms-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}

#filters {
  font-family: var(--body-font);
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
}

#filters li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  font-size: 11px;
}

#filters a {
  background: none;
  outline: none;
  color: #888;
  text-decoration: none;
  color: #888;
  padding: 5px 20px 5px 20px;
  border-radius: 3px;
  -moz-border-radius: 300px;
  -webkit-border-radius: 300px;
}

.text-light #filters a {
  color: rgba(255, 255, 255, 0.5);
}

#filters a:hover {
  color: #111;
}

#filters a.selected {
  color: #fff;
}

.text-light #filters a:hover {
  color: #fff;
}

.text-light #filters a.selected {
  color: #fff;
}

.text-light #filters a.selected {
  color: #fff;
}

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

/* -------------------------------------------------- */

/* flex slider */

/* -------------------------------------------------- */

.flexslider {
  width: 100%;
  background: none;
  border: none;
  box-shadow: none;
  margin: 0px;
}

.control-slider {
  float: right;
  display: inline-block;
  margin-right: -10px;
}

.prev-slider,
.next-slider {
  float: left;
  display: block;
  text-align: center;
  margin-top: 10px;
  cursor: poInter;
}

.prev-slider i,
.next-slider i {
  border: solid 1px #ccc;
  padding: 5px 10px 5px 10px;
}

.prev-slider i:hover,
.next-slider i:hover {
  color: #fff;
  border: solid 1px #333;
  background: #333;
}

.prev-slider {
  margin-right: -5px;
}

.project-carousel ul.slides,
.project-carousel-3-col ul.slides {
  margin-top: 10px;
  margin-bottom: 20px;
}

.logo-carousel .flex-direction-nav,
.project-carousel .flex-control-nav,
.project-carousel .flex-direction-nav,
.project-carousel-3-col .flex-control-nav,
.project-carousel-3-col .flex-direction-nav {
  display: none;
}

.logo-carousel.no-control .flex-control-nav {
  display: none;
}

.logo-carousel li img {
  width: auto;
}

/* flex slider - testi slider */

#testimonial-full {
  text-align: center;
  padding: 10px 0 10px 0;
  text-shadow: none;
}

#testimonial-full blockquote {
  color: #fff;
  border: none;
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5em;

  letter-spacing: 3px;
}

#testimonial-full span {
  letter-spacing: 0;
  margin-top: 20px;
  text-transform: none;
}

.testi-slider .flex-control-nav {
  position: absolute;
  margin-top: -50px;
}

.testi-slider .flex-direction-nav {
  display: none;
}

/* Control Nav */

.testi-slider .flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}

.testi-slider .flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  display: inline;
}

.testi-slider .flex-control-paging li a {
  background: none;
  width: 8px;
  height: 8px;
  display: block;
  border: solid 1px #fff;
  cursor: poInter;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.testi-slider .flex-control-paging li a:hover {
  border: solid 1px #fff;
  background: #fff;
}

.testi-slider .flex-control-paging li a.flex-active {
  border: solid 1px #fff;
  cursor: default;
  background: #fff;
}

.size10 {
  font-size: 10px;
}

.size20 {
  font-size: 20px;
}

.size30 {
  font-size: 30px;
}

.size40 {
  font-size: 40px;
}

.size50 {
  font-size: 50px;
}

.size60 {
  font-size: 60px;
}

.size70 {
  font-size: 70px;
}

.typed-strings p {
  position: absolute;
}

.deco-big {
  display: block;
  font-size: 120px;
  font-family: "Miama";
  margin-top: 48px;
  margin-left: -20px;
}

.deco-md {
  display: block;
  font-size: 120px;
  font-family: "Miama";
  margin-top: 32px;
  margin-left: -20px;
}

.social-icons-sm i {
  color: #fff;
  margin: 0 5px 0 5px;
  border: solid 1px #555;
  width: 32px;
  height: 32px;
  padding: 8px;
  text-align: center;
  border-radius: 30px;
}

.social-icons-sm i:hover {
  color: #ffffff;
}

.arrowup {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid #fff;
  margin: 0 auto;
  left: 0;
  right: 0;
  position: absolute;
  margin-top: -30px;
}

.arrowdown {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid #ffffff;
  margin: 0 auto;
  left: 0;
  right: 0;
  position: absolute;
}

.arrowright {
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  border-left: 60px solid green;
}

.arrowleft {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid blue;
}

blockquote.very-big {
  border: none;
  font-family: var(--body-font);
  text-align: center;
  border: none;
  font-size: 32px;
  font-weight: 400;
  background: none;
  font-style: normal;
}

blockquote.very-big span {
  font-family: var(--body-font);
}

a,
img {
  outline: 0;
}

.z1 {
  z-index: 1;
}

.z10 {
  z-index: 10;
}

.rtl-content {
  text-align: right;
}

/* overwrite bootstrap */

.nav-pills .nav-link {
  font-weight: 500;
}

.de-preloader {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background: #ffffff;
  z-index: 5000;
}

.dark-scheme .de-preloader {
  background: #161d30;
}

.dot-loader {
  width: 80px;
  height: 16.6666666667px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.dot-loader-dot {
  will-change: transform;
  height: 16.6666666667px;
  width: 16.6666666667px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  -webkit-animation: grow 0.5s ease-in-out infinite alternate;
  animation: grow 0.5s ease-in-out infinite alternate;
}

.dot-loader-dot.dot1 {
  left: 0;
  transform-origin: 100% 50%;
}

.dot-loader-dot.dot2 {
  left: 50%;
  transform: translateX(-50%) scale(1);
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.dot-loader-dot.dot3 {
  right: 0;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes grow {
  to {
    transform: translateX(-50%) scale(0);
  }
}

@keyframes grow {
  to {
    transform: translateX(-50%) scale(0);
  }
}

/* -------------------------Line Preloader--------------------- */

.line-preloader {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80px;
  height: 16.6666666667px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.line-preloader .p-line {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0);
}

.line-preloader .p-line:nth-child(1) {
  position: absolute;
  width: 25px;
  height: 25px;
  border-right: 2px solid gold;
  border-bottom: 2px solid gold;
  animation: anim4 600ms linear infinite;
}

.line-preloader .p-line:nth-child(2) {
  border-left: 2px solid gold;
  border-top: 2px solid gold;
  animation: anim4 1200ms linear infinite;
}

.line-preloader .p-line:nth-child(3) {
  position: absolute;
  width: 75px;
  height: 75px;
  border-right: 2px solid gold;
  border-top: 2px solid gold;
  animation: anim4 900ms linear infinite;
}

@keyframes anim4 {
  to {
    transform: rotate(360deg);
  }
}
.rdt_Table {
  border: 1px solid #4d5053;
}
.rdt_TableRow,
.rdt_TableHeadRow {
  background: #150523 !important;
  border-bottom-color: #4d5053 !important;
  transition: 0.5s;
}
.rdt_TableRow:hover {
  background: #34373c !important;
  cursor: pointer;
}
.rdt_TableCell {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 600;
  color: #ffffff;
}
.rdt_TableHeadRow .rdt_TableCol {
  font-size: 16px;
  font-weight: 600;
  color: #a2a2a2;
}
.positiveValue {
  color: rgb(52, 199, 123);
}
.negativeValue {
  color: rgb(235, 87, 87);
}
.rankCollectionColumn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rankHolderImg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.rankHolderImg.author_list_pp {
  margin: 0 10px;
  padding: 0;
}
.cryptoSymobolNumber {
  display: flex;
  align-items: center;
}
.cryptoSymobolNumber img {
  margin-right: 7px;
  width: 18px;
  height: 18px;
}
.rankHolderImg {
  position: relative;
}
.rankHolderImg i.online {
  position: absolute;
  right: 4px;
  top: 0px;
  color: rgb(52, 199, 123);
  padding: 0;
  background: transparent;
  bottom: auto !important;
  z-index: 9;
}
.rankHolderImg i.offline {
  position: absolute;
  right: 4px;
  top: 0px;
  color: #a2a2a2;
  padding: 0;
  background: transparent;
  bottom: auto !important;
  z-index: 9;
}

.brosweCatagoryList.owl-carousel .owl-item {
  text-align: center;
}
.brosweCatagoryList.owl-carousel.owl-theme .owl-nav {
  margin-top: 0;
}
.brosweCatagoryList.owl-carousel.owl-theme .owl-nav [class*="owl-"] {
  padding: 0;
  margin: 0;
}
.brosweCatagoryList.owl-carousel.owl-theme .owl-prev,
.brosweCatagoryList.owl-carousel.owl-theme .owl-next,
.brosweCatagoryList.owl-carousel.owl-theme .d-nav-left,
.brosweCatagoryList.owl-carousel.owl-theme .d-nav-right {
  top: 0;
  cursor: pointer;
}
.brosweCatagoryList.owl-carousel.owl-theme .owl-next,
.brosweCatagoryList.owl-carousel.owl-theme .d-nav-right {
  right: 0;
}
.brosweCatagoryList.owl-carousel.owl-theme .owl-prev,
.brosweCatagoryList.owl-carousel.owl-theme .d-nav-left {
  left: 0;
}
.navbarRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.mb-20 {
  margin-bottom: 20px;
}

.itemFilterTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.itemFilterTop {
  margin-bottom: 30px;
}
.itemCount p {
  margin-bottom: 0;
}

.btn.btn-main.btnGo {
  margin-left: 15px;
  border-radius: 0rem;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff !important;
  min-width: 100px;
}
.btn.btn-main.btnGo:hover {
  color: #ffffff !important;
}
.btn.btn-main.btnGo:focus {
  box-shadow: none !important;
}
.search_item a {
  display: flex;
  border-bottom: 1px solid #383a3e;
  padding: 0 15px 10px 15px;
  margin-bottom: 15px;
}
.search_item a:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
.search_item a img,
.search_item a video,
.search_item a audio {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  object-fit: cover;
}

.title_search {
  font-size: 16px;
}

.menu_side_area {
  display: inline-flex;
  align-items: center;
}
.btn-main.afterWallletIcon {
  min-width: 120px !important;
  min-height: 38px;
  margin-left: 15px;
  display: inline-flex !important;
  align-items: center;
  border-radius: 0;
}
.btn-main.afterWallletIcon span {
  font-size: 14px !important;
  font-weight: 800;
}
.wallet_address {
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  width: 180px;
}
.walletDropdown p {
  margin: 0;
}
.walletDropdown svg {
  fill: #ffffff;
  width: 24px;
}
#mainmenu.walletAddressDropMain li.has-child::after {
  display: none;
}
.btn-main.afterWallletIcon i {
  display: block;
}
.ml-2 {
  margin-left: 7px;
}
.pl-0 {
  padding-left: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
#mainmenu.walletAddressDropMain .dropdown-menu {
  padding: 15px;
  color: #fff;
  min-width: 200px !important;
  margin-left: -85px;
}
.wallet_balance {
  margin-top: 10px;
}
.wallet_balance h4 {
  font-size: 16px;
  margin: 0;
  color: #a2a2a2;
}
.walletAddressDropMain ul li:last-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 12px !important;
}
.walletAddressDropMain ul li a {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-weight: 600 !important;
}
.walletAddressDropMain ul li a:hover {
  color: #ef780b !important;
  background: linear-gradient(
    135deg,
    #ff0a6c -16.8%,
    #2d27ff 138.64%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.createPreview .nft__item_preview {
  box-shadow: none !important;
}
#photo {
  cursor: pointer;
  height: 42px;
  left: 50%;
  margin-left: -65px;
  opacity: 0;
  position: absolute;
  top: 5px;
  width: 130px;
}
.browseSection .nft_wrap img {
  height: 135px;
  object-fit: cover;
  width: 100%;
}

.items_filter .dropdown {
  min-width: 150px;
}
.item_author .author_list_info {
  padding-left: 15px;
}

.creators_details .p_list_pp img {
  height: 50px;
  width: 50px;
}
/* ================================================== */

/* R E S P O N S I V E */

/* ================================================== */

@media (min-width: 1200px) and (max-width: 1399px) {
  .positionSticky.fixed {
    max-width: 356px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .positionSticky.fixed {
    max-width: 356px;
  }
}
@media (min-width: 980px) and (max-width: 1199px) {
  #mainmenu li a:after {
    margin-left: 15px;
  }

  .pf_full_width.gallery_border .item,
  .pf_full_width.gallery_border.pf_2_cols .item,
  .pf_full_width.gallery_border.pf_3_cols .item {
    float: left;
    width: 23.95%;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    min-width: 95%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .activity-filter a {
    width: 100%;
    margin-right: 0 !important;
  }
}
/* -------------------------------------------------- 
    design for 768px
    /* ================================================== */

@media (min-width: 768px) and (max-width: 979px) {
  .slider_text {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 1090px) {
  #quick_search {
    width: 200px;
  }
  /* #quick_search{
        position: absolute;
        top: 30px;
    } */
}

@media only screen and (max-width: 992px) {
  .navbarRight {
    position: fixed;
    width: 300px;
    min-height: 100vh;
    top: 0;
    right: 0;
    background: #150523;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 0px;
    margin-right: -300px;
    transition: 0.5s;
  }
  .navbarRight.openMenu {
    margin-right: 0;
  }

  #mainmenu li {
    -webkit-transition: 0.55s ease-in-out;
    transition: 0.55s ease-in-out;
    opacity: 0;
    -webkit-transform: translateX(150px);
    transform: translateX(150px);
  }
  .navbarRight .form-inline {
    width: 95%;
    margin: 0 auto;
  }
  .navbarRight .form-inline #quick_search {
    transition: 0.55s ease-in-out;
    opacity: 0;
    transform: translateX(150px);
    margin-top: 50px;
    margin-bottom: 15px;
  }
  .openMenu #mainmenu li {
    opacity: 1;
    transform: translateX(0);
  }
  .navbarRight.openMenu .form-inline #quick_search {
    opacity: 1;
    transform: translateX(0);
  }
  .navbarRight .form-inline #quick_search {
    transition-delay: 0.05s;
  }
  #mainmenu li:nth-child(1) {
    transition-delay: 0.1s;
  }
  #mainmenu li:nth-child(2) {
    transition-delay: 0.15s;
  }
  #mainmenu li:nth-child(3) {
    transition-delay: 0.2s;
  }
  #mainmenu li:nth-child(4) {
    transition-delay: 0.25s;
  }
  .navbarToggler {
    transform: translateX(0);
    transition: 0.5s;
  }
  .navbarToggler.active {
    transform: translateX(-222px);
    transition: 0.55s ease-in-out;
  }

  .navbarRight.openMenu::before {
    content: "";
    width: 100%;
    position: fixed;
    min-height: 100vh;
    background: #180d55;
    transition: 0.25s;
    left: 0;
    top: 0;
  }
  .navbarToggler {
    border: 0 !important;
    box-shadow: none !important;
    outline: 0 !important;
    padding: 0 !important;
    z-index: 9;
    margin-left: 10px;
  }
  .navbarToggler.active span {
    background: #ffffff !important;
    color: #000000 !important;
  }

  .navbarToggler.active #menu-btn::before {
    content: "\f00d";
  }
  #mainmenu {
    flex-direction: column;
  }
  .navbarRight .form-inline #quick_search {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
  .card_search_result {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .de_table .tr .td:nth-child(4),
  .de_table .tr .td:nth-child(5),
  .de_table .tr .td:nth-child(6),
  .de_table .tr .td:nth-child(7) {
    display: none;
  }

  .col-right {
    position: absolute;
    top: -82px;
    right: 60px;
    width: 140px;
  }

  .mb-sm-30 {
    margin-bottom: 30px;
  }

  .sm-mt-0 {
    margin-top: 0;
  }

  .p-sm-30 {
    padding: 40px;
  }

  .pb-sm-0 {
    padding-bottom: 0;
  }

  .mb-sm-0 {
    margin-bottom: 0;
  }

  header .header-col {
    position: absolute;
    top: 0;
  }

  header .header-col.mid {
    position: absolute;
    top: 70px;
    width: 100%;
    padding: 0 0 0 0;
  }

  .text-center-sm {
    text-align: center;
  }

  .sm-pt10 {
    padding-top: 10px;
  }

  #menu-btn {
    display: block;
    float: right;
    z-index: 1000;
    top: 0;
    right: 0;
    background: #333;
  }

  .inner-padding {
    padding: 40px;
  }

  .header-col-left,
  .header-col-right {
    padding-top: 16px;
  }

  .header-col-mid #mainmenu {
    position: absolute;
    top: 90px;
    width: 100%;
    padding: 0 0 0 0;
    left: 20px;
  }

  header {
    display: none;
  }

  header .info {
    display: none;
  }

  header.header-bg {
    background: rgba(0, 0, 0, 1);
  }

  header,
  header.fixed {
    display: block;
    position: inherit;
    margin: 0;
    padding: 0;
    margin-top: 0;
    height: auto;
    background: #111111;
    padding: 15px 0 15px 0;
  }

  #de-sidebar {
    position: absolute;
    width: 100%;
    right: 1px;
  }

  header.side-header,
  .de_light header.side-header {
    position: absolute;
    display: block;
    height: 60px;
    top: 0;
    background: #000;
  }

  header.side-header #mainmenu {
    position: absolute;
    background: #000;
    margin: 0;
    top: 60px;
    left: 0;
    width: 100%;
  }

  header.side-header #logo .logo {
    display: none;
  }

  header.side-header #logo .logo-2 {
    display: inline-block;
    position: absolute;
    height: 30px;
    top: 15px;
    left: 40px;
  }

  header.side-header #menu-btn {
    position: absolute;
    top: 8px;
    right: 30px;
    height: 0;
    z-index: 2000;
    height: 35px;
  }

  header.side-header #mainmenu li {
    display: block;
  }

  header.side-header #mainmenu a,
  .de_light.de-navbar-left header #mainmenu > li > a {
    display: block;
    text-align: center;
    color: #fff;
  }

  .de_light.de-navbar-left header #mainmenu > li {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }

  header.side-header #mainmenu a:hover {
    background: none;
  }

  header.side-header #mainmenu a:hover {
    background: none;
  }

  header.side-header .social-icons-2 {
    display: none;
  }

  header.autoshow {
    height: auto;
    top: 0;
  }

  header.autoshow #mainmenu li a {
    background: none;
  }

  header.header-mobile-sticky {
    position: fixed;
  }

  #subheader {
    padding: 0px 0 80px;
  }
  .spacePrice {
    padding-left: 15px;
    margin-left: 12px;
    padding-right: 15px;
  }
  .de-navbar-left #subheader {
    padding: 0;
    margin: 0;
  }

  #subheader h1 {
    margin-top: 0;
  }

  #subheader span {
    display: none;
  }

  #subheader .crumb {
    display: none;
  }

  .de-navbar-left #subheader {
    padding-top: 70px;
  }

  .slider_text h1 {
    font-size: 32px;
  }

  .slider_text .description {
    display: none;
  }

  #logo,
  #logo .inner {
    vertical-align: middle;
    height: auto;
  }

  #domain-check .text,
  #domain-check .button {
    width: 100%;
  }

  .container-4 .de-team-list {
    width: 49.9%;
    float: left;
    position: relative;
  }

  #testimonial-full blockquote {
    padding: 20px;
  }

  #revolution-slider {
  }

  #mainmenu {
    float: none;
    z-index: 200;
    width: 100%;
    margin: 50px 0 0 0;
    padding: 0;
  }

  #mainmenu ul {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  #mainmenu a {
    text-align: left;
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #mainmenu li.has-child:after {
    display: none;
  }

  #mainmenu li li a,
  #mainmenu li li li a {
    padding-left: 0;
  }

  #mainmenu a:hover {
    background: #111;
  }

  #mainmenu a:hover {
    background: #111;
  }

  #mainmenu li ul {
    position: inherit;
    margin: 0;
    width: 100%;
  }

  #mainmenu li {
    border-bottom: solid 1px #eee;
    margin: 0;
    width: 100%;
    display: block;
  }

  #mainmenu li a:after {
    display: none;
  }

  #mainmenu li:last-child {
    margin-bottom: 30px;
  }

  #mainmenu li ul {
    border-top: solid 1px #eee;
    top: auto;
    width: auto;
    height: auto;
    position: inherit;
    visibility: visible;
    opacity: 1;
  }

  #mainmenu > li.menu-item-has-children > a {
    position: relative;
    padding-right: 15px;
  }

  #mainmenu > li.menu-item-has-children > a:after {
    content: "\f107";
    font-family: "FontAwesome";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
  }

  #mainmenu li ul a {
    width: 100%;
    background: none;
    border: none;
  }

  #mainmenu li ul li {
    border: none;
    padding-left: 40px;
  }

  #mainmenu li ul li a {
    display: block;
  }

  #mainmenu li ul li:last-child {
    border-bottom: none;
    margin: 0;
  }

  #mainmenu li ul li:last-child a {
    border-bottom: none;
  }

  #mainmenu li a {
    color: #eceff3;
  }

  #mainmenu li li a:hover {
    color: #fff;
  }

  header.header-mobile {
    overflow: hidden;
    position: relative;
  }

  header.header-mobile #mainmenu {
    float: none;
    z-index: 200;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: left;
    padding-right: 30px;
  }

  header.header-mobile #mainmenu ul {
    background: none;
  }

  header.header-mobile #mainmenu a {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  header.header-mobile #mainmenu a:hover {
    background: none;
  }

  header.header-mobile #mainmenu li ul {
    display: block;
    position: inherit;
    margin: 0;
    width: 100%;
  }

  header.header-mobile #mainmenu li {
    border-bottom: solid 1px #eee;
    margin: 0;
    width: 100%;
    display: block;
  }

  .dark-scheme header.header-mobile #mainmenu li {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }

  header.header-mobile #mainmenu li ul.mega ul {
    height: auto;
  }

  header.header-mobile #mainmenu li ul.mega .menu-content {
    background: #fff;
  }

  header.header-light.header-mobile #mainmenu li,
  header.header-light.header-mobile #mainmenu li ul li a {
    border-color: #eee;
  }

  header.header-dark.header-mobile {
    background: #222;
  }

  header.header-dark.header-mobile #mainmenu li {
    border-color: #333;
  }

  header.header-dark.header-mobile #mainmenu li a {
    color: #fff;
  }

  header.header-mobile #mainmenu li a:after {
    display: none;
  }

  header.header-mobile #mainmenu li:last-child {
    margin-bottom: 30px;
  }

  header.header-mobile #mainmenu li ul {
    border-top: none;
    top: auto;
  }

  header.header-mobile #mainmenu li ul a {
    width: 100%;
    background: none;
    border: none;
  }

  header.header-mobile #mainmenu li ul li {
    border: none;
    padding-left: 40px;
  }

  header #mainmenu li ul li:first-child {
    border-top: solid 1px #eee;
  }

  .dark-scheme header #mainmenu li ul li:first-child {
    border-top-color: rgba(255, 255, 255, 0.1);
  }

  header.header-mobile #mainmenu li ul li a {
    display: block;
    border-bottom: solid 1px #eee;
  }

  header.header-mobile #mainmenu li ul li:last-child {
    border-bottom: none;
    margin: 0;
  }

  header.header-mobile #mainmenu li ul li:last-child a {
    border-bottom: none;
  }

  header.header-mobile #mainmenu li a {
    color: #888;
  }

  .dark-scheme header.header-mobile #mainmenu li a {
    color: #ffffff;
  }

  header.header-mobile #mainmenu li a:hover {
    color: #333;
  }

  .dark-scheme header.header-mobile #mainmenu li a:hover {
    color: #fff;
  }

  header.header-mobile #mainmenu li li a:hover {
    color: #888;
  }

  header.header-mobile #mainmenu li ul {
    height: 0;
    overflow: hidden;
    position: relative;
    left: 0;
  }

  header.header-mobile #mainmenu li ul li ul li:last-child {
    border-bottom: solid 1px #eee;
  }

  header.header-mobile #mainmenu li ul li ul {
    margin-bottom: 10px;
  }

  header.header-mobile #mainmenu > li > span {
    width: 36px;
    height: 36px;
    background: url(../images/ui/arrow-down.png) center no-repeat;
    position: absolute;
    right: 0;
    margin-top: 10px;
    z-index: 1000;
    cursor: poInter;
  }

  .dark-scheme header.header-mobile #mainmenu > li > span {
    background: url(../images/ui/arrow-down-light.png) center no-repeat;
  }

  header.header-mobile #mainmenu li span.active {
    background: url(../images/ui/arrow-up.png) center no-repeat;
  }

  .dark-scheme header.header-mobile #mainmenu li span.active {
    background: url(../images/ui/arrow-up-light.png) center no-repeat;
  }

  header.header-mobile #mainmenu li > ul > li > span {
    width: 36px;
    height: 36px;
    background: url(../images/ui/arrow-down.png) center no-repeat;
    position: absolute;
    right: 0;
    margin-top: -45px;
    z-index: 1000;
    cursor: poInter;
  }

  header.header-mobile #mainmenu > li > ul > li > span.active {
    background: url(../images/ui/arrow-up.png) center no-repeat;
  }

  header.header-mobile #mainmenu li ul li a {
    border-bottom: solid 1px #eee;
  }

  .dark-scheme header.header-mobile #mainmenu li ul li a {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }

  header.header-mobile #mainmenu li ul li:last-child > a {
    border-bottom: none;
  }

  header.header-mobile .h-phone {
    color: #202020;
  }

  header.header-light.header-mobile #mainmenu > li > span,
  header.header-light.header-mobile #mainmenu li > ul > li > span {
    background: url(../images/ui/arrow-down.png) center no-repeat;
  }

  header.header-light.header-mobile #mainmenu > li > span.active,
  header.header-light.header-mobile #mainmenu li > ul > li > span.active {
    background: url(../images/ui/arrow-up.png) center no-repeat;
  }

  #mainmenu li ul.mega {
    position: fixed;
    left: 0;
    width: 100%;
    margin-top: 30px;
    display: block;
  }

  #mainmenu li ul.mega > li {
    width: 100%;
  }

  #mainmenu li ul.mega > li ul {
    position: static;
    visibility: visible;
    opacity: 1;
    left: 0;
    float: none;
    width: 100%;
  }

  #mainmenu li ul.mega .inner {
    background: #1a1c26;
  }

  #mainmenu ul.mega > li ul li {
    margin: 0;
    padding: 0;
  }

  #mainmenu li ul.mega .menu-content {
    background: #202020;
    padding: 30px;
  }

  /* header light */
  .header-light #mainmenu ul li a,
  .header-light #mainmenu ul li a:hover {
    color: #333;
  }

  .de_tab.tab_methods .de_nav {
    text-align: center;
  }

  .de_tab.tab_methods .de_nav li {
    margin: 0;
    padding: 0;
  }

  .de_tab.tab_methods .de_nav li span {
    margin: 0;
    margin-bottom: 40px;
    display: inline-block;
  }

  .de_tab.tab_methods .de_nav li .v-border {
    border: none;
  }

  .hidden-phone {
    display: none;
  }

  .grid_gallery .item {
    width: 100%;
  }

  .table [class*="col-"] {
    float: none;
    display: block;
    vertical-align: top;
  }

  .table-pricing thead tr,
  .table-pricing tbody tr {
    display: block;
    width: 100%;
  }

  .table-pricing thead tr {
    display: none;
  }

  .table-pricing tbody tr th,
  .table-pricing tbody tr td {
    text-align: left;
    display: block;
    width: 100%;
  }

  .table-pricing tbody tr td {
    padding: 10px 30px;
  }

  .table-pricing tbody tr td:nth-child(2) {
    padding-top: 30px;
  }

  .table-pricing tbody tr td:last-child {
    padding-bottom: 40px;
    text-align: center;
  }

  .table-pricing tbody tr th span,
  .table-pricing tbody tr td span {
    min-width: 50%;
  }

  .table-pricing tbody tr th .lbl,
  .table-pricing tbody tr td .lbl {
    display: inline-block;
    min-width: 50%;
  }

  .table-pricing tbody tr th {
    color: #ffffff;
    padding: 20px 30px;
  }

  .sm-hide {
    display: none;
  }

  .btn-rsvp {
    position: absolute;
  }

  .d-btn-close {
    position: relative;
  }

  .schedule-item .sc-info {
    padding-top: 20px;
  }

  .de_tab.tab_style_4 .de_nav li {
    min-width: 100px;
  }

  #mainmenu a span {
    display: none;
  }

  .pf_full_width.grid {
    margin-left: 1.5%;
  }

  .de_tab.tab_methods.style-2 .de_nav li.active .arrow {
    margin-top: -40px;
    margin-bottom: 40px;
  }

  .table-custom-1 {
    position: relative;
    height: auto;
  }

  h1.very-big {
    font-size: 9vw;
    letter-spacing: 0;
  }

  .timeline > li > .timeline-panel {
    width: 100%;
  }

  .timeline-badge {
    display: none;
  }

  .timeline:before {
    background: none;
  }

  .tp-caption {
    padding-left: 40px;
    padding-right: 40px;
  }

  .author_list {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
  }

  .subfooter .de-flex,
  .subfooter .de-flex-col {
    display: block;
  }

  .subfooter .social-icons {
    margin-top: 20px;
  }

  .subfooter span.copy {
    margin-top: 20px;
    display: block;
  }

  .positionSticky.fixed {
    top: 0px;
    max-width: 100%;
    width: 100%;
    position: relative !important;
  }
  .activity-filter a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown {
    display: inline-block;
    margin-bottom: 10px;
  }
  .activity-filter a:nth-child(even) {
    margin-right: 0 !important;
  }
  .positionSticky {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .homeBannerContent h1 {
    font-size: 42px;
    text-align: center;
  }
  .home-banner-left-content {
    text-align: center;
  }
  .home-banner-left-content .btn {
    margin-left: auto;
    margin-right: auto;
  }
  .home-banner-image {
    text-align: center;
    margin-top: 60px;
  }
  .mvHomeBg::before {
    display: none;
  }
  h2.style-2 {
    text-align: center;
    display: block;
    margin-bottom: 30px;
  }
  .hvm-content p {
    text-align: center;
  }
  .homeFanContent {
    padding: 45px;
  }
  .homeFanContent h2 {
    text-align: center;
    display: block !important;
    width: 100%;
  }
  .homeFanContent p {
    text-align: center;
  }
  .homeFanContent .btn {
    margin-left: auto;
    margin-right: auto;
  }
}

/* -------------------------------------------------- 
    custom for 320px & 480px 
    /* -------------------------------------------------- */

@media only screen and (max-width: 767px) {
  .d_profile {
    display: block;
  }

  .d_profile .de-flex-col {
    display: block;
  }

  .profile_avatar {
    display: block;
  }

  .profile_avatar i {
    margin-top: -40px;
  }

  .profile_name {
    margin-left: 0;
    margin-top: 20px;
    display: block;
    width: 100%;
  }

  .profile_follow {
    margin-top: 40px;
    width: 100%;
  }

  .menu_side_area {
    margin-left: 20px;
    width: 100%;
    position: relative;
    right: 0;
    text-align: right;
    z-index: 0;
    display: block;
  }

  header .container {
    min-width: 100%;
  }

  .side-bg,
  .image-container {
    height: 400px;
    position: static;
    display: block;
  }

  .pricing-2-col .pricing-box,
  .pricing-3-col .pricing-box,
  .pricing-4-col .pricing-box,
  .pricing-5-col .pricing-box {
    width: 100%;
  }

  .container-4 .de-team-list {
    width: 100%;
    float: left;
    position: relative;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .feature-box-image,
  .pricing-box {
    margin-bottom: 30px;
  }

  #filters li.pull-right {
    float: none;
  }

  .pf_full_width .item {
    float: left;
    width: 49.9%;
  }

  .text-item {
    font-size: 48px;
    line-height: 48px;
  }

  .text-item i {
    font-size: 36px;
  }

  #de-sidebar {
    width: 100%;
    display: block;
    position: relative;
  }

  .gallery_border {
    margin-left: 0px;
  }

  .pf_full_width.gallery_border .item {
    width: 48.9%;
  }

  body.boxed {
    margin: 0px;
  }

  .schedule-item .sc-time {
    width: 100%;
    padding-bottom: 20px;
    float: none;
  }

  .schedule-item .sc-pic {
    width: 80px;
  }

  .schedule-item .sc-pic img {
    width: 60px;
  }

  .schedule-item .sc-name {
    padding-top: 5px;
  }

  .schedule-item .sc-info {
    float: none;
    display: block;
    width: 100%;
    clear: both;
    padding-top: 30px;
  }

  .schedule-item .sc-info h4 {
    margin-bottom: 10px;
  }

  .de_tab.tab_style_4 .de_nav {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }

  .de_tab.tab_style_4 .de_nav li {
    min-width: 33.3%;
    width: 33.3%;
    border: none;
  }

  .de_tab.tab_style_4 .de_nav li:first-child,
  .de_tab.tab_style_4 .de_nav li:last-child {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }

  .pf_full_width.grid {
    margin-left: 0.2%;
  }

  .pf_full_width.grid .item {
    width: 48.5%;
  }

  .display-table [class*="col-"] {
    display: block;
  }

  h1.big,
  h1_big {
    font-size: 8vw;
    line-height: 10vw;
  }

  .xs-hide {
    display: none;
  }

  .author_list {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
  }
}

/* -------------------------------------------------- 
    design for 480px
    /* -------------------------------------------------- */

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .feature-box-image,
  .pricing-box {
    margin-bottom: 30px;
  }

  .timeline-panel {
    margin-bottom: 30px;
  }

  .timeline-pic {
    height: 300px;
  }
}

/* -------------------------------------------------- 
    design for 320px
    /* -------------------------------------------------- */

@media only screen and (max-width: 480px) {
  .pf_full_width .item {
    float: left;
    width: 100%;
  }

  .text-item {
    font-size: 32px;
    line-height: 32px;
  }

  .text-item i {
    font-size: 24px;
  }

  .pf_full_width.gallery_border .item {
    width: 100%;
  }

  h4.s2 span {
    display: block;
    margin: 0;
    padding: 0;
  }

  .h-phone {
    display: none;
  }

  .author_list {
    -moz-column-count: 1;
    -moz-column-gap: 20px;
    -webkit-column-count: 1;
    -webkit-column-gap: 20px;
    column-count: 1;
    column-gap: 20px;
  }

  header a.btn-main {
    padding: 7px 12px !important;
    border-radius: 2px !important;
    margin-top: -1px;
    margin-right: -10px;
  }

  header a.btn-main i {
    display: inline-block;
  }

  header a.btn-main span {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  #topbar {
    display: none;
  }
}

/* section control */
@media only screen and (min-device-width: 768) and (max-device-width: 1024) and (orientation: portrait) {
  section {
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    -o-background-size: auto 100%;
    background-size: auto 100%;
    background-attachment: scroll;
  }
}

@media only screen and (max-width: 992px) {
  #subheader {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: scroll;
  }
}

@media only screen and (max-width: 992px) {
  section {
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    -o-background-size: auto 100%;
    background-size: auto 100%;
    background-attachment: scroll;
  }
}

@media (min-width: 1200px) {
  .container-timeline ul {
    display: flex;
    padding-left: 0;
    padding-top: 20px;
  }

  .container-timeline ul li {
    flex: 1 1 0;
    border-left: 0;
    border-top: 1px solid green;
    padding: 50px 2em 0 0;
  }

  .container-timeline ul li::before {
    left: 0;
    top: -21px;
  }
}

@media (min-width: 993px) {
  .dropdown.has-child ul {
    display: block !important;
  }
  .mobileHeadRight {
    display: none;
  }
  .createLink-for-mobile {
    display: none !important;
  }
}
@media (max-width: 992px) {
  #navbarSupportedContent .form-inline {
    position: relative;
    z-index: 99;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  #mainmenu li {
    border-radius: 0;
    border-color: rgb(46, 52, 69);
  }
  #mainmenu li > a {
    display: block;
    padding-left: 15px;
    border-radius: 0;
  }
  #mainmenu li > a:hover {
    background: #fc943e;
  }
  #mainmenu li ul {
    border-radius: 0;
    border-top: 0;
  }
  #mainmenu li ul li {
    padding-left: 0;
  }
  #mainmenu li ul li a {
    padding-left: 15px;
  }
  li.has-child a {
    position: relative;
  }
  li.has-child a i {
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    background: url(../images/ui/arrow-down-light.png) center no-repeat;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -18px;
    z-index: 1000;
    cursor: poInter;
    font-size: 0;
  }

  li.has-child.show a i {
    background: url(../images/ui/arrow-up-light.png) center no-repeat;
  }
  #navbarSupportedContent.collapse {
    background-color: #150523;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    min-width: 40%;
    margin-left: -40%;
    opacity: 1;
    transition: 0.5s;
  }
  #navbarSupportedContent.collapse.show {
    margin-left: 0;
  }
  #navbarSupportedContent::before {
    content: "";
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    min-height: 100vh;
    z-index: 9;
  }
  #navbarSupportedContent .form-inline {
    width: 100%;
    margin-top: 30px;
  }
  #quick_search {
    position: static;
    margin-left: 0;
    width: 100% !important;
  }
  .navbar-expand-lg .navbar-collapse {
    display: none !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: block;
  }
  .navbar-expand-lg .navbar-collapse.show {
    display: flex !important;
    flex-direction: column;
    flex-basis: 100%;
    flex-grow: 1;
  }
  .navbar-expand-lg .navbar-collapse.show .navbar-nav {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .menu_side_area {
    display: none;
  }
  .navbar-expand-lg {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .navbar-toggler {
    border: 0 !important;
    outline: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
    position: relative;
    z-index: 99;
  }
  .mainMenu {
    padding: 0 !important;
    min-height: 90px;
  }
  .down .mainMenu {
    min-height: 70px;
  }
  .mobileHeadRight {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  header a.btn-main {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .navbar-toggler.collapsed #menu-btn::before {
    content: "\f0c9" !important;
  }
  .navbar-toggler #menu-btn::before {
    content: "\f00d" !important;
  }
  .dark-scheme .homeTopCarousel .d-arrow-left,
  .dark-scheme .homeTopCarousel .d-arrow-right {
    opacity: 1;
    display: block;
  }

  #form_quick_search {
    width: 100%;
    margin-bottom: 20px;
  }
  .itemFilterTop {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .itemCount {
    margin-top: 15px;
  }
}
@media (max-width: 991px) {
  .activityPageSection .row {
    flex-direction: column-reverse;
  }
}
@media (max-width: 767px) {
  .activityPageSection .row {
    flex-direction: column-reverse;
  }
  .activity-list li::after {
    right: 0;
  }
  .items_filter .dropdown {
    margin-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .btnbar {
    width: 100%;
  }
  .loader_section_ {
    padding: 30px;
    text-align: center;
  }
  .loader_section_ h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .loader_section_ h2 a {
    margin-top: 30px !important;
    display: block;
  }
  .home-banner-left-content::after {
    display: none;
  }
  .nft__item_action {
    width: 100%;
  }
  .nft__item_info .nft__item_action a {
    width: 100%;
  }
  #quick_search {
    margin-top: 0px !important;
  }
  .spacePrice {
    margin-left: 0;
  }
  .createPreview {
    margin-top: 0px;
  }
  .de_nav.createNavTab a.nav-link {
    margin-bottom: 15px !important;
  }
  .items_filter .dropdown {
    margin-right: 0;
    width: 100%;
  }
  .items_filter .dropdown > a {
    width: 100%;
  }
  .items_filter .dropdown ul {
    background: #000000;
    width: 100%;
  }
  .items_filter #item_category {
    z-index: 115;
  }
  .items_filter #buy_category {
    z-index: 112;
  }
  .de_nav.nav-tabs .nav-link {
    margin-bottom: 15px !important;
  }
  .createNavTab {
    display: flex;
    flex-direction: row;
  }
  .createNavTab a {
    width: calc(50% - 10px);
  }
  #content {
    padding-top: 55px;
  }
  .de_count.text-left {
    text-align: center;
  }
  .box-url {
    margin-bottom: 30px;
  }
  .infoGroupButton {
    display: flex;
  }
  .infoGroupButton button {
    white-space: nowrap;
  }
}
@media (max-width: 484px) {
  .items_filter {
    flex-wrap: wrap;
  }
  #form_quick_search .col.text-center {
    display: inline-flex;
    flex-wrap: nowrap;
    width: 100% !important;
  }
  header a.btn-main,
  header a.btn-line {
    min-width: 60px;
  }

  .homeBannerContent h6 {
    font-size: 24px;
  }
  .mvHomeBg {
    background: none;
  }
  .homeVisionMisson p {
    text-align: left;
    max-width: 100%;
  }
  .owl-dots button {
    width: 50px;
    height: 10px;
  }
  .homeMainBanner {
    background-size: 100%;
    min-height: auto;
  }
  .homeFanContent {
    padding-left: 0;
    padding-right: 0;
  }
  .homeFanBanner img {
    max-width: 100%;
  }
  .btnLarge {
    font-size: 16px;
  }
  .collageContent {
    padding: 30px;
  }
  .homeFanContent {
    margin-bottom: 50px;
    height: auto;
  }
  #mainmenu li > a {
    margin-left: 0;
  }
}

.toggle_custom.switch1 input {
  display: none;
}
.toggle_custom.switch1 {
  display: inline-block;
  width: 52px;
  height: 25px;
  transform: translateY(50%);
  position: relative;
}
.toggle_custom .slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 0px #373a3e, 0 0 0px #373a3e;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.02s;
  background: #373a3e;
}
.toggle_custom .slider:before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  background: #d51089;
  border-radius: 30px;
  transition: 0.4s;
}
.toggle_custom input:checked + .slider:before {
  transform: translateX(28px);
  background: #373a3e;
}
.toggle_custom input:checked + .slider {
  box-shadow: 0 0 0 2px #d51089, 0 0 2px #d51089;
  background: #d51089;
}

.input_Unlock_section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.nft__item_info .dropdown > a::after {
  display: none;
}
.nft__item_info .dropdown > a {
  min-width: fit-content;
  border: none;
  padding: 0;
}
.nft__item_info .dropdown-menu.show {
  display: block;
  background: #140b4b;
  height: fit-content;
  padding: 12px 8px;
  transition: none !important;
}
.nft__item_info .dropdown li {
  padding: 0px 7px;
}
.nft__item_info .dropdown li:hover {
  background: linear-gradient(
    135deg,
    #ff0a6c -16.8%,
    #2d27ff 138.64%
  ) !important;
}
.flex_between_s {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report .modal-header {
  position: relative;
}
.report .modal-header h4 {
  width: 100%;
  text-align: center;
}
.report .modal-header button {
  position: absolute;
  right: 5px;
  border: none;
  font-size: 33px;
  color: #fff;
  background: transparent;
  top: 2px;
}
.report p {
  margin: 0;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
}
.report label {
  color: #fff;
  margin-bottom: 7px;
}
.report textarea {
  background: transparent;
  color: #fff;
  border: 1px solid #424242;
}
.social_icons_section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social_icons_section a {
  font-size: 33px;
  margin: 0 10px;
  color: #fff;
}

.sponsorLogo img {
  border: 0px solid #262b30;
  padding: 5px;
}
.support_card_section {
  padding: 20px;
  background: #24272b;
  box-shadow: 0px 2px 7px #000;
  border-radius: 11px;
  margin-bottom: 30px;
}
.support_card_section .form_group {
  margin-bottom: 15px !important;
}
.support_card_section textarea {
  background: transparent;
  color: #fff;
  border: 1px solid #555;
}
.custom-file,
.custom-file-input {
  position: relative;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}

.custom-file-label {
  background: #ef780a !important;
  box-shadow: none !important;
  border: 1px solid #ef780a !important;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  height: auto !important;
  margin-bottom: 0 !important;
  width: 100%;
  text-align: center;
  padding: 3px 0px;
  position: absolute;
  left: 0;
  color: #fff;
  margin: 0px 0px;
}
.custom-file input {
  position: relative;
  z-index: 5;
  cursor: pointer;
  opacity: 0;
}
.custom-file-label:focus {
  border: 1px solid #4040fa !important;
}

.custom-file-label::after {
  background-color: #ff4949 !important;
  color: #ffffff !important;
  border-radius: 0 5px 5px 0px;
  height: 35px;
}

#main {
  margin: 50px 0;
}

#main #faq .card {
  margin-bottom: 30px;
  border: 0;
  background: #24272b;
  box-shadow: 0px 2px 7px #000;
  margin: 15px 3px;
  border-radius: 8px;
  overflow: hidden;
}

#main #faq .card .card-header {
  border: 0;
  box-shadow: none;
  border-radius: 2px;
  padding: 0;
}

#main #faq .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  background: #24272b;
  color: #222;
  padding: 20px;
  color: #fff;
}

#main #faq .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;
}

#main #faq .card .card-header .btn-header-link.collapsed {
  background: #a541bb;
  color: #fff;
  padding: 20px;
  background: #24272b;
  box-shadow: 0px 2px 7px #000;
  border-radius: 0;
  /* box-shadow: none; */
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\f106";
}

#main #faq .card .collapsing {
  background: transparent;
  line-height: 30px;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  background: #24272b;
  line-height: 30px;
  color: #fff;
}
#main #faq .card .card-header .btn-header-link {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#main #faq .card .card-header p {
  margin: 0;
  color: #999;
}

#main #faq .card .card-header p span {
  margin-left: 6px;
  color: #fff;
}

#main #faq .card .card-header p span.text_green {
  margin-left: 6px;
  color: green;
}
.metaChatDetails {
  border-bottom: 1px solid #595959;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.button_cas_sss {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}
ul.ticketComments {
  margin: 0;
  padding: 0;
}
ul.ticketComments li {
  list-style: none;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
}
ul.ticketComments li:last-child {
  margin-bottom: 0;
}
.ticketUserDetails {
  text-align: center;
  margin-right: 20px;
  width: 60px;
}
.userImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.userImg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.ticketDetails {
  width: calc(100% - 70px);
}
.messageTypeBox {
  margin-top: 20px;
}
.messageTypeBox .form-control {
  background: #150523;
  border: 1px solid #626262 !important;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 18px;
  margin-top: 9px;
}
.button_replay button:first-child {
  border: none;
  background: #ef780a;
  color: #fff;
  margin-right: 5px;
  padding: 0 8px;
  border-radius: 5px;
  border: 1px solid #ef780a;
}
.button_replay button:first-child:hover {
  border: 1px solid #fff;
  background: transparent;
}
.button_replay button:nth-child(2) {
  border: none;
  border: 1px solid #fff;
  color: #fff;
  margin-right: 5px;
  padding: 0 8px;
  background: transparent;
  border-radius: 5px;
}
.widyth_30 {
  width: 30%;
}
.flex_between_s a {
  color: #fff !important;
}
.button_replay button:nth-child(2):hover {
  background: #ef780a;
  border: 1px solid #ef780a;
}
.button_replay {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dark-scheme .modal-footer {
  border-top-color: rgba(255, 255, 255, 0.2);
  border-top: 0;
}

.modal-dialog .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}
.hex {
  position: relative;
  max-width: 100%;
  width: 85px;
}
.hex__shape {
  position: absolute;
  backface-visibility: hidden;
  left: 0;
  height: 100%;
  overflow: hidden;
  top: 0;
  transform: rotate(240deg);
  width: 100%;
}
.hex::before {
  content: "";
  display: block;
  padding-bottom: 70%;
}
.hex img {
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 115%;
}
.font_16 {
  font-size: 16px;
}
.font_600 {
  font-weight: 600;
}
.line_20 {
  line-height: 20px;
}
.file_btn {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  color: #000000;
  padding: 7px 16px;
  border-radius: 0px;
  font-weight: 600;
}
input.inp_file {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
  z-index: 1 !important;
}
.primary_label {
  font-size: 16px;
  font-weight: 400 !important;
  color: #fff;
  margin: 0;
  margin-bottom: 9px;
  margin-top: 10px;
}
.text-muted,
.bmd-help {
  color: #8f8796 !important;
}
.create_btn {
  background: #ef780a;
  border: 1px solid #ef780a;
  color: #fff;
  padding: 7px 16px;
  margin: 12px auto;
  border-radius: 0px;
}
.activity-list li.act_sale::after {
  color: #ffffff;
}
.primary_modal .modal-dialog .modal-footer {
  background-color: #26292d;
}
.clas_bottons {
  border: solid 1px rgba(255, 255, 255, 0.2);
  padding: 8px 15px;
}
.price_range .input_section_price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #44374f;
  padding: 3px 8px;
  border-radius: 6px;
  background: #150523;
  margin: 0 15px;
}
.price_range .input_section_price input {
  border: none;
  background: transparent;
  color: #fff;
}
.price_range .input_section_price span {
  margin-left: 6px;
}
.price_range .input_section_price input:focus-visible {
  outline: none;
}
.price_range {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.boredr {
  border: solid 1px rgb(48, 32, 137);
  background: none;
}
.clas_bottons {
  background-color: #ffffff;
  color: #000000;
  font-weight: 600;
  border-radius: 0;
  min-width: 100px;
}

.mar-000_minus {
  margin-top: -18px;
}
.loader_section_ {
  height: 100vh;
  display: grid;
  place-items: center;
}

.select_in_but {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: solid 1px #505050;
  background: none;
  border-radius: 6px;
  margin-bottom: 12px;
}
.select_in_but #item_category_multiple1 {
  width: 160px !important;
  border: 0px solid transparent;
}
.select_in_but #item_category_multiple1 a {
  margin: 0;
  border: none;
}
.select_in_but input {
  background: transparent;
  border: none;
}
.margin_xss {
  margin-bottom: 15px;
}
#create_item_modal button.mar-top-10 {
  min-width: 150px;
}
#cancel_order_modal .modal-sm {
  max-width: 400px;
}
#cancel_order_modal .bid_form {
  padding-top: 12px;
}
#cancel_order_modal .buy_desc_sm_bold {
  text-align: left;
}

#cancel_order_modal .buy_desc_sm {
  text-align: right;
}
#nav-Following .nft_coll span,
#nav-Followers .nft_coll span {
  color: #a2a2a2;
}
.pading_new_s_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 0px;
}
.drobdown_section {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start;
align-items: flex-start; */
}
.drobdown_section #dropdownMenuButton {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  background: transparent;
  border: solid 1px rgba(255, 255, 255, 0.2);
}
.drobdown_section .dropdown-menu.show {
  padding: 11px 8px;
}
.st-btn {
  display: inline-block !important;
}
.marrrr {
  margin-left: 10px;
}
/* .w-100 {
    width: 100%;
} */
.file_btn:hover {
  color: #fff;
  box-shadow: 2px 2px 20px 0px rgba(var(--secondary-color-rgb), 0.5);
  cursor: pointer;
  background: #ef780c;
}
.user_info p {
  max-width: 80%;
  margin: 0 auto;
}
.collection_btn_grp button.btnbar {
  background-color: #da1090;
  border: 1px solid #9d0265;
  border-radius: 2px;
  box-shadow: none !important;
  color: #111;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
  text-transform: capitalize;
  transition: 0.2s all;
  margin-left: -1px;
}

.profile_avatar .user_info .btn {
  width: 50px;
  border: 0 !important;
  background-image: none !important;
  background-color: #111a6e !important;
  margin: 0 10px;
}

.center-button {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.center-button .btn {
  margin: 0 10px;
  width: 150px;
}
.profile_avatar .btn i {
  color: #ffffff;
}
.collection_btn_grp button i {
  color: #808080;
  font-size: 20px;
  line-height: 20px;
  margin: unset;
  position: relative;
  padding: 0;
  background: transparent;
  transition: 0.2s all;
}
.collection_btn_grp button:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.collection_btn_grp button:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.collection_btn_grp button:hover {
  color: #fff;
  background-color: #b9107b;
  border: 1px solid #9d0265;
}
.collection_btn_grp button:hover i {
  color: #fff;
}
.search_inp_form {
  position: relative;
}
.card_search_result {
  z-index: 300;
  position: absolute;
  background: #140b4b;
  padding-top: 15px;
  height: 200px;
  overflow-x: hidden;
  width: 120%;
  box-shadow: 0 4px 20px 0 rgb(10 10 10 / 80%);
  margin-left: 30px;
  overflow-y: auto;
}
.heading_flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
h2.style-2 {
  color: #ffffff;
  font-size: 48px;
  font-family: "Bebas Neue", cursive;
  font-weight: normal;
  letter-spacing: 1px;
}
.day_list {
  margin-left: 10px;
}

.collection_page_details .collection_btn_grp {
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  display: flex;
  margin: 0 auto;
}
.collection_page_details .collection_btn_grp button.btn {
  width: 130px;
}
.collection_page_details p {
  margin-bottom: 5px;
  color: #ffffff;
}
.collection_page_details h4 {
  color: #a2a2a2;
  margin-bottom: 5px;
}
.collection_btn_grp button:hover p {
  color: #eeeeee;
}
.collection_btn_grp button:hover h4 {
  color: #fff;
}
.nft__item_preview_video {
  max-height: 600px;
  object-fit: cover;
  width: 100%;
}
.info_properties_panel {
  display: flex;
  flex-wrap: wrap;
}
.info_properties_panel div {
  border: solid 1px rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 10px;
  width: 156px;
  margin: 10px 10px 10px 0;
  transition: 0.2s all;
}
.info_properties_panel div p {
  margin-bottom: 0;
  font-size: 14px;
  color: #a2a2a2;
}
.info_properties_panel div h4 {
  margin-bottom: 0;
  font-size: 16px;
}
.info_properties_panel div small {
  color: #a2a2a2;
}
.info_properties_panel div:hover {
  border: solid 1px rgb(255 51 61 / 70%);
}

/* Space button CSS */

.btn {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 13rem;
  overflow: hidden !important;
  height: 3rem !important;
  background-size: 300% 300% !important;
  backdrop-filter: blur(1rem) !important;
  border-radius: 5rem !important;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 4px transparent !important;
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      #ffdb3b 10%,
      #fe53bb 45%,
      #8f51ea 67%,
      #0044ff 87%
    ) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  padding: 0 !important;
  color: #ffffff;
}

#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

strong {
  z-index: 2;
  color: #ffffff;
}

#glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

.btn:hover #container-stars {
  z-index: 1;
  background-color: #011031;
}

.btn:hover {
  transform: scale(1.1);
}

.btn:active {
  border: double 4px #fe53bb;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.btn:active .circle {
  background: #fe53bb;
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.menu_side_area .btn {
  width: 10rem;
  margin-left: 15px;
}
@media only screen and (max-width: 1199px) {
  .collection_page_details .collection_btn_grp {
    max-width: 100%;
  }
}
@media only screen and (max-width: 1399px) {
  .homeTextSide::before {
    left: -15px;
    z-index: 0;
  }
  .homeTextSide::after {
    right: -15px;
  }
}
.de_countdownr {
  border: 2px solid #c70d78;
  width: 212px;
  padding-left: 24px;
  border-radius: 50px;
  margin-bottom: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: #d612a0;
}
.sponsorLogo img {
  border: 0px solid #262b30;
  padding: 5px;
}

/* .text-danger {
  color: #dc3545!important;
  top: -18px;
  position: relative;
} */

div#nav-history {
  margin-top: 18px;
}

div#nav-bids {
  margin-top: 18px;
}

/* audio.audio.audio_widyth {
  margin-left: -290px;
  margin-top: 4px;
} */

@media (max-width: 575px) {
  .collection_btn_grp button:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
  }
  .collection_btn_grp button:last-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .dark-scheme .author_list_info a {
    word-break: break-all;
  }
  .item_author {
    display: flex;
    align-items: flex-start;
  }
  .item_author .author_list_pp {
    min-width: 50px;
  }
  .item_info {
    padding-left: 0;
    padding-top: 30px;
  }
  .p_list_info span {
    word-break: break-all;
  }
  .activity-list {
    padding: 15px;
  }
  .p_list_info {
    padding-left: 70px;
  }
}
.blrr {
  filter: blur(7px);
}
